// import libs
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

// import components
import config from '../../config/config'
import LanguageSwitcher from './LanguageSwitcher'
// import styles
import './Header.scss'
import Logo from '../../assets/logo.png'

@withTranslation(['header'], { wait: true })
@inject('store', 'history')
@observer
class Header extends Component {
  handleOpenChooseApp = () => {
    this.props.store.authStore.selectedApp = null
  }
  render () {
    const { t } = this.props
    const APP_NAME = process.env.REACT_APP_NAME
    return (
      <header className={`${config.themeBgClass} header_container`}>

        <div className='header--container--left'>

          <div className='logo-brand'>
            <div className='logo ms-bgColor-themePrimary ms-bgColor-themePrimary--hover' >
              <img src={Logo} alt='SAG-AG Logo' />
            </div>
          </div>

          <div className='ms-fontSize-xl ms-fontWeight-semilight app-name'>
            <a style={{color:'#FFF'}} onClick={this.handleOpenChooseApp} href='/apps'>{APP_NAME}</a>
          </div>

        </div>

        <div className='header--container--right'>
        <LanguageSwitcher _lng={this.props.store.appStateStore.choosen_languages[0]} />
          {
            this.props.store.authStore.current_user.DisplayName !== null &&
              <div className='ms-fontSize-l ms-fontWeight-semilight current-user'>
                <p>
                  {this.props.store.authStore.current_user.DisplayName}
                </p>
              </div>
          }
        </div>

      </header>
    )
  }
}

export default Header
