import { observable } from 'mobx'
import config from '../../config/config'

class TreeStore {
  @observable items = []
  @observable selectedTreeKey = null

  @observable mapSort = new Map()
  @observable selectedShop = null
  
  treeExternalServiceTypes = [
    { key: '', text: ''},
    { key: 'tiles', text: 'tiles' },
    { key: 'node', text: 'node' },
    { key: 'service', text: 'service' },
    { key: 'link', text: 'link' }
  ]
  constructor (authStore, esObjectStore, unitreeStore, tileStore, nodeStore) {
    this.esObjectStore = esObjectStore
    this.authStore = authStore
    this.unitreeStore = unitreeStore 
    this.tileStore = tileStore
    this.nodeStore = nodeStore
  }

  /**
   * Get the trees from DB
   */
   populateTrees = async (unitreeId, selectedTreeId) => {
    this.mapSort.clear()
    // fetch tree names
    this.unitreeStore.mapTextsTranslations.clear()
    await this.fetchUnitreeTranslations(unitreeId)
    
    await this.unitreeStore.fetchUnitreeNodes(unitreeId)

    const result = await fetch(`${config.server_address}/fe/tree/${unitreeId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    this.items = data.message

    this.esObjectStore.shopShops.forEach(shop => { 
      if (!this.mapSort.has(shop)) {
        this.mapSort.set(shop, [])
      }
    })

    this.items.forEach(item => { 
      if (selectedTreeId === item.id) {
        this.unitreeStore.selectedTreeLastUpdate = item.last_updated_date
        this.unitreeStore.selectedTreeLastUpdatedBy = item.updated_by
      }
      item.tree_names = this.unitreeStore.mapTextsTranslations.get(item.id+'@tree_name')
      const treeSorts = item.tree_sort.split(",")
      treeSorts.forEach(treeSort => {
        const shop = treeSort.substring(0, treeSort.indexOf(':'))
        const sort = treeSort.substring(treeSort.indexOf(':') + 1)
        if (!this.mapSort.has(shop)) {
          this.mapSort.set(shop, [])
        }
        this.mapSort.get(shop).push({
          id: item.id,
          tree_name: item.tree_names !== undefined ? item.tree_names[this.esObjectStore.defaultLang] : '',
          sort
        })
      })
    })
    this.mapSort.forEach((value, key) => {
      this.mapSort.set(key, value.slice().sort((t1,t2) => t1.sort - t2.sort))
    })
    this.selectedShop = this.esObjectStore.shopShops[0]
    if (selectedTreeId !== undefined) {
      this.selectedTreeKey = selectedTreeId
    } else {
      this.selectedTreeKey = this.items.length > 0 ? this.items[0].id : null
      this.unitreeStore.selectedTreeLastUpdate = this.items.length > 0 ? this.items[0].last_updated_date : null
      this.unitreeStore.selectedTreeLastUpdatedBy = this.items.length > 0 ? this.items[0].updated_by : null
    }
    await this.nodeStore.populateNodesTree(this.selectedTreeKey)
  }

  fetchUnitreeTranslations = async (unitreeId) => {
    const result = await fetch(`${config.server_address}/fe/texts/${unitreeId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    data.message.forEach(text => {
      this.unitreeStore.addTranslationToMap(`${text.tree_id}@${text.text_key}`, text.language_iso, text.text)
    });
  }

  editTree = async (editItem, updateTexts = true) => {
    // edit in DB 
    const result = await fetch(`${config.server_address}/fe/tree/${editItem.id === undefined ? 'insert' : 'edit'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: editItem.id,
        unitree_id: editItem.unitree_id,
        tree_id: editItem.tree_id,
        tree_sort: editItem.tree_sort,
        tree_image: editItem.tree_image !== undefined ? editItem.tree_image : '',
        tree_external_service_type: editItem.tree_external_service_type,
        tree_external_service_attribute: editItem.tree_external_service_attribute,
        last_updated_date: new Date(), 
        updated_by: this.authStore.current_user.DisplayName
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (updateTexts && res.success === true) {
      if (editItem.id === undefined) {
        editItem.id = res.message
      }
      // save text for tree_name
      for (const [lang, text] of Object.entries(editItem.tree_names)) {
        await this.unitreeStore.editText(editItem.id, 'tree_name', lang, text)
      }
      await this.populateTrees(this.unitreeStore.selectedUnitree.id, editItem.id)
    }
  }

  /**
   * Remove a tree from the DB and elastic search
   */
  removeTree = async (item) => {
    // remove from DB 
    const result = await fetch(`${config.server_address}/fe/tree/delete/${item.id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      alert(`Tree successfully deleted !`)
      await this.populateTrees(item.unitree_id)
    }
  }

  saveSort = async() => {
    for await (const item of this.items) {
      let tree_sort = ''
      this.mapSort.forEach((value, key) => {
        const tree = value.find(val => val.id === item.id)
        if (tree_sort.length > 0) {
          tree_sort += ','
        }
        tree_sort += key + ':' + tree.sort
      })
      item.tree_sort = tree_sort
      await this.editTree(item, false)
    }
    // refresh data
    await this.populateTrees(this.unitreeStore.selectedUnitree.id)
  }

  updateLastDateAndUserOnTree = (tree_id) => {
    const selectedTreeIndex = this.items.findIndex(tree => tree.id === tree_id) // find tree
      if (selectedTreeIndex >= 0) {
        this.items[selectedTreeIndex].last_updated_date = this.unitreeStore.selectedTreeLastUpdate
        this.items[selectedTreeIndex].updated_by = this.unitreeStore.selectedTreeLastUpdatedBy
      }
  }
} 

export default TreeStore
