import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'

i18n
  .use(HttpApi)
  .init({
    fallbackLng: 'en',
    wait: true, // globally set to wait for loaded translations in translate hoc

    // have a common namespace used around the full app
    // eslint-disable-next-line id-length
    ns: ['common'],
    defaultNS: 'common',

    debug: false,

    detection: {
      order: ['localStorage']
    },

    react: {
      useSuspense: false
    },

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format (value, format) {
        if (format === 'uppercase') return value.toUpperCase()
        return value
      }
    }
  })

export default i18n
