import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { ScrollablePane, ScrollbarVisibility, Label, Checkbox, TextField, Dropdown, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from './../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
// styles
import './Portal.scss'
import { object } from 'prop-types'

const shops = require('../../../config/shops.json')

@inject('store')
@observer
class Overview extends Component {
  constructor (props) {
    super(props)
    this.appStore = this.props.store.appStore
    this.overviewStore = this.props.store.overviewStore
    this.authStore = this.props.store.authStore
    this.usersStore = this.props.store.usersStore
    const user = this.authStore.userItems.find(user => user.user === this.authStore.current_user.UserName.toLowerCase())
    this.setChecked(user)
    this.state = {
      selectedEnv: null,
      loadingData: false,
      showIndicesDialog: false,
      user
    }
  }

  componentDidMount () {
    if (this.appStore.envs.length === 0) {
      this.appStore.fetchEnvs()
    }
  }

  loadData = () => {
    if (!this.state.selectedEnv) {
      alert('Select an environment!')
      return
    }
    if (!this.state.selectedShop) {
      alert('Select a shop!')
      return
    }
    if (this.overviewStore.checked.length === 0) {
      alert('Configure the indices shown in the overview !')
      return
    }
    if (this.state.loadingData === true) {
      return
    }
    this.props.store.overviewStore.items = []
    if (this.state.selectedEnv && this.state.selectedShop) {
        this.setState({
            loadingData: true
        })
        this.overviewStore.populateData(this.state.selectedEnv, this.state.selectedShop).then(() => 
            this.setState({
                loadingData: false
            })
        )
    }
  } 

  setChecked = (user) => {
    if (user !== undefined) {
      this.overviewStore.checked = []
      const roles = this.authStore.usersMap.get(user.id).filter(role => this.overviewStore.indices.map(index => index.name).includes(role.role))
      if (roles !== undefined) {
          roles.forEach(role => {
              if (role.value === 1) this.overviewStore.checked.push(role.role) 
          }) 
      } else {
          this.overviewStore.checked = []
      }
    }
  }

  showIndicesDialog = () => {
    this.setState({ showIndicesDialog: true })
  }

  handleCloseIndicesDialog = () => {
    this.setState({ showIndicesDialog: false })
    this.setChecked(this.state.user)
  }

  handleSaveShownIndices = () => {
    const promises = []
    for (const index of this.overviewStore.indices) {
      promises.push(this.usersStore.saveRole(this.state.user.id, index.name, this.overviewStore.checked.includes(index.name)))
    }
    Promise.all(promises).then(() => {
      this.setState({
        showIndicesDialog: false
      })
    })
  }

  getArticleCountByShop(shop) {
    return this.overviewStore.getArticleCountByShop(shop)
  }

  renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName]
    if (this.props.store.overviewStore.existingLangs.includes(fieldContent)) {
      return <span style={{textAlign: 'right'}}><b>{fieldContent}</b></span>
    }
    switch (column.key) {
      case 'detail':
        if (fieldContent !== 'Last date of updates' && fieldContent !== 'Total number of records') {
          return <span style={{textAlign: 'right'}}><b>{fieldContent}</b></span>
        } else {
          return <span>{fieldContent}</span>;
        }
      default:
        return <span>{fieldContent}</span>;
    }
  }
  colorRow = (props, defaultRender) => {
    let bgcolor = props.itemIndex%2 === 0 ? 'white' : '#E0E0E0'
    return <div>{defaultRender({...props, styles: {root: { background: bgcolor }}})} </div>;
  }

  render () {
    const { t, store: { appStore: { envs }, overviewStore: { items, columns, shopsDropDown, indices, checked } } } = this.props
    const { selectedEnv, selectedShop, loadingData, showIndicesDialog } = this.state
    const stackTokens = {
      childrenGap: 5,
      padding: 5
    }
    const stackStyles = {
        root: {
          width: '350px'
        }
    }
    return (
    <div className='page-container'>
      <div className='arti-container'>
        <Stack vertical tokens={stackTokens}>
         <Stack horizontal tokens={stackTokens} styles={{ root: { width: '70vw'}}}>
            <Dropdown id='env'
              placeholder={t('common:DROPDOWN_ENV_PLACEHOLDER')}
              label={t('common:ENV')}
              options={envs}
                onChange={(event, { key }) => {
                    this.setState({
                        selectedEnv: key
                    })
                    this.overviewStore.clearServoData()
                    this.overviewStore.clearESData()
                }
              }
              styles={{dropdown: { width: 200, height: 20, float: 'right' },
              caretDownWrapper: { height:20, lineHeight: 'normal' },
              dropdownItem: 'dropdownItem',
              dropdownItemSelected: 'dropdownItem' }}
            />  
            <Dropdown id='shops'
              placeholder='--Select shop--'
              label='Shops'
              options={shopsDropDown}
                onChange={(event, { key }) => {
                    this.setState({
                        selectedShop: key
                    })
                    this.overviewStore.clearServoData()
                    this.overviewStore.clearESData()
                    this.overviewStore.items = []
                    this.overviewStore.buildHeader(key)
                }
              }
              styles={{dropdown: { width: 200, height: 20, float: 'right' },
              caretDownWrapper: { height:20, lineHeight: 'normal' },
              dropdownItem: 'dropdownItem',
              dropdownItemSelected: 'dropdownItem' }}
            />  
            <PrimaryButton onClick={() => this.loadData()} text='Load data' />
          </Stack>
          <Stack horizontal tokens={{ padding: 0, childrenGap: 5}} styles={ {root: {width: '100%'}}}>
            <Stack vertical tokens={{ padding: 0, childrenGap: 5}} styles={stackStyles}>
              <span style={{paddingBottom:5, paddingLeft:220}}><h4>Servo</h4></span>
            </Stack>
            <Stack vertical tokens={{ padding: 0, childrenGap: 5}} styles={{ root: { width: '450px'}}}>
              <Stack horizontal tokens={{ childrenGap: 20 }} styles={ {root: {width: '100%'}}}>
                <span style={{paddingBottom:5, paddingLeft: 150, textAlign: 'right'}}><h4>Connect Data</h4></span>
                <PrimaryButton onClick={() => this.showIndicesDialog()} text={t('common:CONFIG_SHOWN_INDICES')} />
              </Stack>
            </Stack>
          </Stack> 
          {loadingData && <Spinner size={SpinnerSize.large} /> } 
          <div id ={items.length > 0 ? "detailsList" : "emptyList"}>   
            <DetailsList id='detailsListItems'
              isHeaderVisible={false}
              items={items} 
              setKey='set'
              columns={columns}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              enterModalSelectionOnTouch
              constrainMode={ConstrainMode.unconstrained}
              onRenderItemColumn={this.renderItemColumn}
              onRenderRow={this.colorRow}
            />
          </div>    
        </Stack> 
        {showIndicesDialog && <Dialog
          minWidth={400}
          maxWidth={800}
          hidden={!showIndicesDialog}
          onDismiss={this.handleCloseIndicesDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:CONFIG_SHOWN_INDICES'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <Stack vertical tokens={stackTokens}>
                {indices.map((item, index) => (
                    <div key={index} >
                        <Checkbox id={item.name} label={item.name} checked={ checked.includes(item.name) } onChange={this.overviewStore.handleCheck} />
                    </div>
                ))}
            </Stack>  
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSaveShownIndices} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseIndicesDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
      </div>    
    </div>          
    )
  }
}

export { Overview }
export default withTranslation(['common'], { wait: true })(withMainContainer(Overview))