import { observable } from 'mobx'

class DialogNodeStore {
  @observable filtersItems = []
  @observable filtersColumns = []  
  @observable showFilterDialog = false
  @observable filterItem = undefined
  @observable critFiltersItems = []

  nodeExternalServiceTypes = [
    { key: '', text: ''},
    { key: 'info', text: 'info' },
    { key: 'link', text: 'link' },
    { key: 'partner', text: 'partner' },
    { key: 'service', text: 'service' },
    { key: 'shop', text: 'shop' }
  ]

  constFiltersHeader = [
    { key: 'gatxt', text : 'NODE_FILTER_GAID' },
    { key: 'filter_str', text : 'NODE_FILTER' },
    { key: 'buttons', text: '' }
  ]

  constructor (authStore, nodeStore) {
    this.authStore = authStore 
    this.nodeStore = nodeStore 
    this.constFiltersHeader.forEach(({key}) => this.filtersColumns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: '50%',
      minWidth: 50,
      maxWidth: 200,
    }))
  }

  /**
   * Hide / Show dialog
   */
  handleFilterDialog = (filter) => {
    this.showFilterDialog = !this.showFilterDialog
    this.filterItem = filter
  } 
  
  editFilter = () => {
    if (!this.filterItem.isEdit) {
      this.filtersItems.push(this.filterItem)
    }
    this.nodeStore.editNode.filters = this.filtersItems
  }

  removeFilter = (delIndex) => {
    this.filtersItems.splice(delIndex, 1)
    this.nodeStore.editNode.filters = this.filtersItems
  } 
}

export default DialogNodeStore
