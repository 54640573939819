import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, TextField, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter, 
  ScrollablePane, ScrollbarVisibility,
  Pivot, PivotItem } from '@fluentui/react'
import withMainContainer from './../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown as LookupDropdown} from 'semantic-ui-react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { ControlledTreeEnvironment, Tree } from 'react-complex-tree';
import Brands from '../Extentions/brand_priority/Brands';
import Sorts from '../Extentions/brand_priority/Sorts';
const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const changeIcon = { iconName: 'ChangeEntitlements' }

@inject('store')
@observer
class BrandPriorityDocs extends Component {
  constructor (props) {
    super(props)
    this.brandPriorityStore = this.props.store.brandPriorityStore
    this.esObjectStore = this.props.store.esObjectStore
    this.brandsStore = this.props.store.brandsStore
    this.sortsStore = this.props.store.sortsStore
    this.state = {
      hideDelDialog: true,
      hideGaGroupDelDialog: true,
      hideDropDialog: true
    }
  }

  handleEdit = () => {
    if (this.brandPriorityStore.selectedTabKey === 'brands' && this.brandPriorityStore.gaGroup.length < 1) {
      alert(this.props.t('common:MSG_SELECT_FILTER_GENART'))
      return
    }
    if (this.brandPriorityStore.selectedTabKey === 'sorts' && this.brandPriorityStore.gaGroupSort.length < 1) {
      alert(this.props.t('common:MSG_SELECT_FILTER_GENART'))
      return
    }
    const items = []
    // set brand priorities 
    if (this.brandPriorityStore.selectedTabKey === 'brands') {
      const all = [...new Set([].concat(...this.brandsStore.allPrioritiesItemsByMandant.values()))]
      const mapByBrand = new Map()
      all.forEach(prio => {
        if (!mapByBrand.has(prio.brand_id)) {
          mapByBrand.set(prio.brand_id, [])
        }
        mapByBrand.get(prio.brand_id).push({[prio.type]: prio.mandant, "sort" : {"1": prio.prio, "2": prio.subprio}})
      })
      const priorities = []
      for (let [key, value] of  mapByBrand.entries()) {
        priorities.push({"brand_id": key, "priorities": value})
      }
      this.brandPriorityStore.editItem.brands = priorities
      this.brandPriorityStore.gaGroup.forEach(ga => {
        const item = this.brandPriorityStore.brandPriorityDocs.find(doc => doc.gaid === ga.id)
        items.push({...this.brandPriorityStore.editItem, 
          sorts: item === undefined ? [] : typeof item.sorts === 'string' ? JSON.parse(item.sorts) : item.sorts,
          gaid: ga.id, 
          parent_gaid: ga.parent !== undefined ? ga.parent : null, 
          parent_sort_gaid: item?.parent_sort_gaid, 
          id: item?.id })
      })
    } else {
      // set sort priorities 
      const allSorts = [...new Set([].concat(...this.sortsStore.allPrioritiesItemsByMandant.values()))]
      const mapSortsByBrand = new Map()
      allSorts.forEach(prio => {
        if (!mapSortsByBrand.has(prio.brand_id)) {
          mapSortsByBrand.set(prio.brand_id, [])
        }
        mapSortsByBrand.get(prio.brand_id).push({[prio.type]: prio.mandant, "sort" : {"1": prio.prio }})
      })
      const sortPriorities = []
      for (let [key, value] of  mapSortsByBrand.entries()) {
        sortPriorities.push({"brand_id": key, "priorities": value})
      }
      this.brandPriorityStore.editItem.sorts = sortPriorities
      this.brandPriorityStore.gaGroupSort.forEach(ga => {
        const item = this.brandPriorityStore.brandPriorityDocs.find(doc => doc.gaid === ga.id)
        items.push({...this.brandPriorityStore.editItem, 
          brands: item === undefined ? [] : typeof item.brands === 'string' ? JSON.parse(item.brands) : item.brands,
          gaid: ga.id, 
          parent_gaid: item?.parent_gaid, 
          parent_sort_gaid: ga.parent !== undefined ? ga.parent : null, 
          id: item?.id 
        })
      })
    }
    // remove parent_gaid of the ga deleted from the group
    console.log(this.state.gaDeletedFromGroup)
    this.state.gaDeletedFromGroup !== undefined && this.state.gaDeletedFromGroup.forEach(ga => {
      const item = this.brandPriorityStore.brandPriorityDocs.find(doc => doc.gaid === ga.id)
      if (item) {
        items.push({...item, 
          brands: typeof item.brands === 'string' ? JSON.parse(item.brands) : item.brands, 
          sorts: typeof item.sorts === 'string' ? JSON.parse(item.sorts) : item.sorts, 
          parent_gaid: this.brandPriorityStore.selectedTabKey === 'brands'? null : item.parent_gaid, 
          parent_sort_gaid: this.brandPriorityStore.selectedTabKey === 'brands'? item.parent_sort_gaid : null 
        })
      }
    })
    console.log(items)
    this.brandPriorityStore.saveDocs(items)
  }

  setBrandsItems (item) {
    this.brandsStore.allPrioritiesItemsByMandant.clear()
    console.log(item)
    const brands = typeof item.brands === 'string' ? JSON.parse(item.brands) : item.brands
    brands.forEach(brand => {
      // add all priorities
      brand.priorities.forEach(priority => {
        const type = Object.keys(priority).find(key => this.brandsStore.mandant_type.find(type => type.key === key))
        if (type !== undefined) {
          if (!this.brandsStore.allPrioritiesItemsByMandant.has(priority[type])) {
            this.brandsStore.allPrioritiesItemsByMandant.set(priority[type], [])
          }
          this.brandsStore.allPrioritiesItemsByMandant.get(priority[type]).push({...priority, type: type, mandant: priority[type], prio: priority.sort["1"], subprio: priority.sort["2"], brand_id: brand.brand_id, brandName: this.esObjectStore.mapManufacturers.get(brand.brand_id) !== undefined ? brand.brand_id + ' - ' + this.esObjectStore.mapManufacturers.get(brand.brand_id) : brand.brand_id})  
          this.brandsStore.allPrioritiesItemsByMandant.set(priority[type], this.brandsStore.allPrioritiesItemsByMandant.get(priority[type]).slice().sort((p1,p2) => p1.prio !== p2.prio ? p1.prio - p2.prio : p1.subprio - p2.subprio))  
        }
      })
    })
  }

  setSortsItems (item) {
    this.sortsStore.allPrioritiesItemsByMandant.clear()
    console.log(item)
    const sorts = typeof item.sorts === 'string' ? JSON.parse(item.sorts) : item.sorts
    sorts.forEach(sort => {
      // add all priorities
      sort.priorities.forEach(priority => {
        const type = Object.keys(priority).find(key => this.sortsStore.mandant_type.find(type => type.key === key))
        if (type !== undefined) {
          if (!this.sortsStore.allPrioritiesItemsByMandant.has(priority[type])) {
            this.sortsStore.allPrioritiesItemsByMandant.set(priority[type], [])
          }
          this.sortsStore.allPrioritiesItemsByMandant.get(priority[type]).push({...priority, type: type, mandant: priority[type], prio: priority.sort["1"], brand_id: sort.brand_id, brandName: this.esObjectStore.mapManufacturers.get(sort.brand_id) !== undefined ? sort.brand_id + ' - ' + this.esObjectStore.mapManufacturers.get(sort.brand_id) : sort.brand_id})  
          this.sortsStore.allPrioritiesItemsByMandant.set(priority[type], this.sortsStore.allPrioritiesItemsByMandant.get(priority[type]).slice().sort((p1,p2) => p1.prio - p2.prio))  
        }
      })
    })
  }

  showEditDialog = item => {
    if (item === undefined) {
      this.brandsStore.allPrioritiesItemsByMandant.clear()
      this.sortsStore.allPrioritiesItemsByMandant.clear()
      this.brandPriorityStore.gaGroup = []
      item = {
        brands: [],
        sorts: []
      }
    } else {
      this.brandPriorityStore.selectedTabKey === 'brands' && this.setBrandsItems(item)
      this.brandPriorityStore.selectedTabKey === 'sorts' && this.setSortsItems(item)
      this.brandPriorityStore.populateGaGroup(item)
    }
    this.brandPriorityStore.selectedTabKey === 'brands' ? this.setState({gaGroupOrig: [...this.brandPriorityStore.gaGroup]}) :
      this.setState({gaGroupOrig: [...this.brandPriorityStore.gaGroupSort]})
    this.brandPriorityStore.handleShowDialog(item)
  }

  handleCloseInsertDialog = () => {
    this.brandPriorityStore.handleShowDialog()
    // this.brandPriorityStore.populateDocs(this.brandPriorityStore.selectedESObject.id)
  }

  showDelDialog = item => {
    this.setState({
      hideDelDialog: false,
      delItem: item
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.setState({
      hideDelDialog: true,
    })
    this.brandPriorityStore.removeDoc(this.state.delItem).then((result)=> {
      result && alert(`Brand priority for genart ${this.state.delItem.gaid} successfully deleted !`)
      this.setState({
        delItem: undefined
      })
    })
  }

  handleChange = (key, value) => {
    const editItem = this.brandPriorityStore.editItem
    if (editItem !== undefined) {
      editItem[key] = value
    }
  }

//ga group
setGaGroupHeaderColumns () {
  const { t } = this.props
  const columnsName = []
  this.brandPriorityStore.gaGroupColumns.forEach((col, index) => {
    col.name = col.key !== 'buttons' ? t(`common:${this.brandPriorityStore.constGaGroupHeader[index].text}`) : ''
    columnsName.push(col)
  })
  return columnsName
}
handleGaGroupItemColumn = (item, index, column) => {
  if (column.fieldName === 'buttons') {
    const { t } = this.props
    return (
      <Stack horizontal verticalAlign='start' verticalFill='true'>
        <IconButton
          onClick={() => this.showGaGroupDelDialog(index)}
          iconProps={delIcon}
          disabled={!item.parent}
          title={t('common:GENART_DELETE')}
        />
      </Stack>
    )
  }
  return item[column.fieldName]
}

showGaGroupDialog = () => {
  this.brandPriorityStore.handleGaGroupDialog(this.brandPriorityStore.editItem)
}

handleCloseGaGroupDialog = () => {
  this.brandPriorityStore.handleGaGroupDialog()
  if (this.brandPriorityStore.selectedTabKey === 'brands') {
    this.brandPriorityStore.gaGroup = this.state.gaGroupOrig
  } else {
    this.brandPriorityStore.gaGroupSort = this.state.gaGroupOrig
  }
}

handleCloseGaGroupInsertDialog = () => {
  this.brandPriorityStore.handleCloseGaGroupInsertDialog()
}

showGaGroupInsertDialog = () => {
  const item = {
     id: '', 
     text: ''
  } 
  this.brandPriorityStore.handleCloseGaGroupInsertDialog(item)
}

handleGaGroupInsert = () => {
  if (this.brandPriorityStore.selectedTabKey === 'brands') {
    const child = this.brandPriorityStore.brandPriorityDocs.find(doc => doc.parent_gaid === this.brandPriorityStore.gaGroupItem.id)
    if (child) {
      alert(this.props.t('common:MSG_GENART_IS_PARENT'))
      return
    } else {
      this.brandPriorityStore.insertGaGroup()
      this.brandPriorityStore.handleCloseGaGroupInsertDialog()
    }
  } else {
    const child = this.brandPriorityStore.brandPriorityDocs.find(doc => doc.parent_sort_gaid === this.brandPriorityStore.gaGroupItem.id)
    if (child) {
      alert(this.props.t('common:MSG_GENART_IS_PARENT'))
      return
    } else {
      this.brandPriorityStore.insertGaGroupSort()
      this.brandPriorityStore.handleCloseGaGroupInsertDialog()
    }
  }
}

showGaGroupDelDialog = index => {
  this.setState({
    hideGaGroupDelDialog: false,
    gaDelIndex: index
  })
}

handleCloseGaGroupDelDialog = () => {
  this.setState({ hideGaGroupDelDialog: true })
}

handleDeleteGaGroup = () => {
  this.brandPriorityStore.removeGaFromGaGroup(this.state.gaDelIndex)
  this.setState({
    hideGaGroupDelDialog: true,
    gaDelIndex: undefined
  })
}

handleSaveGaGroup = () => {
  if (this.brandPriorityStore.selectedTabKey === 'brands' && this.brandPriorityStore.gaGroup.length > 0) {
    this.setState({gaDeletedFromGroup: this.state.gaGroupOrig.filter(ga => !this.brandPriorityStore.gaGroup.includes(ga))})
    this.setState({gaGroupOrig: [...this.brandPriorityStore.gaGroup]})
  }
  if (this.brandPriorityStore.selectedTabKey === 'sorts' && this.brandPriorityStore.gaGroupSort.length > 0) {
    this.setState({gaDeletedFromGroup: this.state.gaGroupOrig.filter(ga => !this.brandPriorityStore.gaGroupSort.includes(ga))})
    this.setState({gaGroupOrig: [...this.brandPriorityStore.gaGroupSort]})
  }
  this.brandPriorityStore.handleGaGroupDialog()
}

saveChild = async(item) => {
  const parent = this.brandPriorityStore.brandPriorityDocs.find(doc => doc.gaid === item.parent_gaid)
  console.log(parent)
  if (parent) {
    item.brands = typeof parent.brands === 'string' ? JSON.parse(parent.brands) : parent.brands
  } else {
    item.brands = typeof item.brands === 'string' ? JSON.parse(item.brands) : item.brands
  }
  item.sorts = typeof item.sorts === 'string' ? JSON.parse(item.sorts) : item.sorts
  console.log(item)
  await this.brandPriorityStore.saveDoc(item)
}

showDropDialog = (item, target)=> {
  let msg
  const parent = Object.values(this.brandPriorityStore.items).find(potentialParent =>
    potentialParent.children?.includes(item.index)
  )
  if (!parent || !parent.children) {
    return
  }
  if (target.targetType === 'item' || target.targetType === 'root') {
    if (target.targetItem === parent.index) {
      return
    } else {
      msg='MSG_DRAG_AND_DROP'
    }
  } else {
    if (target.parentItem === item.index) {
      // Trying to drop inside itself
      return
    }
    if (target.parentItem === parent.index) { // same parent
      return 
    } else {
      msg='MSG_DRAG_AND_DROP_2'
    }
  }
  this.setState({
    hideDropDialog: false,
    item: item,
    target: target,
    parent: parent,
    msg: msg
  })
}

handleCloseDropDialog = () => {
  this.setState({ hideDropDialog: true })
}

handleDragAndDrop = () => {
  const {item, target, parent } = this.state
  if (target.targetType === 'item' || target.targetType === 'root') {
      this.brandPriorityStore.items[parent.index].children = parent.children.filter(child => child !== item.index)
      this.brandPriorityStore.items[target.targetItem].children = [...(this.brandPriorityStore.items[target.targetItem].children ?? []), item.index]
      this.brandPriorityStore.items[target.targetItem].isFolder=true
      const indexItem = this.brandPriorityStore.brandPriorityDocs.findIndex(node => node.gaid === item.index)
      if (indexItem !== -1) {
        this.brandPriorityStore.brandPriorityDocs[indexItem].parent_gaid = target.targetItem === 'root' ? undefined : target.targetItem
        this.saveChild(this.brandPriorityStore.brandPriorityDocs[indexItem])
      }
  } else {
    const newParent = this.brandPriorityStore.items[target.parentItem];
    const newParentChildren = [...(newParent.children ?? [])].filter(
      child => child !== item.index
    )
    newParentChildren.splice(target.childIndex, 0, item.index);
    this.brandPriorityStore.items[parent.index].children = parent.children.filter(child => child !== item.index)
    this.brandPriorityStore.items[target.parentItem].children = newParentChildren
    this.brandPriorityStore.items[target.parentItem].isFolder=true
    const indexItem = this.brandPriorityStore.brandPriorityDocs.findIndex(node => node.gaid === item.index)
    if (indexItem !== -1) {
      this.brandPriorityStore.brandPriorityDocs[indexItem].parent_gaid = target.parentItem === 'root' ? undefined : target.parentItem
      this.saveChild(this.brandPriorityStore.brandPriorityDocs[indexItem])
    }
  }
  this.setState({
    hideDropDialog: true,
    item: undefined,
    target: undefined
  })
}

onDrop = (item, target) => {
  this.showDropDialog(item, target)
}

setHeaderColumns () {
  const { t } = this.props
  const columnsName = []
  this.brandPriorityStore.columns.forEach((col, index) => {
    col.name = col.key !== 'buttons' ? t(`common:${this.brandPriorityStore.constHeader[index].text}`) : ''
    columnsName.push(col)
  })
  return columnsName
}

handleItemColumn = (item, index, column) => {
  if (column.fieldName === 'buttons') {
    const { t } = this.props
    return (
      <Stack horizontal verticalAlign='start' verticalFill='true'>
        <IconButton
          iconProps={editIcon}
          title={t('common:BRAND_PRIORITY_EDIT')}
          onClick={() => this.showEditDialog(item, index)}
        />
        <IconButton
          onClick={() => this.showDelDialog(item)}
          iconProps={delIcon}
          title={t('common:BRAND_PRIORITY_DELETE')}
        />
      </Stack>
    )
  }
  return (<div id={`node_${item.gaid}`}>{item[column.fieldName]} {(this.brandPriorityStore.mapSortChildren.has(item.gaid) ? ` (${this.brandPriorityStore.mapSortChildren.get(item.gaid).join('; ')})` : '')}</div>)
}

handleLinkClick = (item) => {
  this.brandPriorityStore.selectedTabKey = item.props.itemKey
}

  render () {
    const { t, store: { brandPriorityStore: { items, editItem, showDialog, loading, gaGroup, gaGroupSort, showGaGroupDialog, showGaGroupInsertDialog, gaGroupItem, mapChildren, 
      selectedTabKey, filteredSorts }, esObjectStore : {genarts, mapGenarts } }} = this.props
    const { hideDelDialog, delItem, hideGaGroupDelDialog, gaDelIndex, hideDropDialog } = this.state
    const stackTokens = {
      childrenGap: 5,
      padding: 5
    }
    const containerStyle = {
      root: {
        width: '100%',
        height: '100%'
      }
    }
    const growingStyles = {
        root: {
            display: 'flex',
            height: '50%'
        }
    }
    return (
      <div className='page-container'>
        <Stack verticall tokens={stackTokens} styles={containerStyle}>
         <Pivot linkFormat="tabs"  onLinkClick={this.handleLinkClick} id='bp_tabs'>
          <PivotItem headerText={t('common:BRANDS')} itemKey='brands' key='brands' >
            <div id='brandsDocs'>
              <IconButton
                iconProps={addIcon}
                title={t('common:BRAND_PRIORITY_ADD')}
                onClick={() => this.showEditDialog()}
              />
              {loading && <Spinner size={SpinnerSize.large} /> }
              <ControlledTreeEnvironment
                canDragAndDrop={true}
                canDropOnFolder={true}
                canDropOnNonFolder={true}
                canReorderItems={true}
                canSearch={true}
                items={items}
                getItemTitle={item => 
                  item.data.gatxt
                }
                viewState={{
                  'tree-1': {
                    focusedItem: this.brandPriorityStore.focusedItem,
                    selectedItems: this.brandPriorityStore.selectedItems,
                    expandedItems: this.brandPriorityStore.expandedItems
                  },
                }}
                onSelectItems={(items) => {    
                  this.brandPriorityStore.selectedItems = items
                }} 
                onDrop={(itemsToMove, target) => {
                  itemsToMove.length > 0 && this.onDrop(itemsToMove[0], target)
                }}
                onFocusItem={item => 
                  this.brandPriorityStore.focusedItem = item.index
                }
                onExpandItem={item => {
                  this.brandPriorityStore.expandedItems = [...this.brandPriorityStore.expandedItems, item.index]
                }
                }
                onCollapseItem={item =>
                  this.brandPriorityStore.expandedItems = this.brandPriorityStore.expandedItems.filter(expandedItemIndex => expandedItemIndex !== item.index) 
                }
                renderItem={({ item, depth, children, title, context, arrow }) => {
                  const InteractiveComponent = context.isRenaming ? 'div' : 'button';
                  const type = context.isRenaming ? undefined : 'button';
                  return (
                    <li
                      {...(context.itemContainerWithChildrenProps)}
                      className="rct-tree-item-li"
                    >
                      <div id={`node_${item.index}`}
                        {...(context.itemContainerWithoutChildrenProps)}
                        style={{ paddingLeft: `${(depth + 1) * 15}px` }}
                        className={[
                          'rct-tree-item-title-container',
                          item.isFolder && 'rct-tree-item-title-container-isFolder',
                          context.isSelected && 'rct-tree-item-title-container-selected',
                          context.isExpanded && 'rct-tree-item-title-container-expanded',
                          context.isFocused && 'rct-tree-item-title-container-focused',
                          context.isDraggingOver &&
                            'rct-tree-item-title-container-dragging-over',
                          context.isSearchMatching &&
                            'rct-tree-item-title-container-search-match',
                        ].join(' ')}
                      >
                        {arrow}
                        <InteractiveComponent
                          type={type}
                          {...(context.interactiveElementProps)}
                          className="rct-tree-item-button"
                        >
                          {title} {mapChildren.has(item.data.gaid) ? ` (${mapChildren.get(item.data.gaid).join('; ')})` : ''}
                        </InteractiveComponent>
                        <IconButton iconProps={editIcon} title={t('common:NODE_EDIT')} /*disabled={item?.data.parent_gaid}*/ onClick={() => this.showEditDialog(item?.data)} />
                        <IconButton iconProps={delIcon} title={t('common:NODE_DELETE')} onClick={() => this.showDelDialog(item?.data)} />
                      </div>
                      {children}
                    </li>
                  );
                }}
              >
               <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" />
              </ControlledTreeEnvironment>
            </div>
            </PivotItem>
            <PivotItem headerText={t('common:SORTS')} itemKey='sorts' key='sorts' >
              <div id='sortsDocs'>
                <IconButton
                  iconProps={addIcon}
                  title={t('common:BRAND_PRIORITY_ADD')}
                  onClick={() => this.showEditDialog()}
                />
                {loading && <Spinner size={SpinnerSize.large} /> }
                <DetailsList ariaLabelForGrid='Shops'
                  items={filteredSorts} 
                  setKey='set'
                  columns={this.setHeaderColumns()}
                  layoutMode={DetailsListLayoutMode.justified}
                  selectionMode={SelectionMode.single}
                  enterModalSelectionOnTouch={true}
                  selectionPreservedOnEmptyClick={true}
                  constrainMode={ConstrainMode.unconstrained}
                  onRenderItemColumn={this.handleItemColumn}
                />
              </div>
            </PivotItem>
          </Pivot> 
        </Stack>
        {showDialog && <Dialog
          minWidth={800}
          maxWidth={1000}
          hidden={!showDialog}
          onDismiss={this.handleCloseInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: editItem.id === undefined ? t('common:BRAND_PRIORITY_ADD') : t('common:BRAND_PRIORITY_EDIT'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticall tokens={stackTokens}>
           <Stack.Item styles={growingStyles}> 
              <TextField label={t('common:NODE_FILTER_GAID')} id='ga_group' type='text'  readOnly value={ this.brandPriorityStore.getGaGroup() || '' } styles={{fieldGroup: { width: 800, height: 25, float: 'right' }}}/>
              <IconButton iconProps={changeIcon} title={t('common:GA_GROUP_ADD')} onClick={() => this.showGaGroupDialog()} />
            </Stack.Item> 
            <Stack.Item styles={{root: {width: '100%', height: '100%'}}}>
                {selectedTabKey === 'brands'&& <div id='brands'><Brands/></div>}
                {selectedTabKey === 'sorts'&& <div id='sorts'><Sorts/></div>}
            </Stack.Item>
            </Stack> 
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {hideDelDialog === false && <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:BRAND_PRIORITY_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:BRAND_PRIORITY_DELETE_QESTION') + (delItem !== undefined ? `(${delItem.gatxt})` : '')
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>} 
        {showGaGroupDialog && <Dialog
          minWidth={500}
          maxWidth={700}
          hidden={!showGaGroupDialog}
          onDismiss={this.handleCloseClickGroupDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:GA_GROUP_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
              <IconButton
                iconProps={addIcon}
                title={t('common:GENART_ADD')}
                onClick={() => this.showGaGroupInsertDialog()}
              />         
              <div style={{ position: "relative", height: 300 }} id='gaGroupListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='gaGroupDetailsList'
                    items={selectedTabKey === 'brands' ? gaGroup : gaGroupSort} 
                    setKey='set'
                    columns={this.setGaGroupHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderItemColumn={this.handleGaGroupItemColumn}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSaveGaGroup} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseGaGroupDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        { showGaGroupInsertDialog && <Dialog
          minWidth={400}
          maxWidth={600}
          hidden={!showGaGroupInsertDialog}
          onDismiss={this.handleCloseGaGroupInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:GENART_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
            <Stack horizontal tokens={stackTokens}>
            <Label>{t('common:NODE_FILTER_GAID')}</Label>
              <LookupDropdown
                placeholder={t('common:NODE_FILTER_GAID')}
                defaultValue={gaGroupItem !== undefined ? gaGroupItem.id : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={genarts}
                onChange={(e, { value }) => {
                    gaGroupItem.id = value
                    gaGroupItem.text = value + '-' + mapGenarts.get(this.esObjectStore.defaultLang).get(value)
                }}
              />
              </Stack>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleGaGroupInsert} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseGaGroupInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {!hideGaGroupDelDialog && <Dialog
          hidden={hideGaGroupDelDialog}
          onDismiss={this.handleCloseGaGroupDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:GENART_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:GENART_DELETE_QESTION') + (selectedTabKey === 'brands' ? (gaGroup[gaDelIndex] !== undefined ? gaGroup[gaDelIndex].text : '') : (gaGroupSort[gaDelIndex] !== undefined ? gaGroupSort[gaDelIndex].text : '')) + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteGaGroup} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseGaGroupDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}      
        {!hideDropDialog && <Dialog
          hidden={hideDropDialog}
          onDismiss={this.handleCloseDropDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:BRAND_EDIT'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t(`common:${this.state.msg}`)
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDragAndDrop} text={t('common:BUTTON_YES')} />
            <DefaultButton onClick={this.handleCloseDropDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>} 
      </div>   
    )
  }
}

export { BrandPriorityDocs }
export default withTranslation(['common'], { wait: true })(withMainContainer(BrandPriorityDocs))