import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { ScrollablePane, ScrollbarVisibility, Pivot, PivotItem, Label, TextField, Dropdown, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter, DetailsRowFields } from '@fluentui/react'
import withMainContainer from './../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
// styles
import './Portal.scss'

@inject('store')
@observer
class Article extends Component {
  constructor (props) {
    super(props)
    this.appStore = this.props.store.appStore
    this.articleStore = this.props.store.articleStore
    this.authStore = this.props.store.authStore
    this.usersStore = this.props.store.usersStore
    this.selection = new Selection({
      onSelectionChanged: () => {
      }
    })
    const user = this.authStore.userItems.find(user => user.user === this.authStore.current_user.UserName.toLowerCase())
    if (user !== undefined) {
      this.articleStore.searchByArtNr = this.authStore.hasUserRight('search_by_art_nr')
      this.setChecked(user)
    }
    this.state = {
      selectedEnv: null,
      loadingArticles: false,
      loadingArticle: false,
      showArticleDialog: false,
      showArticleJsonDialog: false,
      showEsArticleJsonDialog: false,
      user
    }
  }

  componentDidMount () {
    if (this.appStore.envs.length === 0) {
      this.appStore.fetchEnvs()
    }
    if (this.articleStore.mapWisch.size === 0) {
      this.articleStore.loadWischFile()
    }
    if (this.articleStore.excludeOELocksAT.length === 0) {
      this.articleStore.loadExcludeOELocksATFile()
    }
    this.articleStore.resetFields()
  }

  setChecked = (user) => {
    if (user !== undefined) {
      this.articleStore.checked = []
      const roles = this.authStore.usersMap.get(user.id).filter(role => Object.keys(this.articleStore.chShopsMapping).map(cty => 'cty_' + cty).includes(role.role))
      if (roles !== undefined) {
          roles.forEach(role => {
              if (role.value === 1) this.articleStore.checked.push(role.role.substring(4)) // remove cty_
          }) 
      } else {
          this.articleStore.checked = []
      }
    }
  }

  handleSearchText = (event, text) => {
    this.articleStore.onSearchTextChanged(text)
  }

  handleSearchByArtNrCheck = (value) => {
    this.articleStore.onSearchByArtNrChecked(value)
    this.state.user.search_by_art_nr = value
    this.usersStore.saveRole(this.state.user.id, 'search_by_art_nr', value)
  }

  handleCtyCheck = (event, newValue) => {
    this.articleStore.handleCheck(event, newValue)
    const promises = []
    for (const cty of Object.keys(this.articleStore.chShopsMapping)) {
      promises.push(this.usersStore.saveRole(this.state.user.id, 'cty_' + cty, this.articleStore.checked.includes(cty)))
    }
    Promise.all(promises)
  } 

  handleSearch = () => {
    this.articleStore.items = []
    if (this.state.selectedEnv === null) {
      alert('Select the environment !')
      return
    }
    if (this.articleStore.checked.length === 0) {
      alert('Select the countries !')
      return
    }
    if (this.state.loadingArticles === true) {
      return
    }
    this.setState({
      loadingArticles: true
    })
    this.articleStore.serchArticles(this.state.selectedEnv).then(() => 
    this.setState({
      loadingArticles: false,
      selectedCountry: undefined,
      selectedItemEs: undefined
    }))
  }

  showArticle = (item) => {
    if (this.state.loadingArticle === true) {
      return
    }
    this.setState({ loadingArticle: true })
    this.articleStore.defaultLang = this.props.store.appStateStore.get_gui_language()
    this.articleStore.loadArticleDetails(this.state.selectedEnv, item).then(() =>
      this.setState({
        loadingArticle: false,
        showArticleDialog: true
      })
    )
  }
  
  handleCloseArticleDialog = () => {
    this.setState({
      showArticleDialog: false,
      selectedCountry: undefined,
      selectedItemEs: undefined
    })
  }

  showArticleJson = () => {
    this.setState({
      showArticleJsonDialog: true,
    })
  }
  
  handleCloseArticleJsonDialog = () => {
    this.setState({
      showArticleJsonDialog: false
    })
  }

  showEsArticleJson = () => {
    if (this.articleStore.countries.length === 0) {
      alert('Article not found in Connect !')
      return
    }
    if (this.state.selectedCountry === undefined) {
      this.setState({
        selectedCountry: this.articleStore.countries[0].toUpperCase()
      })
    }
    if (this.state.selectedItemEs === undefined) {
      if (Object.keys(this.articleStore.itemEs).length > 0 && this.articleStore.itemEs[this.state.selectedCountry !== undefined ? this.state.selectedCountry : this.articleStore.countries[0].toUpperCase()] !== undefined &&
        Object.keys(this.articleStore.itemEs[this.state.selectedCountry !== undefined ? this.state.selectedCountry : this.articleStore.countries[0].toUpperCase()]).length > 0) {
          this.setState({
            selectedItemEs: this.articleStore.itemEs[this.state.selectedCountry !== undefined ? this.state.selectedCountry : this.articleStore.countries[0].toUpperCase()][Object.keys(this.articleStore.itemEs[this.state.selectedCountry !== undefined ? this.state.selectedCountry : this.articleStore.countries[0].toUpperCase()])[0]]
          })
      } else {
        alert(`Article not found in Connect for ${this.state.selectedCountry !== undefined ? this.state.selectedCountry : this.articleStore.countries[0].toUpperCase() } !`)
        return
      }
    }
    this.setState({
      showEsArticleJsonDialog: true
    })
  }
  
  handleCloseEsArticleJsonDialog = () => {
    this.setState({
      showEsArticleJsonDialog: false
    })
  }

  handleCountryClick = (item) => {
    this.setState({
      selectedCountry: item.props.itemKey,
      selectedItemEs: undefined
    })
    this.articleStore.populateDetailItems(item.props.itemKey, this.articleStore.langsByCountry.get(item.props.itemKey)[0])
  }

  handleLangClick = (item) => {
    if (this.articleStore.countries.length === 0) {
      return
    }
    if (this.state.selectedCountry === undefined) {
      this.setState({ selectedCountry: this.articleStore.countries[0].toUpperCase()})
    }
    this.setState({selectedItemEs: this.articleStore.itemEs[this.state.selectedCountry !== undefined ? this.state.selectedCountry : this.articleStore.countries[0].toUpperCase()] !== undefined ? this.articleStore.itemEs[this.state.selectedCountry !== undefined ? this.state.selectedCountry : this.articleStore.countries[0].toUpperCase()][item.props.itemKey] : undefined})
    this.articleStore.populateDetailItems(this.state.selectedCountry !== undefined ? this.state.selectedCountry : this.articleStore.countries[0].toUpperCase(), item.props.itemKey)
  }

  renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName]
    switch (column.key) {
      case 'servo':
        if (Array.isArray(fieldContent) && fieldContent.length > 0) {
          if (item.detail === 'Documents') {
            return(
              <div style={{overflowX: 'auto', whiteSpacee: 'nowrap', marginTop: 5 }}>
                {fieldContent.map((doc, index) => (
                  <a key={doc.url+'_'+index} href={doc.url} target="_blank">
                    <img src={doc.url !== undefined && doc.url.endsWith('pdf') ? '/images/pdf_image.png' : doc.url !== undefined && doc.url.includes('youtube.com') ? `https://i.ytimg.com/vi/${doc.url.includes('?') ? doc.url.substring(doc.url.lastIndexOf('/') + 1, doc.url.indexOf('?')) : doc.url.substring(doc.url.lastIndexOf('/') + 1)}/default.jpg` : doc.url !== undefined ? doc.url: '/images/missing_image.png'} title={(doc.url !== undefined ? doc.url : '') + ' (lang: ' + doc.language + ')'} width="75" height="75" style={{paddingLeft: 5}} />
                  </a>
                ))}
              </div>
            )
          } else if (item.detail === 'Info') {
            return(
              <div style={{overflowX: 'auto', whiteSpacee: 'nowrap', marginTop: 5 }}>
                {fieldContent.map((info, index) => (
                  <div className={'info_text'} title={info.text} key={info.id_textmodule +'_'+index}>{info.text}</div>
                ))}
              </div>
            )
          } else {// ERP ID and Other genarts
            return (
              <div>
                {fieldContent.map(erpId => (
                    <div key={erpId}>
                        <p>{erpId}</p>
                    </div>
                ))}
              </div>
            )
          }
        } else {
          return <span>{fieldContent}</span>
        }
      case 'detail':
        return <span style={{textAlign: 'right'}}><b>{fieldContent}</b></span>
      case 'es':
        if (Array.isArray(fieldContent) && fieldContent.length > 0) {
          if (item.detail === 'Info') {
            return(
              <div style={{overflowX: 'auto', whiteSpacee: 'nowrap', marginTop: 5  }}>
                {fieldContent.map((doc, index) => {
                  if (doc.info_txt !== undefined && doc.info_txt.includes('youtube.com')) 
                    return(
                      <a key={doc.info_txt+'_'+index} href={doc.info_txt} target="_blank">
                        <img src={`https://i.ytimg.com/vi/${doc.info_txt.includes('?') ? doc.info_txt.substring(doc.info_txt.lastIndexOf('/') + 1, doc.info_txt.indexOf('?')) : doc.info_txt.substring(doc.info_txt.lastIndexOf('/') + 1)}/default.jpg`} title={doc.info_txt + ' (type: ' + doc.info_type + ')'} width="75" height="75" style={{paddingLeft: 5}}/>
                      </a>
                    )
                  else return(
                    <div className={'info_text'} title={doc.info_txt + ' (type: ' + doc.info_type + ')'} key={doc.id_info+'_'+index}>{doc.info_txt}</div>
                  )
                })}
              </div>
            ) 
          } else if (item.detail === 'Documents') {
            return(
              <div style={{overflowX: 'auto', whiteSpacee: 'nowrap', marginTop: 5  }}>
                {fieldContent.map((doc, index) => (
                  <a key={doc.ref+'_'+index} href={doc.ref} target="_blank">
                    <img src={doc.ref.toString().endsWith('pdf') ? '/images/pdf_image.png' : doc.ref} title={doc.ref + ' (type: ' + doc.img_typ + ')'} width="75" height="75" style={{paddingLeft: 5}}/>
                  </a>
                ))}
              </div>
            )
          }
        } else if (index === 0 && fieldContent?.match(/[a-zA-Z]/)) {
          return <span style={{color: 'red'}}>{fieldContent}</span>
        } else {
          return <span>{fieldContent}</span>
        } 
      default:
        return <span>{fieldContent}</span>;
    }
  }

  colorRow = (props, defaultRender) => {
    let bgcolor = props.itemIndex%2 === 0 ? 'white' : '#E0E0E0'
    if (props.item.es === ' ') {
      bgcolor = 'rgba(255,0, 0, 0.1)'
    }
    return <div>{defaultRender({...props, styles: {root: { background: bgcolor }}})} </div>;
  }

  render () {
    const { t, store: { appStore: { envs }, articleStore: { searchText, servoItemsCount, items, columns, columnsDetails, item, itemEs, searchByArtNr, detailsItems, chShopsMapping, countries, langsByCountry } } } = this.props
    const { selectedEnv, showArticleDialog, showArticleJsonDialog, showEsArticleJsonDialog, selectedItemEs, loadingArticle, loadingArticles } = this.state
    const stackTokens = {
      childrenGap: 5,
      padding: 5
    }
    const stackStyles = {
      root: {
        width: '70vw'
      }
    }
    return (
    <div className='page-container'>
      <div className='arti-container'>
        <Stack vertical tokens={stackTokens}>
          <Stack horizontal tokens={stackTokens} >
            <Dropdown id='env'
              placeholder={t('common:DROPDOWN_ENV_PLACEHOLDER')}
              label={t('common:ENV')}
              options={envs}
              onChange={(event, { key }) => {
                this.setState({ selectedEnv: key })
                this.appStore.fetchManufacturers(key)
                }
              }
              styles={{dropdown: { width: 200, height: 20, float: 'right' },
              caretDownWrapper: { height:20, lineHeight: 'normal' },
              dropdownItem: { height:20 } }}
            />  
            <TextField label={t('common:SEARCH')} value={searchText} onChange={this.handleSearchText} styles = {{ fieldGroup: { width: 160, height: 25 }}} title={t('common:SEARCH_TITLE')} />
            <PrimaryButton text={t('common:SEARCH')} onClick={this.handleSearch} title={t('common:SEARCH_TITLE')} />
            <Checkbox label='Search also by article number' defaultChecked={searchByArtNr} onChange={(event, newValue) => this.handleSearchByArtNrCheck(newValue)} />
              <Label style={ {marginLeft: '3em'}}></Label>
              {Object.keys(chShopsMapping).map((item, index) => (
                <span key={index} style={ {marginLeft: '1.2em'}}>
                  <Checkbox id={item} label={item} checked={ this.articleStore.checked.includes(item) } onChange={this.handleCtyCheck} />
                </span>
              ))}  
          </Stack>
          <div style={{paddingLeft: 5}}><TextField label={t('common:RECORDS_COUNT_SERVO')} value={servoItemsCount} borderless readOnly styles = {{ fieldGroup: { width: 160, height: 25 }}}/></div>
          <div style={{ position: "relative", height: 400 }} id='apiListItem'>    
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} >
              <DetailsList id='esDetailsList'
                items={items} 
                setKey='set'
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                enterModalSelectionOnTouch
                constrainMode={ConstrainMode.unconstrained}
                onItemInvoked={this.showArticle}
              />
            </ScrollablePane>   
            {loadingArticles && <Spinner size={SpinnerSize.large} /> }
            {loadingArticle && <Spinner size={SpinnerSize.large} /> }
          </div>
        </Stack> 
        { showArticleDialog && <Dialog
          minWidth={1200}
          maxWidth={1200}
          hidden={!showArticleDialog}
          onDismiss={this.handleCloseArticleDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: `Article View  GRID=${item.id_art}  ${item.manufacturer}  ${item.article !== undefined && item.article.manufactureritemnumber !== undefined ? item.article.manufactureritemnumber : ''}`,
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
        <div id ="detailsTab">
        <Stack horizontal tokens={{ padding: 0, childrenGap: 5}} styles={ {root: {width: '100%'}}}>
            <Stack className='left_side' vertical tokens={{ padding: 0, childrenGap: 5}} styles={stackStyles}>
              <span style={{paddingBottom:10, paddingLeft:350}}><h4>Servo</h4></span>
            </Stack>
            <Stack vertical tokens={{ padding: 0, childrenGap: 5 }} styles={{ root: { width: '100%'}}}>
              <span style={{paddingBottom:10, paddingRight: 250, textAlign: 'right'}}><h4>Connect Data</h4></span>
            </Stack>     
          </Stack>    
          {countries.length > 0 && <Pivot aria-label="Tree" linkFormat="tabs" onLinkClick={this.handleCountryClick} >
            {countries.map(country => {
              return (
                <PivotItem headerText={country.toUpperCase()} itemKey={country.toUpperCase()} key={country.toUpperCase()}>
                  <Pivot aria-label="Tree" linkFormat="tabs" onLinkClick={this.handleLangClick} >
                    {langsByCountry.get(country.toUpperCase()).map(lang => {
                      return (
                        <PivotItem headerText={lang} itemKey={lang} key={`${country}_${lang}`}>
                          <div id ="detailsList">    
                            <DetailsList id='detailsListItems'
                              isHeaderVisible={false}
                              items={detailsItems} 
                              setKey='set'
                              columns={columnsDetails}
                              layoutMode={DetailsListLayoutMode.justified}
                              selectionMode={SelectionMode.none}
                              enterModalSelectionOnTouch
                              constrainMode={ConstrainMode.unconstrained}
                              onRenderItemColumn={this.renderItemColumn}
                              onRenderRow={this.colorRow}
                            />
                          </div>
                          <Stack horizontal tokens={{ padding: 0, childrenGap: 5}} styles={ {root: {width: '100%'}}}>
                            <Stack vertical tokens={{ padding: 0, childrenGap: 5}} styles={stackStyles}>
                              <span style={{paddingBottom:10, paddingLeft:350}}>
                                <PrimaryButton onClick={this.showArticleJson} text={'Show JSON'} style={ { width: '100px'} } />
                              </span>
                            </Stack>
                            <Stack vertical tokens={{ padding: 0, childrenGap: 5 }} styles={{ root: { width: '100%'}}}>
                              <span style={{paddingBottom:10, paddingRight: 230, textAlign: 'right'}}>
                                <PrimaryButton onClick={this.showEsArticleJson} text={'Show JSON'} style={ { width: '100px'} }/>
                              </span>
                            </Stack>     
                          </Stack>  
                        </PivotItem>
                      );
                    })}
                  </Pivot>
                </PivotItem>
              );
            })}
          </Pivot>}
          {countries.length == 0 && <div id ="detailsList">    
            <DetailsList id='detailsListItems'
              isHeaderVisible={false}
              items={detailsItems} 
              setKey='set'
              columns={columnsDetails}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              enterModalSelectionOnTouch
              constrainMode={ConstrainMode.unconstrained}
              onRenderItemColumn={this.renderItemColumn}
              onRenderRow={this.colorRow}
            />
          </div>}
          {countries.length == 0 && <Stack vertical tokens={{ padding: 0, childrenGap: 5}}>
            <span style={{paddingBottom:10, paddingLeft:250}}>
              <PrimaryButton onClick={this.showArticleJson} text={'Show JSON'} style={ { width: '100px'} } />
            </span>
          </Stack>}
        </div>
          <DialogFooter>
            <DefaultButton onClick={this.handleCloseArticleDialog} text={t('common:BUTTON_CLOSE')} />
          </DialogFooter>
        </Dialog>}
        { showArticleJsonDialog && <Dialog
          minWidth={900}
          maxWidth={700}
          hidden={!showArticleJsonDialog}
          onDismiss={this.handleCloseArticleJsonDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Servo Json Data',
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <pre className='detail-p'>{item !== undefined ? item.json_data : ''}</pre>
          </Stack>
          <DialogFooter>
            <DefaultButton onClick={this.handleCloseArticleJsonDialog} text={t('common:BUTTON_CLOSE')} />
          </DialogFooter>
        </Dialog>}
        { showEsArticleJsonDialog && <Dialog
          minWidth={900}
          maxWidth={700}
          hidden={!showEsArticleJsonDialog}
          onDismiss={this.handleCloseEsArticleJsonDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: `Connect Json Data for ${this.state.selectedCountry === undefined ? this.articleStore.countries[0].toUpperCase() : this.state.selectedCountry }`,
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <pre className='detail-p'>{selectedItemEs !== undefined ? selectedItemEs : ''}</pre>
          </Stack>
          <DialogFooter>
            <DefaultButton onClick={this.handleCloseEsArticleJsonDialog} text={t('common:BUTTON_CLOSE')} />
          </DialogFooter>
        </Dialog>}
      </div>    
    </div>          
    )
  }
}

export { Article }
export default withTranslation(['common'], { wait: true })(withMainContainer(Article))