import { observable } from 'mobx'

class TilesExternalStore {
  @observable externalItems = []
  @observable columns = []  
  @observable showEditDialog = false
  @observable externalItem = undefined
  
  externalTypes = [
    { key: 'leaf', text: 'leaf'},
    { key: 'link', text: 'link'},
    { key: 'node', text: 'node'},
    { key: 'service', text: 'service'},
    { key: 'shop', text: 'shop'}
  ]

  constHeader = [
    { key: 'tile_link_text_trans', text : 'TILE_EXTERNAL_TEXT', width: '25%', minWidth: 120, maxWidth: 250 },
    { key: 'tile_link_type', text : 'TILE_TYPE', width: '10%', minWidth: 30, maxWidth: 30 },
    { key: 'tile_link_attr', text : 'TILE_EXTERNAL_ATTR', width: '15%', minWidth: 50, maxWidth: 100 },
    { key: 'tile_link_highlighted', text : 'TILE_EXTERNAL_HIGHLIGHTED', width: '15%', minWidth: 50, maxWidth:100 },
    { key: 'tile_link_str', text : 'TILE_LINK', width: '25%', minWidth: 90, maxWidth: 250 },
    { key: 'buttons', text: '', width: '10%', minWidth: 50, maxWidth: 50 }
  ]

  constructor (authStore, tileStore) {
    this.authStore = authStore 
    this.tileStore = tileStore
    this.constHeader.forEach(({key, width, minWidth, maxWidth}) => this.columns.push({
      key,
      fieldName: key,
      isResizable: true,
      width,
      minWidth,
      maxWidth,
    }))
  }

  /**
   * Hide / Show dialog
   */
  handleShowEditDialog = (external) => {
    this.showEditDialog = !this.showEditDialog
    this.externalItem = external
  } 
  
  editExternal = () => {
    if (!this.externalItem.isEdit) {
      this.externalItems.push(this.externalItem)
    }
    this.tileStore.editTile.tile_external = this.externalItems
  }

  removeExternal = (delIndex) => {
    this.externalItems.splice(delIndex, 1)
    this.tileStore.editTile.tile_external = this.externalItems
  } 
}

export default TilesExternalStore
