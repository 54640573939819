import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb';
import { Label, TextField, Dropdown, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from '../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import Tree from './Tree'
import dateFormat from "dateformat";
import { TagPicker } from '@fluentui/react/lib/Pickers';

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const viewIcon = { iconName: 'LookupEntities' }
const exportIcon = { iconName: 'CloudUpload' }

@inject('store')
@observer
class UniTree extends Component {
  constructor (props) {
    super(props)
    this.unitreeStore = this.props.store.unitreeStore
    this.esObjectStore = this.props.store.esObjectStore
    this.treeStore = this.props.store.treeStore
    this.selection = new Selection({
      onSelectionChanged: () => {
      }
    })
    this.state = {
      showEsObjects: true,
      showTrees: false,
      hideDelDialog: true,
      hideEditDialog: true,
      loadingTrees: false, 
      loadingExport: false,
      showConfirmExportDialog: false
    }
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.esObjectStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.esObjectStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  componentDidMount () {
    this.unitreeStore.populateUniTrees(true)
  }

  populateTree = (item) => {
    this.setState({loadingTrees: true})
    this.unitreeStore.selectedUnitree = item
    const shop =  [...this.esObjectStore.mapShops.values()].find(el => el.id === item.shop_id)
    if (shop !== undefined) {
      this.esObjectStore.initialiseData(shop, this.props.store.appStateStore.get_gui_language())
      this.treeStore.populateTrees(item.id).then(() => {
        this.breadcrumbitems.push({ text: `${item.shop_name}_${item.index_type}_${item.version_name}`, key: 'tree' })
          Promise.all([this.esObjectStore.fetchCriterias(this.esObjectStore.defaultLang, this.unitreeStore.selectedUnitree.env), 
            this.esObjectStore.fetchGenarts(this.unitreeStore.selectedUnitree.env),
            this.esObjectStore.fetchSuppliers(this.unitreeStore.selectedUnitree.env),
            this.esObjectStore.fetchArtgrps(shop.shop_name, this.esObjectStore.defaultLang, this.unitreeStore.selectedUnitree.env)]).then (
              () => this.setState({
                showEsObjects: false,
                showTrees: true,
                loadingTrees: false
              }))
      })
    }
  }

  exportEsObject = (item) => {
    this.esObjectStore.getIndexAliases(item).then((actions) =>{
      this.setState({ loadingExport: false})
      if (actions.length > 0) {
        this.setState({ showConfirmExportDialog: true, item, actions})
      } else {
        this.setState({ loadingExport: true})
        console.log(`Will export unitree ${item.id} into ES`)    
        this.esObjectStore.exportEsObject(item).then(() => { 
          this.setState({ loadingExport: false})
          alert(`Unitree ${item.id} exported!`)
        })
      }
    })
  }

  onBreadcrumbItemClicked = (ev, item) => {
    this.setState({
      showEsObjects: true,
      showTrees: false
    });
    this.unitreeStore.selectedUnitree = null
    this.breadcrumbitems.length > 1 && this.breadcrumbitems.pop()
  }

  breadcrumbitems = [
    { text: 'Unitree', key: 'unitree', onClick: this.onBreadcrumbItemClicked }
  ]

  handleEdit = () => {
    // check if a unitree with same fields exists
    const found = this.unitreeStore.unitrees.filter(item => (item.shop_name === this.state.editItem.shop_name && 
      item.version_name === this.state.editItem.version_name && item.id !== this.state.editItem.id))
    if (found.length > 0) {
      alert(this.props.t('common:UNITREE_ALREADY_EXISTS_MSG'))
      this.unitreeStore.populateUniTrees()
    }  else {
      this.esObjectStore.editESObject(this.state.editItem).then(() => 
      this.unitreeStore.populateUniTrees().then(() => 
        this.setState({
          hideEditDialog: true,
          editItem: undefined
        })
       )
      )
    }
  }

  showEditDialog = item => {
    if (item === undefined) {
      item = {
        index_type: 'unitree',
        shop_name: '',
        version_name: dateFormat(new Date(), "yyyymmdd"), 
      }
    }
    this.setState({
      hideEditDialog: false,
      editItem: item
    })
  }

  handleCloseEditDialog = () => {
    this.setState({ hideEditDialog: true })
    this.unitreeStore.populateUniTrees()
  }
  
  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.esObjectStore.removeESObject(this.unitreeStore.unitrees[this.state.delIndex]).then(() =>
      this.unitreeStore.populateUniTrees().then(() =>
        this.setState({
          hideDelDialog: true,
          delIndex: -1
        })
      )
    )
  }

  handleItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={viewIcon}
            title={t('common:UNITREE_VIEW')}
            onClick={() => this.populateTree(item)}
          />
          <IconButton
            iconProps={editIcon}
            title={t('common:UNITREE_EDIT')}
            onClick={() => this.showEditDialog(item)}
          />
          <IconButton
            iconProps={exportIcon}
            title={t('common:UNITREE_EXPORT')}
            onClick={() => this.exportEsObject(item)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:UNITREE_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  getTextFromItem = (item) => {
    return item.name;
  }
  listContainsDocument = (tag, tagList) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.filter(compareTag => compareTag.key === tag.key).length > 0;
  };
  onFilterChanged = (filterText, tagList) => {
    if (this.unitreeStore.searchTexts === undefined || !filterText) {
      return []
    }
    // search by node name
    const filteredTags = this.unitreeStore.searchTexts.filter(tag => tag.name.substring(tag.name.lastIndexOf('>') + 1).toLowerCase().indexOf(filterText.toLowerCase()) >= 0)
          .filter(tag => !this.listContainsDocument(tag, tagList))
    // search by genart
    const gaids = []
    this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).forEach((value, key) => {
      if (value !== undefined && value.toLowerCase().indexOf(filterText.toLowerCase()) >= 0) {
        gaids.push(key)
      }
    })
    if (gaids.length > 0) {
      const filteredTagsByGaid = []
      this.unitreeStore.searchTexts.forEach(tag => {
        const genartsArr = tag.node.include.genarts.length > 0 ? tag.node.include.genarts.split(',') : []
        let foundGaid = false
        genartsArr.forEach(genart => {
          if (gaids.includes(genart)) {
            foundGaid = true
          }
        })
        if (foundGaid && filteredTags.find(compareTag => compareTag.key === tag.key) === undefined) {
          filteredTagsByGaid.push(tag)
        }
      })
      if (filteredTagsByGaid.length > 0) {
        filteredTags.push.apply(filteredTags, filteredTagsByGaid)
      }  
    }
    return filteredTags;
  }

  handleCloseConfirmExportDialog = () => {
    this.setState({ showConfirmExportDialog: false })
  }

  handleExport = () => {
    this.setState({
      showConfirmExportDialog: false, 
      loadingExport: true
    })
    console.log(`Will export unitree ${this.state.item.id} into ES`)    
    this.esObjectStore.exportEsObject(this.state.item).then(() => 
      this.esObjectStore.setAliases(this.state.item.env, this.state.actions).then(() => {
        this.setState({ loadingExport: false })
        alert(`Unitree ${this.state.item.id} exported!`)
      }
    ))
  }

  render () {
    const { t, store: { unitreeStore: { unitrees } ,
      esObjectStore : {shops, envs } } } = this.props
    const { hideEditDialog, hideDelDialog, editItem, loadingTrees, loadingExport, showConfirmExportDialog } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const containerStyle = {
      root: {
        width: '100%',
        height: '100%'
      }
    }
    const growingStyles = {
      root: {
        display: 'flex',
        height: '50%',
        width: 400,
      }
    }
    const textFieldsStyles = {
      fieldGroup: { width: 200, height: 20, float: 'right' }
    }
    const pickerSuggestionsProps = {
      noResultsFoundText: 'No result found',
    };
    return (
      <div className='page-container'>
        <Stack verticall tokens={stackTokens} styles={containerStyle}>
        <Stack horizontal tokens={stackTokens}>
        <Stack.Item styles={growingStyles}>   
          <Breadcrumb style={{width: '400px'}}
            items={this.breadcrumbitems} 
          />
        </Stack.Item>
        <Stack.Item styles={growingStyles}> 
          { this.state.showTrees && <Label style={{margin: '10px', fontSize: '12px'}}>{t('common:SEARCH')}</Label> } 
          { this.state.showTrees && <TagPicker style={{width: '400px'}}
            resolveDelay='1000'//ms
            onResolveSuggestions={this.onFilterChanged}
            getTextFromItem={this.getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            onItemSelected={(item) => {
              this.unitreeStore.searchNode = item.node.id
              this.treeStore.selectedTreeKey = item.tree_id
              const selectedTree = this.treeStore.items.find(tree => tree.id === item.tree_id)
              if (selectedTree !== undefined) {
                this.unitreeStore.selectedTreeLastUpdate = selectedTree.last_updated_date
                this.unitreeStore.selectedTreeLastUpdatedBy = selectedTree.updated_by
              }
              this.props.store.nodeStore.populateNodesTree(this.treeStore.selectedTreeKey).then(() => {
                const nodeTree = this.props.store.nodeStore.items[item.node.leaf_id]
                if (nodeTree !== undefined) {
                  this.props.store.nodeStore.selectItem(nodeTree)
                }
                })
              return null;
            }}
          />}
          </Stack.Item>
          </Stack>
          {loadingTrees && <Spinner size={SpinnerSize.large} /> }
          {loadingExport && <Spinner size={SpinnerSize.large} /> }
          {this.state.showEsObjects && <div id='unitreeDetailsList'>
              <DetailsList ariaLabelForGrid='Unitree'
              items={unitrees} 
              setKey='set'
              columns={this.setHeaderColumns()}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.single}
              selection={this.selection}
              enterModalSelectionOnTouch={true}
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={this.populateTree}
              constrainMode={ConstrainMode.unconstrained}
              onRenderItemColumn={this.handleItemColumn}
            />
            <IconButton
              iconProps={addIcon}
              title={t('common:UNITREE_ADD')}
              onClick={() => this.showEditDialog()}
          />
            </div>
          }
          {this.state.showTrees && <Tree/> }
        </Stack>
        <Dialog
          minWidth={400}
          maxWidth={800}
          hidden={hideEditDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: editItem !== undefined && editItem.id !== undefined ? t('common:UNITREE_EDIT') : t('common:UNITREE_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
          <Dropdown id='env'
                placeholder={t('common:UNITREE_DROPDOWN_ENV_PLACEHOLDER')}
                label={t('common:UNITREE_ENV')}
                defaultSelectedKey={editItem !== undefined ? editItem.env : ''}
                options={envs}
                onChange={(event, { key }) => {
                  if (editItem !== undefined) {
                    editItem.env = key
                    this.setState({
                      editItem
                    })
                  }}
                }
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
            <Dropdown id='shop_name'
                placeholder={t('common:UNITREE_DROPDOWN_SHOP_PLACEHOLDER')}
                label={t('common:UNITREE_SHOP_NAME')}
                defaultSelectedKey={editItem !== undefined ? editItem.shop_id : ''}
                options={shops}
                onChange={(event, { key, text }) => {
                  if (editItem !== undefined) {
                    editItem.shop_name = text
                    editItem.shop_id = key
                    this.setState({
                      editItem
                    })
                  }}
                }
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
            <TextField label={t('common:UNITREE_VERSION_NUMBER')} id='version_name' defaultValue={editItem !== undefined ? editItem.version_name : ''} 
              onChange={(event, newValue) => {
                if (editItem !== undefined) {
                  editItem.version_name = newValue
                  this.setState({
                    editItem
                  })
                }
              }}
              styles={textFieldsStyles}/>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:UNITREE_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:UNITREE_DELETE_QESTION')
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        { showConfirmExportDialog && <Dialog
          hidden={!showConfirmExportDialog}
          onDismiss={this.handleCloseConfirmExportDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:UNITREE_EXPORT'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:UNITREE_EXPORT_QESTION')
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 500 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleExport} text={t('common:BUTTON_YES')} />
            <DefaultButton onClick={this.handleCloseConfirmExportDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
      </div>             
    )
  }
}

export { UniTree }
export default withTranslation(['common'], { wait: true })(withMainContainer(UniTree))