import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Selection
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode } from '@fluentui/react'
import withMainContainer from '../withMainContainer'
import { withTranslation } from 'react-i18next'

const delIcon = { iconName: 'ErrorBadge' }

@inject('store')
@observer
class GenartCupis extends Component {
  constructor (props) {
    super(props)
    this.genartsStore = this.props.store.genartsStore
    this.genartCupisStore = this.props.store.genartCupisStore
    this.esObjectStore = this.props.store.esObjectStore
    this.selection = new Selection();
  }

  setCupisHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.genartCupisStore.cupisColumns.forEach((col, index) => {
      col.name = t(`common:${this.genartCupisStore.constCupisHeader[index].text}`)
      columnsName.push(col)
    })
    return columnsName
  }

  render () {
    const { t, store: { genartsStore: { cupisItems } }}  = this.props
    const stackTokens = {
      childrenGap: 5
    }
    return (
      <React.Fragment>
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>       
              <div style={{ position: "relative", height: 300 }} id='cupisListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='cpisDetailsList'
                    items={cupisItems} 
                    setKey='set'
                    columns={this.setCupisHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    selection={this.selection}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
      </React.Fragment> 
    )
  }
}

export { GenartCupis }
export default withTranslation(['common'], { wait: true })(withMainContainer(GenartCupis))