import { action, observable } from 'mobx'
import config from '../../config/config'

class NodeStore {
  nodes = []
  @observable items = {}
  @observable loadingNodes = false
  @observable hideDialog = true
  @observable editNode = undefined
  @observable rootNode = undefined

  @observable expandedItems = []
  @observable selectedItems = []
  @observable focusedItem = undefined

  @observable loadingOnSave = false

  origEditNode = ''
  editIndex = undefined

  nodeDB = {}
  mapChildren = new Map()

  nodeExternalServiceTypes = [
    { key: '', text: ''},
    { key: 'info', text: 'info' },
    { key: 'link', text: 'link' },
    { key: 'partner', text: 'partner' },
    { key: 'service', text: 'service' },
    { key: 'shop', text: 'shop' }
  ]

  constructor (authStore, esObjectStore, unitreeStore) {
    this.esObjectStore = esObjectStore
    this.authStore = authStore 
    this.unitreeStore = unitreeStore
  }

  /**
   * Hide / Show dialog
   */
  handleHideDialog = (node) => {
    this.hideDialog = !this.hideDialog
    this.editNode = node
  }

  populateNodesTree = async (treeId, showSpinner = true) => {
    this.loadingNodes = showSpinner
    const result = await fetch(`${config.server_address}/fe/nodes/${treeId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success === true) {
      this.items = {}
      this.nodes = []
      this.nodeDB = data.message !== undefined ? data.message : {}

      if (this.nodeDB.nodes_source !== undefined) {
        this.nodes = JSON.parse(this.nodeDB.nodes_source)
      } else {
        this.nodeDB.tree_id = treeId
      }  
      this.nodes.forEach(node => {
        // set translations
        node.node_name_trans = this.unitreeStore.mapTextsTranslations.get(`${treeId}@node_name[${node.id}]`)
        node.node_external_service_attribute_trans = this.unitreeStore.mapTextsTranslations.get(`${treeId}@node_external_service_attribute[${node.id}]`)
        node.node_keywords_trans = this.unitreeStore.mapTextsTranslations.get(`${treeId}@node_keywords[${node.id}]`)
      })
      this.buildNodesTree()
    }
    this.loadingNodes = false
  }

  populateMapChildren = () => {
    this.mapChildren.clear()
    this.nodes.forEach(node => {
      if (!this.mapChildren.has(node.parent_id)) {
        this.mapChildren.set(node.parent_id, [])
      }
      this.mapChildren.get(node.parent_id).push(node.leaf_id)
    })
  }

  buildNodesTree = () => {
    this.selectedItems = []
    this.expandedItems = []
    this.focusedItem = undefined
    this.populateMapChildren()
    for (let index = 0; index < this.nodes.length; index++) {
        const node = this.nodes[index]
        const id = node.leaf_id
        const children = this.mapChildren.has(id) ? this.mapChildren.get(id) : []
        // root node
        if (index == 0) {
          this.items['root']= {
              index: 'root',
              isFolder: children.length > 0,
              children: children,
              data: node,
            }
        } else {
          this.items[id] = {
              index: id,
              isFolder: children.length > 0,
              children: children,
              data: node,
              canMove: true
            }
        }
    }
  }

  removeTranslationsFromNodes = () => {
    this.nodes.forEach(node => {
      delete node.node_name_trans
      delete node.node_external_service_attribute_trans
      delete node.node_keywords_trans
    })
  }

  removeChildren = async(parent_id) => {
    const children = this.mapChildren.get(parent_id)
    if (children === undefined) {
      return
    }
    for (const child_leaf_id of children) {
      const childIndex = this.nodes.findIndex(node => node.leaf_id === child_leaf_id)
      if (childIndex !== -1) {
        await this.deleteTexts(this.nodes[childIndex])
        this.nodes.splice(childIndex, 1)
      }
      if (this.mapChildren.has(child_leaf_id)) {
        await this.removeChildren(child_leaf_id)
      }
    }
  }

  saveNodes = async(op) => {
    this.loadingOnSave = true
    // sort nodes alphabetically
    const sortedNodes = []
    sortedNodes.push(this.nodes[0])
    sortedNodes.push(...this.nodes.slice(1).sort((node1, node2) => 
      (node1.node_name_trans !== undefined && node1.node_name_trans[this.esObjectStore.defaultLang] !== undefined ? node1.node_name_trans[this.esObjectStore.defaultLang] : '').localeCompare
      (node2.node_name_trans !== undefined && node2.node_name_trans[this.esObjectStore.defaultLang] !== undefined ? node2.node_name_trans[this.esObjectStore.defaultLang] : '')))
    this.nodes = sortedNodes
    
    this.removeTranslationsFromNodes()
    
    this.nodeDB.nodes_source =  JSON.parse(JSON.stringify(this.nodes))
    // save in DB 
    const result = await fetch(`${config.server_address}/fe/nodes/${this.nodeDB.id === undefined ? 'insert' : 'edit'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: this.nodeDB.id,
        tree_id: this.nodeDB.tree_id,
        nodes_source: this.nodeDB.nodes_source,
        op
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      // update date on tree
      const resultTreeUpdate = await fetch(`${config.server_address}/fe/tree/editLastUpdated`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`,
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          id: this.nodeDB.tree_id,
          last_updated_date: new Date(),
          updated_by: this.authStore.current_user.DisplayName
        })
      })
      // token no longer valid => forward to welcome page
      if (resultTreeUpdate.status === 401) {
        this.authStore.clearUserSession(true)
        return
      }
      // refresh data
      this.unitreeStore.selectedTreeLastUpdate = new Date()
      this.unitreeStore.selectedTreeLastUpdatedBy = this.authStore.current_user.DisplayName
      await this.unitreeStore.fetchUnitreeNodes(this.unitreeStore.selectedUnitree.id)
      await this.populateNodesTree(this.nodeDB.tree_id, false)
    }
    this.loadingOnSave = false
  }

  saveNode = async() => {
    this.loadingOnSave = true
    // on insert add also in the nodes json the parent node
    if (this.nodes.length === 0 && this.rootNode !== undefined) {
      // save texts for parent node in DB and in mapTranslations
      for (const lang of this.esObjectStore.langs) {
        const text = this.unitreeStore.mapTextsTranslations.get(`${this.nodeDB.tree_id}@tree_name`) !== undefined ?
          this.unitreeStore.mapTextsTranslations.get(`${this.nodeDB.tree_id}@tree_name`)[lang] : ''
        this.unitreeStore.addTranslationToMap(`${this.nodeDB.tree_id}@node_name[${this.rootNode.id}]`, lang, text)
        await this.unitreeStore.editText(this.nodeDB.tree_id, `node_name[${this.rootNode.id}]`, lang, text)
      }
      this.nodes.push(this.rootNode)
    }
    // save texts for node in DB and in mapTranslations
    for (const [lang, text] of Object.entries(this.editNode.node_name_trans)) {
      this.unitreeStore.addTranslationToMap(`${this.nodeDB.tree_id}@node_name[${this.editNode.id}]`, lang, text)
      await this.unitreeStore.editText(this.nodeDB.tree_id, `node_name[${this.editNode.id}]`, lang, text)
    }
    for (const [lang, text] of Object.entries(this.editNode.node_external_service_attribute_trans)) {
      this.unitreeStore.addTranslationToMap(`${this.nodeDB.tree_id}@node_external_service_attribute[${this.editNode.id}]`, lang, text) 
      await this.unitreeStore.editText(this.nodeDB.tree_id, `node_external_service_attribute[${this.editNode.id}]`, lang, text)
    }
    for (const [lang, text] of Object.entries(this.editNode.node_keywords_trans)) {
      this.unitreeStore.addTranslationToMap(`${this.nodeDB.tree_id}@node_keywords[${this.editNode.id}]`, lang, text) 
      await this.unitreeStore.editText(this.nodeDB.tree_id, `node_keywords[${this.editNode.id}]`, lang, text)
    }
    const isEdit = this.editNode.isEdit
    // delete temp properties from filters
    this.editNode.filters.forEach(gafilter => {
      delete gafilter.gatxt
      delete gafilter.isEdit
      gafilter.filter.forEach(critfilter => {
        delete critfilter.filter_catxt
        delete critfilter.isEdit
      })
    })
    this.editNode.include.criteria.forEach(criteria => delete criteria.isEdit)
    this.editNode.exclude.criteria.forEach(criteria => delete criteria.isEdit)
    // delete temp properties from the object
    delete this.editNode.isEdit
    // add node to nodes array
    if (!isEdit) {
      if (this.nodes.find(node => node.id === this.editNode.id) === undefined) {
        this.nodes.push(this.editNode)
      }
    } else {
      const editIndex = this.nodes.findIndex(node => node.leaf_id === this.editNode.leaf_id)
      this.nodes[editIndex] = this.editNode
    }
    await this.saveNodes((isEdit ? 'edit ' : 'insert ') + 'node with id ' + this.editNode.id)
    this.handleHideDialog()
  }

  deleteTexts = async(node) => {
    this.unitreeStore.mapTextsTranslations.delete(`${this.nodeDB.tree_id}@node_name[${node.id}]`)
    await this.unitreeStore.removeText(this.nodeDB.tree_id, `node_name[${node.id}]`)
    
    this.unitreeStore.mapTextsTranslations.delete(`${this.nodeDB.tree_id}@node_external_service_attribute[${node.id}]`) 
    await this.unitreeStore.removeText(this.nodeDB.tree_id, `node_external_service_attribute[${node.id}]`)
    
    this.unitreeStore.mapTextsTranslations.delete(`${this.nodeDB.tree_id}@node_keywords[${node.id}]`) 
    await this.unitreeStore.removeText(this.nodeDB.tree_id, `node_keywords[${node.id}]`)
  }

  removeNode = async(delNode) => {
    // delete children
    await this.removeChildren(delNode.leaf_id)
    // delete texts
    await this.deleteTexts(delNode)
    // delete node
    const delIndex = this.nodes.findIndex(node => node.leaf_id === delNode.leaf_id)
    if (delIndex !== -1) {
      this.nodes.splice(delIndex, 1)
    }
    await this.saveNodes('delete node with id: ' + delNode.id)
  }

  expandParents = (item) => {
    const parent = Object.values(this.items).find(it =>
      it.data.leaf_id === item.data.parent_id
    )
    if (parent !== undefined) {
      this.expandedItems = [...this.expandedItems, parent.index] // expand parent
      if(parent.index !== 'root') { // is not root expand upper parent
        this.expandParents(parent)
      }
    }
  }

  @action
  selectItem = (item) => {
    this.expandParents(item)
    this.selectedItems = []
    this.selectedItems.push(item.index)
    this.focusedItem = item.index
  }
} 

export default NodeStore
