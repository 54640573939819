/* eslint-disable camelcase */
if (process.env.NODE_ENV === 'development') {
  module.exports = {
    server_address: 'http://localhost:3003', // BACKEND CONFIG
    themeBgClass: 'ms-bgColor-neutralDark',
    INITIAL_URL: '/apps',
    clientId: '136713df-ce92-430a-9679-b945fec9e8f3',
    directoryId: '87ba7b57-cd64-42e8-a3c4-6300e4bdca25',
    scopeName: 'User.Access'
  }
} else if (process.env.NODE_ENV === 'dev') {
  module.exports = {
    server_address: 'https://contree.preprod.sag-ag.cloud',
    themeBgClass: 'bgColor-red-c82e29',
    INITIAL_URL: '/apps',
    clientId: 'c50a15f9-8276-4ab6-b0b0-1ed776fcbc91',
    directoryId: '87ba7b57-cd64-42e8-a3c4-6300e4bdca25',
    scopeName: 'User.Access'
  } 
} else if (process.env.NODE_ENV === 'test') {
  module.exports = {
    server_address: 'https://contree.preprod.sag-ag.cloud',
    themeBgClass: 'bgColor-orange-ffb900',
    INITIAL_URL: '/apps',
    clientId: 'c50a15f9-8276-4ab6-b0b0-1ed776fcbc91',
    directoryId: '87ba7b57-cd64-42e8-a3c4-6300e4bdca25',
    scopeName: 'User.Access'
  };
} else if (process.env.NODE_ENV === 'production') {
  module.exports = {
    server_address: 'https://contree.sag-ag.cloud',
    themeBgClass: 'bgColor-green-09922c',
    INITIAL_URL: '/apps',
    clientId: '297a7cab-2ff9-4777-a2a2-3646e28b83b8',
    directoryId: '87ba7b57-cd64-42e8-a3c4-6300e4bdca25',
    scopeName: 'User.Read'
  }
}
