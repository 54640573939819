// import libs
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

// import components
import config from '../../config/config'
import SidebarButton from './SidebarButton'

// import styles
import './LeftSidebar.scss'

@withTranslation(['common'])
@inject('store')
@observer
class LeftSidebar extends Component {
  render () {
    const { t } = this.props

    return (
      <div className={`${config.themeBgClass} left-sidebar`}>
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'unitree' &&
            <SidebarButton
              iconName='BacklogBoard'
              buttonTitle='Unitree'
              routePath='/unitree/unitrees'
              calloutSubtext='Configure unitree builder'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'unitree' &&
          this.props.store.authStore.hasUserRight('import') === true &&
            <SidebarButton
              iconName='CloudDownload'
              buttonTitle='Import'
              routePath='/unitree/import'
              calloutSubtext='Import data from elasticsearch'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === null &&
          this.props.store.authStore.hasUserRight('shops') === true &&
            <SidebarButton
              iconName='AddToShoppingList'
              buttonTitle='Shops'
              routePath='/shops'
              calloutSubtext='Configure shops'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'dashboard' &&
            <SidebarButton
              iconName='AnalyticsView'
              buttonTitle='Overview'
              routePath='/dashboard/overview'
              calloutSubtext='Dashboard (Overview)'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'dashboard' &&
            <SidebarButton
              iconName='DocumentSearch'
              buttonTitle='Articles'
              routePath='/dashboard/article'
              calloutSubtext='Detail View (by article)'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === null &&
          this.props.store.authStore.hasUserRight('users') === true &&
            <SidebarButton
              iconName='UserFollowed'
              buttonTitle='Users'
              routePath='/users'
              calloutSubtext='Configure users'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'categories' &&
            <SidebarButton
              iconName='BacklogBoard'
              buttonTitle='Category'
              routePath='/category/categories'
              calloutSubtext='Configure categories builder'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'categories' &&
          this.props.store.authStore.hasUserRight('import_categories') === true &&
            <SidebarButton
              iconName='CloudDownload'
              buttonTitle='Import'
              routePath='/category/import'
              calloutSubtext='Import data from elasticsearch'
            />
        }
         {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'categories' &&
          this.props.store.authStore.hasUserRight('config_am') === true &&
            <SidebarButton
              iconName='AddToShoppingList'
              buttonTitle='Config am genarts'
              routePath='/category/config_am'
              calloutSubtext='Select generic articles that have am'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'categories' &&
          this.props.store.authStore.hasUserRight('config_cupis') === true &&
            <SidebarButton
              iconName='AddToShoppingList'
              buttonTitle='Config cupis genart'
              routePath='/category/config_cupis'
              calloutSubtext='Configure cupis for generic articles'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'brand_priority' &&
            <SidebarButton
              iconName='BacklogBoard'
              buttonTitle='Brand Priority'
              routePath='/brand_priority'
              calloutSubtext='Configure brand priorities'
            />
        }
        {
          this.props.store.authStore.isUserAuth === true && this.props.store.authStore.selectedApp === 'brand_priority' &&
          this.props.store.authStore.hasUserRight('import_brand_priority') === true &&
            <SidebarButton
              iconName='CloudDownload'
              buttonTitle='Import'
              routePath='/brand_priority/import'
              calloutSubtext='Import data from elasticsearch'
            />
        }
      </div>
    )
  }
}

export default LeftSidebar
