import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb';
import { Label, TextField, Dropdown, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from '../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import BrandPriorityDocs from './BrandPriorityDocs'
import dateFormat from "dateformat";
import { TagPicker } from '@fluentui/react/lib/Pickers';

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const viewIcon = { iconName: 'LookupEntities' }
const exportIcon = { iconName: 'CloudUpload' }

@inject('store')
@observer
class BrandPriority extends Component {
  constructor (props) {
    super(props)
    this.brandPriorityStore = this.props.store.brandPriorityStore
    this.esObjectStore = this.props.store.esObjectStore
    this.selection = new Selection({
      onSelectionChanged: () => {
      }
    })
    this.state = {
      showEsObjects: true,
      showDocs: false,
      hideDelDialog: true,
      hideEditDialog: true,
      loadingDocs: false, 
      loadingExport: false,
      showConfirmExportDialog: false
    }
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.esObjectStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.esObjectStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  componentDidMount () {
    this.brandPriorityStore.populateESObjects(true)
  }

  populateDocs = (item) => {
    this.setState({loadingDocs: true})
    this.brandPriorityStore.selectedESObject = item
    const shop =  [...this.esObjectStore.mapShops.values()].find(el => el.id === item.shop_id)
    if (shop !== undefined) {
      this.esObjectStore.initialiseData(shop, this.props.store.appStateStore.get_gui_language())
      Promise.all([this.esObjectStore.fetchGenartsByLang(this.esObjectStore.defaultLang, this.brandPriorityStore.selectedESObject.env),
        this.esObjectStore.fetchManufacturers(),
      ]).then(() =>
          this.brandPriorityStore.populateDocs(item.id).then(() => {
            this.breadcrumbitems.push({ text: `${item.shop_name}_${item.index_type}_${item.version_name}`, key: 'tree' })
            this.setState({
              showEsObjects: false,
              showDocs: true,
              loadingDocs: false
            })
          })
      )
    }
  }

  runGitHubAction = async(item) => {
    await this.esObjectStore.runGitHubAction(item.shop_name, item.env)
  }

  exportEsObject = (item) => {
    this.esObjectStore.getIndexAliases(item).then((actions) =>{
      this.setState({ loadingExport: false})
      if (actions.length > 0) {
        this.setState({ showConfirmExportDialog: true, item, actions})
      } else {
        this.setState({ loadingExport: true})
        console.log(`Will export brand priority ${item.id} into ES`)    
        this.esObjectStore.exportEsObject(item).then(() => { 
          this.setState({ loadingExport: false})
          this.runGitHubAction(item)
          alert(`Brand priority ${item.id} exported!`)
        })
      }
    })
  }

  onBreadcrumbItemClicked = (ev, item) => {
    this.setState({
      showEsObjects: true,
      showDocs: false
    });
    this.brandPriorityStore.selectedESObject = null
    this.breadcrumbitems.length > 1 && this.breadcrumbitems.pop()
  }

  breadcrumbitems = [
    { text: 'Brand Priority', key: 'brand_priority', onClick: this.onBreadcrumbItemClicked }
  ]

  handleEdit = () => {
    // check if a unitree with same fields exists
    const found = this.brandPriorityStore.brandPriorities.filter(item => (item.shop_name === this.state.editItem.shop_name && 
      item.version_name === this.state.editItem.version_name && item.id !== this.state.editItem.id))
    if (found.length > 0) {
      alert(this.props.t('common:BRAND_PRIORITY_ALREADY_EXISTS_MSG'))
      this.brandPriorityStore.populateESObjects()
    }  else {
      this.esObjectStore.editESObject(this.state.editItem).then(() => 
      this.brandPriorityStore.populateESObjects().then(() => 
        this.setState({
          hideEditDialog: true,
          editItem: undefined
        })
       )
      )
    }
  }

  showEditDialog = item => {
    if (item === undefined) {
      item = {
        index_type: 'brand_priority',
        shop_name: '',
        version_name: dateFormat(new Date(), "yyyymmdd"), 
      }
    }
    this.setState({
      hideEditDialog: false,
      editItem: item
    })
  }

  handleCloseEditDialog = () => {
    this.setState({ hideEditDialog: true })
    this.brandPriorityStore.populateESObjects()
  }
  
  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.esObjectStore.removeESObject(this.brandPriorityStore.brandPriorities[this.state.delIndex]).then(() =>
      this.brandPriorityStore.populateESObjects().then(() =>
        this.setState({
          hideDelDialog: true,
          delIndex: -1
        })
      )
    )
  }

  handleItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={viewIcon}
            title={t('common:BRAND_PRIORITY_VIEW')}
            onClick={() => this.populateDocs(item)}
          />
          <IconButton
            iconProps={editIcon}
            title={t('common:BRAND_PRIORITY_EDIT')}
            onClick={() => this.showEditDialog(item)}
          />
          <IconButton
            iconProps={exportIcon}
            title={t('common:BRAND_PRIORITY_EXPORT')}
            onClick={() => this.exportEsObject(item)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:BRAND_PRIORITY_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }


  handleCloseConfirmExportDialog = () => {
    this.setState({ showConfirmExportDialog: false })
  }

  handleExport = () => {
    this.setState({
      showConfirmExportDialog: false, 
      loadingExport: true
    })
    console.log(`Will export brand priority ${this.state.item.id} into ES`)    
    this.esObjectStore.exportEsObject(this.state.item).then(() => { 
      this.esObjectStore.setAliases(this.state.item.env, this.state.actions).then(() => {
        this.setState({ loadingExport: false })
        this.runGitHubAction(this.state.item)
        alert(`Brand priority ${this.state.item.id} exported!`)
      })
    })
  }

  getTextFromItem = (item) => {
    return item.name;
  }
  listContainsDocument = (tag, tagList) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.filter(compareTag => compareTag.key === tag.key).length > 0;
  };
  onFilterChanged = (filterText, tagList) => {
    if (this.brandPriorityStore.searchTexts === undefined || !filterText) {
      return []
    }
    // search by node name
    const filteredTags = this.brandPriorityStore.searchTexts.filter(doc => this.brandPriorityStore.selectedTabKey === 'brands' ? JSON.parse(doc.node.brands).length > 0 : true).filter(tag => tag.name.substring(tag.name.lastIndexOf('>') + 1).toLowerCase().indexOf(filterText.toLowerCase()) >= 0)
          .filter(tag => !this.listContainsDocument(tag, tagList))
    return filteredTags;
  }

  onFilterChange = (text) => {
    this.brandPriorityStore.filterText = text
    this.brandPriorityStore.filteredSorts = text ? this.brandPriorityStore.brandPriorityDocs.filter(i => i.gatxt.toLowerCase().indexOf(text.toLowerCase()) > -1) : this.brandPriorityStore.brandPriorityDocs
  }

  render () {
    const { t, store: { brandPriorityStore: { brandPriorities, filterText } ,
      esObjectStore : {shops, envs } } } = this.props
    const { hideEditDialog, hideDelDialog, editItem, loadingDocs, loadingExport, showConfirmExportDialog } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const containerStyle = {
      root: {
        width: '100%',
        height: '100%'
      }
    }
    const growingStyles = {
      root: {
        display: 'flex',
        height: '50%',
        width: 400,
      }
    }
    const textFieldsStyles = {
      fieldGroup: { width: 200, height: 20, float: 'right' }
    }
    const pickerSuggestionsProps = {
      noResultsFoundText: 'No result found',
    };
    return (
      <div className='page-container'>
        <Stack verticall tokens={stackTokens} styles={containerStyle}>
        <Stack horizontal tokens={stackTokens}>
        <Stack.Item styles={growingStyles}>   
          <Breadcrumb style={{width: '400px'}}
            items={this.breadcrumbitems} 
          />
        </Stack.Item>
        <Stack.Item style={{ padding: 20 }}> 
          { this.state.showDocs && this.brandPriorityStore.selectedTabKey === 'brands'&& <Label style={{margin: '10px', fontSize: '12px'}}>{t('common:SEARCH')}</Label> } 
          { this.state.showDocs && this.brandPriorityStore.selectedTabKey === 'brands'&& <TagPicker style={{width: '400px'}}
            resolveDelay='1000'//ms
            onResolveSuggestions={this.onFilterChanged}
            getTextFromItem={this.getTextFromItem}
            pickerSuggestionsProps={pickerSuggestionsProps}
            onItemSelected={(item) => {
              const nodeTree = this.props.store.brandPriorityStore.items[item.node.gaid]
              if (nodeTree !== undefined) {
                this.props.store.brandPriorityStore.selectItem(nodeTree)
              }
              return null;
            }}
          />}
          { this.state.showDocs && this.brandPriorityStore.selectedTabKey !== 'brands'&& <TextField label={t('common:SEARCH')} value={filterText || ''} onChange={(event, newValue) => this.onFilterChange(newValue)} styles={{fieldGroup: { width: 350, height: 30, float: 'right' }}} />}
          </Stack.Item>
          </Stack>
          {loadingDocs && <Spinner size={SpinnerSize.large} /> }
          {loadingExport && <Spinner size={SpinnerSize.large} /> }
          {this.state.showEsObjects && <div id='brandPriorityDetailsList'>
              <DetailsList ariaLabelForGrid='BrandPriority'
              items={brandPriorities} 
              setKey='set'
              columns={this.setHeaderColumns()}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.single}
              selection={this.selection}
              enterModalSelectionOnTouch={true}
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={this.populateDocs}
              constrainMode={ConstrainMode.unconstrained}
              onRenderItemColumn={this.handleItemColumn}
            />
            <IconButton
              iconProps={addIcon}
              title={t('common:BRAND_PRIORITY_ADD')}
              onClick={() => this.showEditDialog()}
          />
            </div>
          }
          {this.state.showDocs && <BrandPriorityDocs/> }
        </Stack>
        <Dialog
          minWidth={400}
          maxWidth={800}
          hidden={hideEditDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: editItem !== undefined && editItem.id !== undefined ? t('common:BRAND_PRIORITY_EDIT') : t('common:BRAND_PRIORITY_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
          <Dropdown id='env'
                placeholder={t('common:UNITREE_DROPDOWN_ENV_PLACEHOLDER')}
                label={t('common:UNITREE_ENV')}
                defaultSelectedKey={editItem !== undefined ? editItem.env : ''}
                options={envs}
                onChange={(event, { key }) => {
                  if (editItem !== undefined) {
                    editItem.env = key
                    this.setState({
                      editItem
                    })
                  }}
                }
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
            <Dropdown id='shop_name'
                placeholder={t('common:UNITREE_DROPDOWN_SHOP_PLACEHOLDER')}
                label={t('common:UNITREE_SHOP_NAME')}
                defaultSelectedKey={editItem !== undefined ? editItem.shop_id : ''}
                options={shops}
                onChange={(event, { key, text }) => {
                  if (editItem !== undefined) {
                    editItem.shop_name = text
                    editItem.shop_id = key
                    this.setState({
                      editItem
                    })
                  }}
                }
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
            <TextField label={t('common:UNITREE_VERSION_NUMBER')} id='version_name' defaultValue={editItem !== undefined ? editItem.version_name : ''} 
              onChange={(event, newValue) => {
                if (editItem !== undefined) {
                  editItem.version_name = newValue
                  this.setState({
                    editItem
                  })
                }
              }}
              styles={textFieldsStyles}/>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:BRAND_PRIORITY_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:BRAND_PRIORITY_DELETE') + " ? "
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        { showConfirmExportDialog && <Dialog
          hidden={!showConfirmExportDialog}
          onDismiss={this.handleCloseConfirmExportDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:BRAND_PRIORITY_EXPORT'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:BRAND_PRIORITY_EXPORT_QESTION')
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 500 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleExport} text={t('common:BUTTON_YES')} />
            <DefaultButton onClick={this.handleCloseConfirmExportDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
      </div>             
    )
  }
}

export { BrandPriority }
export default withTranslation(['common'], { wait: true })(withMainContainer(BrandPriority))