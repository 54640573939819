import { observable } from 'mobx'

class GenartCupisStore {
  @observable cupisColumns = []  

  constCupisHeader = [
    { key: 'cupi', text : 'CUPI' },
    { key: 'loc', text : 'LOC' },
    { key: 'zone', text : 'ZONE' }
  ]

  constructor (authStore, genartsStore) {
    this.authStore = authStore 
    this.genartsStore = genartsStore

    this.constCupisHeader.forEach(({key}) => this.cupisColumns.push({
        key,
        fieldName: key,
        isResizable: true,
        width: key === 'loc' ? '50%' : '25%',
        minWidth: key === 'loc' ? 150 : 50,
        maxWidth: key === 'loc' ? 250 : 100,
    }))
  }
}

export default GenartCupisStore
