import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Selection
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton,
  Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from '../withMainContainer'
import { withTranslation } from 'react-i18next'
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling'
import DialogGenartFilter from './DialogGenartFilter';

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }

const theme = getTheme()
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})

@inject('store')
@observer
class GenartFilters extends Component {
  constructor (props) {
    super(props)
    this.genartsStore = this.props.store.genartsStore
    this.genartFiltersStore = this.props.store.genartFiltersStore
    this.esObjectStore = this.props.store.esObjectStore
    this.selection = new Selection();
    this.dragDropEvents = this.getDragDropEvents()
    this.draggedItem = undefined
    this.draggedIndex = -1
  }

  setFilterHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.genartFiltersStore.filterColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.genartFiltersStore.constFilterHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  handleFilterItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:FILTER_EDIT')}
            onClick={() => this.showEditFilterDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showDelFilterDialog(index)}
            iconProps={delIcon}
            title={t('common:FILTER_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  showEditFilterDialog = (item, index) => {
    if (item === undefined) {
      const sortFilters = this.genartsStore.genartItem.filters.map(filter => Number(filter.filter_sort))
      item = {
       filter_default: null,
       filter_bar: null,
       filter_uom: '',
       filter_sort: (sortFilters.length > 0 ? Math.max(...sortFilters) + 1 : 1).toString(),
       isEdit: false
      }
    } else {
      item.isEdit = true
      // save values before editing
      this.genartFiltersStore.origFilter = JSON.stringify(item)
      this.genartFiltersStore.editFilterIndex = index
    }
    this.genartFiltersStore.handleEditFilterDialog(item)
  }

  showDelFilterDialog = index => {
    this.genartFiltersStore.handleDelFilterDialog(index)
  }

  handleFilterDelete = () => {
    this.genartFiltersStore.removeFilter()
    this.genartFiltersStore.handleDelFilterDialog()
  }

  getDragDropEvents = () => {
    return {
      canDrop: (dropContext, dragContext) => {
        return true
      },
      canDrag: (item) => {
        return true
      },
      onDragEnter: (item, event) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item, event) => {
        return
      },
      onDrop: (item, event) => {
        if (this.draggedItem) {
          this.insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        this.draggedItem = item;
        this.draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        this.draggedItem = undefined;
        this.draggedIndex = -1;
      },
    };
  }

  insertBeforeItem = async(item) => {
    const draggedItems = this.selection.isIndexSelected(this.draggedIndex)
      ? (this.selection.getSelection()) : [this.draggedItem]

    const insertIndex = this.genartsStore.filtersItems.indexOf(item)
    const items = this.genartsStore.filtersItems.filter(itm => draggedItems.indexOf(itm) === -1)

    items.splice(insertIndex, 0, ...draggedItems)
    this.genartsStore.filtersItems = items

    // update sort field
    const sort = this.genartsStore.filtersItems.map(item => Number(item.filter_sort))
    const minsort = sort.length > 0 ? Math.min(...sort) : 1
    for (let index = 0; index < this.genartsStore.filtersItems.length; index++) {
      this.genartsStore.filtersItems[index].filter_sort = (minsort+index).toString()
    }
    this.genartsStore.genartItem.filters = this.genartsStore.filtersItems
  }

  render () {
    const { t, store: { genartsStore: { filtersItems },
        genartFiltersStore: { hideEditFilterDialog, hideDelFilterDialog, delFilterIndex } }}  = this.props
    const stackTokens = {
      childrenGap: 5
    }
    const stackStyles = {
      root: {
        width: '280px'
      }
    }
    return (
      <React.Fragment>
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
              <IconButton
                iconProps={addIcon}
                title={t('common:FILTER_ADD')}
                onClick={() => this.showEditFilterDialog()}
              />         
              <div style={{ position: "relative", height: 270 }} id='filterListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='filterDetailsList'
                    items={filtersItems} 
                    setKey='set'
                    columns={this.setFilterHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    selection={this.selection}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderItemColumn={this.handleFilterItemColumn}
                    dragDropEvents={this.dragDropEvents}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
        <Dialog
          hidden={hideDelFilterDialog}
          onDismiss={this.showDelFilterDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:FILTER_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:FILTER_DELETE_QESTION') + (filtersItems[delFilterIndex] !== undefined ? filtersItems[delFilterIndex].filter_catxt : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleFilterDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.showDelFilterDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        {hideEditFilterDialog === false && <DialogGenartFilter />}
      </React.Fragment> 
    )
  }
}

export { GenartFilters }
export default withTranslation(['common'], { wait: true })(withMainContainer(GenartFilters))