import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, Stack, TextField, Pivot, PivotItem, Dropdown, ConstrainMode, Dialog, DialogType, DialogFooter, IconButton, PrimaryButton, DefaultButton } from '@fluentui/react';
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling'
import withMainContainer from '../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import Nodes from './Nodes'
import Tiles from './Tiles'
import dateFormat from "dateformat";

const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const theme = getTheme()
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})
@inject('store')
@observer
class Tree extends Component {
  constructor (props) {
    super(props)
    this.esObjectStore = this.props.store.esObjectStore
    this.unitreeStore = this.props.store.unitreeStore
    this.treeStore = this.props.store.treeStore
    this.nodeStore = this.props.store.nodeStore
    this.tileStore = this.props.store.tileStore
    this.selection = new Selection();
    this.dragDropEvents = this.getDragDropEvents()
    this.draggedItem = undefined
    this.draggedIndex = -1
    this.state = {
      showSortDialog: false,
      hideEditDialog: true
    }
  }

  setHeaderColumns () {
    const { t } = this.props
    return [({
        name: t(`common:TREE_NAME`),
        key : 'tree_name',
        fieldName: 'tree_name',
        isResizable: true,
        width: '100%',
        minWidth: 200,
        maxWidth: 200
    })]
  }

  handleLinkClick = (item) => {
    this.treeStore.selectedTreeKey = item.props.itemKey
    if (item.props.itemKey === '0') {//Add tree
      this.showEditDialog()
    } else if (item.props.itemKey === '00') {//Sort trees
      this.showSortDialog()
    } else {
      const selectedTree = this.treeStore.items.find(tree => tree.id === item.props.itemKey)
      if (selectedTree !== undefined) {
        this.unitreeStore.selectedTreeLastUpdate = selectedTree.last_updated_date
        this.unitreeStore.selectedTreeLastUpdatedBy = selectedTree.updated_by
      }
    }
    this.nodeStore.populateNodesTree(this.treeStore.selectedTreeKey)
  };

  handleTreeLinkClick = (item) => {
    if (this.treeStore.selectedTreeKey) { 
      if (item.props.itemKey === 'tiles') {
        this.tileStore.populateTiles(this.treeStore.selectedTreeKey)
      } else {
        this.nodeStore.populateNodesTree(this.treeStore.selectedTreeKey)
      }
    }
  };
  
  handleShopsLinkClick = (item) => {
    this.treeStore.selectedShop = item.props.itemKey
  }

  handleEdit = () => {
    let empty = false
    this.esObjectStore.langs.forEach(lang => {
      if (this.state.editItem.tree_names[lang] === '' || this.state.editItem.tree_names[lang] === undefined) {
        empty = true
      }
    })
    if (empty) {
      return
    }
    this.treeStore.editTree(this.state.editItem)
    this.setState({
      hideEditDialog: true,
      editItem: undefined
    })
  }

  showEditDialog = item => {
    if (item === undefined) {
      const treeIds = this.treeStore.items.map(item => Number(item.tree_id))
      let tree_sort = ''
      this.treeStore.mapSort.forEach((value, key) => {
        const sorts= value.map(tree => Number(tree.sort))
        const sort = sorts.length > 0 ? Math.max(...sorts) + 1 : 1010
        if (tree_sort.length > 0) {
          tree_sort += ','
        }
        tree_sort += key + ':' + sort
      })
      item = {
        unitree_id: this.unitreeStore.selectedUnitree.id,
        tree_id: treeIds.length > 0 ? Math.max(...treeIds) + 1 : 1,
        tree_names: {},
        tree_sort 
      }
    }
    if (item.tree_names === undefined) {
      item.tree_names = {}
    }
    this.setState({
      hideEditDialog: false,
      editItem: item
    })
  }

  handleCloseEditDialog = () => {
    this.setState({ hideEditDialog: true })
    this.treeStore.populateTrees(this.state.editItem.unitree_id, this.state.editItem.id)
  }

  showSortDialog = () => {
    this.setState({
      showSortDialog: true,
    })
  }

  handleSort = () => {
    this.treeStore.saveSort()
    this.setState({
      showSortDialog: false
    })
  }

  handleCloseSortDialog = () => {
    this.setState({ showSortDialog: false })
    this.treeStore.populateTrees(this.unitreeStore.selectedUnitree.id)
  }

  getDragDropEvents = () => {
    return {
      canDrop: (dropContext, dragContext) => {
        return true
      },
      canDrag: (item) => {
        return true
      },
      onDragEnter: (item, event) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item, event) => {
        return
      },
      onDrop: (item, event) => {
        if (this.draggedItem) {
          this.insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        this.draggedItem = item;
        this.draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        this.draggedItem = undefined;
        this.draggedIndex = -1;
      },
    };
  }

  insertBeforeItem = async(item) => {
    const draggedItems = this.selection.isIndexSelected(this.draggedIndex)
      ? (this.selection.getSelection()) : [this.draggedItem]

    const insertIndex = this.treeStore.mapSort.get(this.treeStore.selectedShop).indexOf(item)
    const items = this.treeStore.mapSort.get(this.treeStore.selectedShop).filter(itm => draggedItems.indexOf(itm) === -1)
    items.splice(insertIndex, 0, ...draggedItems)

    // update sort field
    const sorts = items.map(tree => Number(tree.sort))
    const minsort = sorts.length > 0 ? Math.min(...sorts) : 1010
    for (let index = 0; index < items.length; index++) {
      items[index].sort = minsort+index
    }
    this.treeStore.mapSort.set(this.treeStore.selectedShop, items)
  }

  handleChange = (key, value) => {
    const editItem = this.state.editItem
    if (editItem !== undefined) {
      editItem[key] = value
      this.setState({
        editItem
      })
    }
  }

  showDelDialog = item => {
    this.setState({
      hideDelDialog: false,
      delItem: item
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.treeStore.removeTree(this.state.delItem)
    this.setState({
      hideDelDialog: true,
      delItem: undefined
    })
  }

  render () {
    const { t, store: { treeStore: { selectedShop, mapSort, items, selectedTreeKey, treeExternalServiceTypes }, 
      esObjectStore : { shopShops, langs, defaultLang },
      unitreeStore : { selectedTreeLastUpdate, selectedTreeLastUpdatedBy } } } = this.props
    const { hideEditDialog, hideDelDialog, editItem, showSortDialog } = this.state
    const stackTokens = {
      childrenGap: 5,
      padding: 15
    }
    const textFieldsStyles = {
      fieldGroup: { width: 150, height: 20, float: 'right' }
    }
    const dialogTextFieldsStyles = {
      fieldGroup: { width: 280, height: 20, float: 'right' }
    }
    return (
      <div className='page-container'>
        <Pivot aria-label="Tree" linkFormat="tabs" onLinkClick={this.handleLinkClick} selectedKey={selectedTreeKey}>
          {items.map((item) => {
            return (
              <PivotItem headerText={item.tree_names !== undefined ? (item.tree_names[defaultLang]) : ''} itemKey={item.id} key={item.id}>
                <Stack horizontal tokens={stackTokens}>
                  {item.tree_image && <Label>{t('common:TREE_IMAGE')}</Label>}
                  {item.tree_image && <img src={item.tree_image} alt={item.tree_image} width="30" height="30"/>}
                  <TextField label={t('common:TREE_EXTERNAL_SERVICE_TYPE')} id='tree_external_service_type' readOnly value={item.tree_external_service_type || ''} styles={textFieldsStyles} />
                  <TextField label={t('common:TREE_EXTERNAL_SERVICE_ATTRIBUTE')} id='tree_external_service_attribute' readOnly value={item.tree_external_service_attribute !== null ? item.tree_external_service_attribute : ''} styles={textFieldsStyles} />
                  <TextField label={t('common:TREE_LAST_UPDATED_DATE')} id='last_updated_date' readOnly value={selectedTreeLastUpdate !== null ? dateFormat(selectedTreeLastUpdate, "yyyy-mm-dd HH:MM") : '' || ''} styles={textFieldsStyles}/>
                  <TextField label={t('common:TREE_UPDATED_BY')} id='updated_by' readOnly value={selectedTreeLastUpdatedBy || ''} styles={textFieldsStyles}/>
                  <IconButton
                    iconProps={editIcon}
                    title={t('common:TREE_EDIT')}
                    onClick={() => this.showEditDialog(item)}
                  />
                  <IconButton
                    onClick={() => this.showDelDialog(item)}
                    iconProps={delIcon}
                    title={t('common:TREE_DELETE')}
                  />
                </Stack>
                <Pivot linkFormat="tabs" onLinkClick={this.handleTreeLinkClick}>
                  <PivotItem headerText='Nodes' itemKey='nodes' key='nodes'>
                    <Nodes/>
                  </PivotItem>
                  <PivotItem headerText='Tiles' itemKey='tiles' key='tiles'>
                    <Tiles/>
                  </PivotItem>
                </Pivot>
              </PivotItem>
            );
          })}
          <PivotItem headerText={t('common:TREE_ADD')} itemKey={'0'} itemIcon="Add" />
          <PivotItem headerText={t('common:TREE_SORT')} itemKey={'00'} itemIcon="Down" />
        </Pivot>
        <Dialog
          minWidth={500}
          maxWidth={800}
          hidden={hideEditDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: editItem !== undefined && editItem.id !== undefined ? t('common:TREE_EDIT') : t('common:TREE_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            {langs.map(lang => {
              return (
              <TextField label={t('common:TREE_NAME')+ ' ' + lang} id={'tree_name_'+ lang} key={'tree_name_'+ lang} defaultValue={editItem !== undefined ? editItem.tree_names[lang] : ''} 
                required type='text' onGetErrorMessage={ value => { if (value === '') return lang + t('common:TREE_NAME_DIALOG_INPUT_ERR_MSG') }} 
                onChange={(event, newValue) => {
                  if (editItem !== undefined) {
                    editItem.tree_names[lang] = newValue
                    this.setState({
                      editItem
                    })
                  }
                }
                } styles={dialogTextFieldsStyles}/>
              )})}
            <TextField label={t('common:TREE_IMAGE')} id='tree_image' defaultValue={editItem !== undefined ? editItem.tree_image : ''} 
              onChange={(event, newValue) => this.handleChange('tree_image', newValue)} styles={dialogTextFieldsStyles}/>
            <Dropdown id='tree_external_service_type'
                label={t('common:TREE_EXTERNAL_SERVICE_TYPE')}
                defaultSelectedKey={editItem !== undefined ? editItem.tree_external_service_type : ''}
                options={treeExternalServiceTypes}
                onChange={(event, { key }) => this.handleChange('tree_external_service_type', key !== '' ? key : null)}
                styles={{dropdown: { width: 280, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
            <TextField label={t('common:TREE_EXTERNAL_SERVICE_ATTRIBUTE')} id='tree_external_service_attribute' defaultValue={editItem !== undefined ? editItem.tree_external_service_attribute : ''} 
              onChange={(event, newValue) => this.handleChange('tree_external_service_attribute', newValue)} styles={dialogTextFieldsStyles}/>   
            <TextField label={t('common:TREE_SORT')} id='tree_sort' defaultValue={editItem !== undefined ? editItem.tree_sort : ''} 
              onChange={(event, newValue) => this.handleChange('tree_sort', newValue)} styles={dialogTextFieldsStyles}/>     
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        { showSortDialog && <Dialog
          minWidth={500}
          maxWidth={800}
          hidden={!showSortDialog}
          onDismiss={this.handleCloseSortDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:TREE_SORT'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <Pivot aria-label="Shops" linkFormat="tabs" onLinkClick={this.handleShopsLinkClick}>
              {shopShops.map(shop => {
                return (
                  <PivotItem headerText={shop} itemKey={shop} key={shop}>
                    <DetailsList id={`${shop}DetailList`}
                      items={mapSort.get(selectedShop)} 
                      setKey='set'
                      columns={this.setHeaderColumns()}
                      layoutMode={DetailsListLayoutMode.justified}
                      selectionMode={SelectionMode.none}
                      selection={this.selection}
                      enterModalSelectionOnTouch
                      constrainMode={ConstrainMode.unconstrained}
                      dragDropEvents={this.dragDropEvents}
                    />
                  </PivotItem>
                )})}
            </Pivot>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSort} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseSortDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:TREE_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:TREE_DELETE_QESTION')
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
      </div>             
    )
  }
}

export { Tree }
export default withTranslation(['common'], { wait: true })(withMainContainer(Tree))