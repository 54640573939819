
import { observable, action } from 'mobx'
import config from '../../config/config'

class UnitreeStore {
  @observable unitrees = []
    
  @observable editing = false

  @observable selectedUnitree = null
  mapTextsTranslations = new Map()

  nodesOptions = []

  leafIds = []

  @observable searchTexts = []
  @observable searchNode = undefined

  nodeParents=[]

  @observable selectedTreeLastUpdate = null
  @observable selectedTreeLastUpdatedBy = null

  constructor (authStore, esObjectStore) {
    this.authStore = authStore 
    this.esObjectStore = esObjectStore
  }

  fetchUnitreeNodes = async (unitreeId) => {
    const result = await fetch(`${config.server_address}/fe/nodes/all/${unitreeId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success === true) {
      this.nodesOptions = []
      this.leafIds = []
      this.searchTexts = []
      data.message.forEach(({nodes_source, tree_id}) => {
        const nodes = JSON.parse(nodes_source)
        for (let index = 0; index < nodes.length; index++) {
          const node = nodes[index]
          const treeName = this.mapTextsTranslations.get(`${tree_id}@tree_name`) !== undefined ? 
            this.mapTextsTranslations.get(`${tree_id}@tree_name`)[this.esObjectStore.defaultLang] : ''
          const nodeName = this.mapTextsTranslations.get(`${tree_id}@node_name[${node.id}]`) !== undefined ? 
            this.mapTextsTranslations.get(`${tree_id}@node_name[${node.id}]`)[this.esObjectStore.defaultLang] : 
            (index === 0 ? treeName : '')
          // set optionsForDopdrown
          this.nodesOptions.push({
            key: node.id,
            value: node.leaf_id,
            text: `${treeName}>${nodeName}`
          })
          this.leafIds.push(Number(node.leaf_id))
          // set serchTextArray
          if (index !== 0) { // ignore root nodes
            this.nodeParents = []
            this.getNodeParents(tree_id, nodes, node)
            this.nodeParents.reverse()
            let name = treeName
            this.nodeParents.forEach(parent => {
              name += ' > ' + parent
            })
            name += ' > ' + nodeName
            this.searchTexts.push({ key: node.id, name, node: node, tree_id})
          }
        }
      })
    }
  }

  getNodeParents = (tree_id, nodes, item) => {
    const parent = nodes.find(node =>
      node.leaf_id === item.parent_id
    )
    if (parent !== undefined && this.mapTextsTranslations.get(`${tree_id}@node_name[${parent.id}]`) !== undefined) {
      this.nodeParents.push(this.mapTextsTranslations.get(`${tree_id}@node_name[${parent.id}]`)[this.esObjectStore.defaultLang])
      if(parent.parent_id !== '10000') { // is not root get upper parent
        this.getNodeParents(tree_id, nodes, parent)
      }
    }
  }

  /**
   * Get the unitrees from DB
   */
   populateUniTrees = async (reloadShops = false) => {
    this.unitrees =(await this.esObjectStore.populateESObjects('unitree', reloadShops)).filter(item => this.authStore.hasUserRight(this.esObjectStore.mapShops.get(item.shop_id).shop_name) === true).map(item => ({...item, env_txt: this.esObjectStore.environments[item.env], shop_name: this.esObjectStore.mapShops.get(item.shop_id).shop_name }))
  }

  addTranslationToMap = (mapKey, lang, text) => {
    if (this.mapTextsTranslations.has(mapKey)) {
      this.mapTextsTranslations.get(mapKey)[lang] = text
    } else {
      this.mapTextsTranslations.set(mapKey, {[lang] : text})
    }
  }

  editText = async (treeId, key, lang, text) => {
    const result = await fetch(`${config.server_address}/fe/texts/save`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        es_object_id: this.selectedUnitree.id,
        tree_id: treeId,
        text_key: key,
        language_iso: lang,
        text: text
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
  }

  removeText = async (treeId, key) => {
    const result = await fetch(`${config.server_address}/fe/texts/delete`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        unitree_id: this.selectedUnitree.id,
        tree_id: treeId,
        text_key: key
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
  }
}

export default UnitreeStore
