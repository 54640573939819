import { observable, action } from 'mobx'
import config from '../../config/config'

class AppStore {
  @observable envs = []
  
  mapManufacturers = new Map()
  
  constructor (authStore) {
    this.authStore = authStore 
  }

  fetchEnvs = async () => {
    const result = await fetch(`${config.server_address}/fe/app/env`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    Object.keys(data.message).forEach(env => {
      let text = env
      switch (env) {
        case 'test':
          text = 'preProduction'
          break;
        case 'dev':
          text = 'preProduction'
          break; 
        default:
          break;
      }
      this.envs.push({
        key: env,
        text
      })
    })
  }

  fetchManufacturers = async (env) => {
    this.mapManufacturers.clear()
    const result = await fetch(`${config.server_address}/fe/servo/manufacturers/${env}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    data.message.forEach(man => {
      this.mapManufacturers.set(man.id_manufacturer, man)
    })
  }
}

export default AppStore