/* eslint-disable camelcase */
// import libs
import React, { Component, createRef } from 'react'
import { inject, observer } from 'mobx-react'

// import fabric-ui
import { IconButton } from '@fluentui/react/lib/Button'
import { Callout, DirectionalHint } from '@fluentui/react/lib/Callout'

// import components

// import styles
import './SidebarButton.scss'

@inject('store', 'history')
@observer
class SidebarButton extends Component {
  _sidebarButtonHTMLElement = createRef();

  constructor (props) {
    super(props)
    this.state = {
      button_hint: false
    }
  }

  handleOpenPath = () => {
    this.props.store.appStateStore.setPathActive(this.props.routePath)
    this.props.history.push(this.props.routePath)
  }

  handleShowHint = () => {
    this.setState({ button_hint: true })
  }

  handleHideHint = () => {
    this.setState({ button_hint: false })
  }

  render () {
    return (
      <div
        className={
          this.props.store.appStateStore.active_sidebar_item === this.props.routePath
            ? 'button-sidebar-wrapper menu-active'
            : 'button-sidebar-wrapper'
        }
        ref={menuButton => { this._sidebarButtonHTMLElement = menuButton }}
      >

        <IconButton
          iconProps={{ iconName: this.props.iconName }}
          className='icon-white'
          title={this.props.buttonTitle}
          ariaLabel={this.props.buttonTitle}
          onClick={this.handleOpenPath}
          onMouseEnter={this.handleShowHint}
          onMouseMove={this.handleShowHint}
          onMouseLeave={this.handleHideHint}
        />

        {
          this.state.button_hint &&
            <Callout
              className='callout'
              gapSpace={10}
              target={this._sidebarButtonHTMLElement}
              isBeakVisible
              beakWidth={16}
              directionalHint={DirectionalHint.rightCenter}
            >
              <div style={{ padding: '5px 10px 5px 15px' }}>
                <h3>{this.props.buttonTitle}</h3>
                <p className='ms-CalloutExample-subText'>
                  {this.props.calloutSubtext}
                </p>
              </div>
            </Callout>
        }
      </div>
    )
  }
}

export default SidebarButton
