import { observable, action } from 'mobx'
const data = require('../../config/data.json')
class SortsStore {
  @observable columns = [] 
  @observable showDialog = false
  @observable item = undefined
  @observable allPrioritiesItemsByMandant = new Map()
  @observable checked = []

  oriBrandIds = []

  constHeader = [
    { key: 'brandName', text : 'BRAND' },
    { key: 'prio', text : 'PRIO' },
    { key: 'buttons', text: '' }
  ]

  @observable mandant_type = []
  @observable mandant = []

  constructor (authStore, esObjectStore, brandPriorityStore) {
    this.authStore = authStore 
    this.esObjectStore = esObjectStore
    this.brandPriorityStore = brandPriorityStore 
    this.constHeader.forEach(({key}) => this.columns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: key === 'buttons' ? '20%' : '40%',
      minWidth: key === 'buttons' ? 50 : 100,
      maxWidth: key === 'buttons' ? 50 : 200,
    }))
    data.mandant_type.sort.forEach(type => this.mandant_type.push({key: type, text: type}))
  }

  populateMandants = (type) => {
    this.mandant = []
    data.mandant.sort[type][this.brandPriorityStore.selectedESObject.shop_name].forEach(type => this.mandant.push({key: type, text: type}))
  }
  /**
   * Hide / Show dialog
   */
  handleDialog = (sort) => {
    this.showDialog = !this.showDialog
    this.item = sort
  } 

  // Add/Remove checked item from list
  handleCheck = (event, newValue) => {
    var updatedList = [...this.checked];
    if (newValue) {
      updatedList = [...this.checked, event.target.id]
    } else {
      updatedList.splice(this.checked.indexOf(event.target.id), 1)
    }
    this.checked  = updatedList;
    console.log(this.checked)
  }
}

export default SortsStore
