import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { Router, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { I18nextProvider } from 'react-i18next'

//import '@babel/polyfill'

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './assets/fabric.min.css'

import 'semantic-ui-css/semantic.min.css'

import i18n from './i18n'

// import Stores & Helpers
import Store from './stores/Store'

// import components
import App from './App'

const browserHistory = createBrowserHistory()

// create store
const store = new Store(browserHistory)

const stores = {
  store,
  history: browserHistory
}

// render App component and bind to div#root
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider {...stores}>
      <Router history={browserHistory}>
        <Route path='/' component={App} />
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
)
