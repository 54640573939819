/* eslint-disable camelcase */
import { observable } from 'mobx'

import i18n from '../i18n'

// ONLY TO DEBUG MOBX
// import remotedev from 'mobx-remotedev'
// @remotedev
class AppStateStore {
  @observable active_sidebar_item = ''
  @observable choosen_languages = []

  @observable show_global_msgBar = false;
  @observable global_msgBar_type = 'info';
  @observable global_msgBar_text = '';

  constructor () {
    this.active_sidebar_item = window.location.pathname

    this.set_gui_language(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en')
  }

  setPathActive = path => {
    this.active_sidebar_item = path
  }

  set_gui_language = lng => {
    this.choosen_languages[0] = lng
    localStorage.setItem('i18nextLng', this.choosen_languages[0])
    i18n.changeLanguage(this.choosen_languages[0])
  }

  get_gui_language = () => this.choosen_languages[0]

  setGlobalMsgBar = (bool, _type, text, time = 10000, close = true) => {
    const TYPE_ENUM = {
      error: 'error',
      info: 'info',
      warning: 'warning',
      success: 'success'
    }
    const TYPE = TYPE_ENUM[_type]
    if (!TYPE) throw new Error(`Unsupported MessageBar Type: ${_type}`)

    this.show_global_msgBar = bool
    this.global_msgBar_type = TYPE
    this.global_msgBar_text = text

    if (close === true) this.closeGlobalMessageBarTimeout(time)
  }

  closeGlobalMessageBarTimeout = time => setTimeout(
    () => this.setGlobalMsgBar(false, 'info', ''),
    time
  );
}

export default AppStateStore
