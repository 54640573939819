import React, { Component  } from 'react'
import { inject, observer } from 'mobx-react'
import { Dropdown, Pivot, PivotItem, Stack, Checkbox, DefaultButton, PrimaryButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import withMainContainer from './Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'

@inject('store')
@observer
class Importer extends Component {
  constructor (props) {
    super(props)
    this.importerStore = this.props.store.importerStore
    this.esObjectStore = this.props.store.esObjectStore
    this.state = {
      showIndices: false,
      selectedEnv: null,
      selectedShop: null,
      hideDelDialog: true,
    }
  }

  componentDidMount () {
    if (this.esObjectStore.envs.length === 0) {
      this.fetchEnvs()
    }
  }

  handleShopClick = (item) => {
    this.importerStore.checked = []
    this.setState({
      selectedShop: item.props.itemKey
    })
  }
  
  runGitHubAction = async(selectedShop, selectedEnv) => {
    if (this.props.store.authStore.selectedApp === 'unitree') {
      return
    }
    if (!selectedShop && !selectedEnv) {
      return
    }
    await this.esObjectStore.runGitHubAction(selectedShop, selectedEnv)
  }

  showDelDialog = index => {
    this.setState({
      hideDelDialog: false
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.setState({
      hideDelDialog: true
    })
    const shop_group = this.importerStore.items.find(sg => sg.shop_name === this.state.selectedShop)
    if (!shop_group) {
      return
    }
    if(this.importerStore.checked.filter(index => shop_group.info[index]['aliases'].length > 0).length > 0) {
      alert('The selected indexes has alias! The indexes cannot be deleted !')
      return
    }
    this.importerStore.deleteIndexes(this.state.selectedEnv, this.importerStore.checked).then((result) => { 
      if (result) {
        this.importerStore.checked = []
        this.importerStore.populateIndices(this.state.selectedEnv)
      }
    })
  }
    render () {
        const { t, store: { importerStore: { items, loading},
          esObjectStore: { envs} } } = this.props
        const { showIndices, selectedEnv, selectedShop, hideDelDialog } = this.state
        const stackTokens = {
          childrenGap: 5,
          padding: 5,
        }
        return (
      <div className='page-container'>
        <Stack vertical tokens={stackTokens}>
          <Stack horizontal tokens={stackTokens}>
            <Dropdown id='env'
              placeholder={t('common:UNITREE_DROPDOWN_ENV_PLACEHOLDER')}
              label={t('common:UNITREE_ENV')}
              options={envs}
              onChange={(event, { key }) => {
                this.importerStore.populateIndices(key).then(
                  () => this.setState({
                    showIndices: true,
                    selectedEnv: key,
                    selectedShop: selectedShop !== null && selectedShop !== undefined ? selectedShop : this.importerStore.items.length > 0 ? this.importerStore.items[0].shop_name: null
                  }))
                }
              }
              styles={{dropdown: { width: 200, height: 20, float: 'right' },
              caretDownWrapper: { height:20, lineHeight: 'normal' },
              dropdownItem: 'dropdownItem',
              dropdownItemSelected: 'dropdownItem' }}
            />  
            {loading && <Spinner size={SpinnerSize.large} /> }
          </Stack>
          {showIndices && <Pivot aria-label="Tree" linkFormat="tabs" onLinkClick={this.handleShopClick} >
          {items.map((shop_group) => {
            if (this.props.store.authStore.hasUserRight(shop_group['shop_name']) === true) {
              return (
                <PivotItem headerText={shop_group['shop_name']} itemKey={shop_group['shop_name']} key={shop_group['shop_name']}>
                  <div className="list-container env-indices">
                    {shop_group['indices'].map((item, index) => (
                      <div key={index} title={"Document count: " + shop_group['info'][item]['count'] + "\nAliases: " + shop_group['info'][item]['aliases'].join(', ')} 
                          className={(shop_group['info'][item]['aliases'].length > 0 ? 'has-aliases' : '') + ' ' + (shop_group['info'][item]['count'] == 0 ? 'no-docs' : '') + ' ' + 'ckboxes'}>
                        <Checkbox id={item} label={item} checked={this.importerStore.checked.includes(item)} onChange={this.importerStore.handleCheck} />
                      </div>
                    ))}
                  </div>
                </PivotItem>
              );
            }
          })}
 
          </Pivot>}
          <div className="button-bar">
            <PrimaryButton variant="contained"
                onClick={() => this.importerStore.doImport(selectedEnv, selectedShop, this.importerStore.checked)}>
                {t('common:IMPORTER_EXECUTE_IMPORT')}
            </PrimaryButton>
            <PrimaryButton variant="contained"
                onClick={() => this.importerStore.setAlias(selectedEnv, this.importerStore.checked, selectedShop).then((result) => { 
                  if (result) {
                    this.runGitHubAction(selectedShop, selectedEnv) 
                    this.importerStore.checked = []
                    this.importerStore.populateIndices(selectedEnv)
                  }
                })}>{t('common:IMPORTER_SET_ALIAS')}
            </PrimaryButton>
            <PrimaryButton onClick={() => this.showDelDialog()} text={t('common:IMPORTER_DELETE_INDEX')} />
          </div>
          </Stack>
          {!hideDelDialog && <Dialog
            hidden={hideDelDialog}
            onDismiss={this.handleCloseDelDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: t('common:INDEXES_DELETE'),
              closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
              subText: t('common:INDEXES_DELETE_QESTION')
            }}
            modalProps={{
              isBlocking: true, dragOptions: true,
              styles: { main: { maxWidth: 450 } }
            }}
          >
            <DialogFooter>
              <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
              <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
            </DialogFooter>
          </Dialog>}
        </div>
        )
      } 
}
export { Importer }
export default withTranslation(['common'], { wait: true })(withMainContainer(Importer)) 