import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { TextField, Dropdown, ScrollablePane, ScrollbarVisibility, Label, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from './../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown as LookupDropdown} from 'semantic-ui-react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }

@inject('store')
@observer
class ConfigCupis extends Component {
  constructor (props) {
    super(props)
    this.configCupisStore = this.props.store.configCupisStore
    this.esObjectStore = this.props.store.esObjectStore
    this.cupiRef = React.createRef()
    this.zoneRef = React.createRef()
    this.state = {
      hideDelDialog: true,
    }
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.configCupisStore.columns.forEach((col, index) => {
      switch (col.key) {
        case 'buttons':
          col.name = ''
          break;
        case 'vloc':
          col.name = t('common:FRONT')+'/'+ t('common:REAR') + ' '+ t('common:LOC')
          break;
        case 'hloc':
          col.name = t('common:LEFT')+'/'+ t('common:RIGHT') + ' '+ t('common:LOC') 
          break; 
        default:
          col.name = t(`common:${this.configCupisStore.constHeader[index].text}`)
          break;
      }
      columnsName.push(col)
    })
    return columnsName
  }

  componentDidMount() {
    this.configCupisStore.filterText = ''
    this.configCupisStore.items = []
    if (this.esObjectStore.mapShops.size === 0 && this.esObjectStore.shops.length === 0) {
      this.esObjectStore.fetchShops()
    }
  }

  loadData = () => {
    if (!this.state.env) {
      alert('Select an environment!')
      return
    }
    if (!this.state.shop_id) {
      alert('Select a shop!')
      return
    }
    this.setState({
        loadingData: true
    })
    this.configCupisStore.items = []
    const shop =  [...this.esObjectStore.mapShops.values()].find(el => el.id === this.state.shop_id)
    if (shop !== undefined) {
      this.esObjectStore.initialiseData(shop, this.props.store.appStateStore.get_gui_language())
      this.esObjectStore.fetchGenartsByLang(this.esObjectStore.defaultLang, this.state.env).
        then(() => this.configCupisStore.fetchGenartsCupis().then(() => {
            this.setState({
                loadingData: false
            })
            if (!this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang)) {
                alert('Select the right environment for the shop!')
            }
        }
      ))
    }
  }

  handleEdit = () => {
    if (!this.configCupisStore.editItem.id_genart) {
      alert(this.props.t('common:MSG_SELECT_FILTER_GENART'))
      return
    }
    if (!this.cupiRef.current.value) {
      alert(this.props.t('common:MSG_ADD_CUPI'))
      return
    }
    if (!this.zoneRef.current.value) {
      alert(this.props.t('common:MSG_ADD_ZONE'))
      return
    }
    this.configCupisStore.editItem.cupi = this.cupiRef.current.value
    this.configCupisStore.editItem.zone = this.zoneRef.current.value
    this.configCupisStore.saveCupi(this.configCupisStore.editItem)
  }

  showEditDialog = item => {
    if (item === undefined) {
      item = {
        vloc: '',
        hloc: ''
      }
    }
    this.configCupisStore.handleShowDialog(item)
  }

  handleCloseInsertDialog = () => {
    this.configCupisStore.handleShowDialog()
    this.configCupisStore.fetchGenartsCupis()
  }
  
  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.configCupisStore.removeCupi(this.state.delIndex)
    this.setState({
      hideDelDialog: true,
      delIndex: -1
    })
  }

  handleItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:CUPI_EDIT')}
            onClick={() => this.showEditDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:CUPI_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  onFilterChange = (text) => {
    this.configCupisStore.filterText = text
    this.configCupisStore.items = text ? this.configCupisStore.allItems.filter(i => i.gatxt.toLowerCase().indexOf(text.toLowerCase()) > -1 || i.cupi.toLowerCase().indexOf(text.toLowerCase()) > -1) : this.configCupisStore.allItems
  }

  handleChange = (key, value) => {
    const editItem = this.configCupisStore.editItem
    if (editItem !== undefined) {
      editItem[key] = value
    }
  }
    render () {
    const { t, store: { configCupisStore: { items, editItem, showDialog, vloc, hloc, filterText }, esObjectStore : {envs, shops, genarts, mapGenarts } }} = this.props
    const { hideDelDialog, delIndex, loadingData } = this.state
    const stackTokens = {
      childrenGap: 5,
      padding: 5
    }
    const containerStyle = {
      root: {
        width: '100%',
        height: '100%'
      }
    }
    const growingStyles = {
        root: {
            display: 'flex',
            height: '50%'
        }
    }
    const textFieldsStyles = {
      fieldGroup: { width: 250, height: 20, float: 'right' }
    }
    return (
      <div className='page-container'>
        <Stack verticall tokens={stackTokens} styles={containerStyle}>
        <h1>{t('common:ARTICLES_GENARTS') + ' ' + t('common:CUPIS')}</h1>
        <Stack horizontal tokens={stackTokens} styles={{ root: { width: '70vw'} }}>
            <Dropdown id='env'
                placeholder={t('common:UNITREE_DROPDOWN_ENV_PLACEHOLDER')}
                label={t('common:UNITREE_ENV')}
                defaultSelectedKey={''}
                options={envs}
                onChange={(event, { key }) => {
                    this.configCupisStore.items = []
                    this.setState({
                        env: key
                    })
                }}
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
             <Dropdown id='shop_name'
                placeholder={t('common:UNITREE_DROPDOWN_SHOP_PLACEHOLDER')}
                label={t('common:UNITREE_SHOP_NAME')}
                defaultSelectedKey={''}
                options={shops}
                onChange={(event, { key, text }) => {
                    this.configCupisStore.items = []
                    this.setState({
                        shop_id: key
                    })
                }}
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
             <PrimaryButton onClick={() => this.loadData()} text={t('common:LOAD_DATA')} />
             {loadingData && <Spinner size={SpinnerSize.large} /> } 
          </Stack>
            <div id='shopsDetailsList'>
              <IconButton
                iconProps={addIcon}
                title={t('common:CUPI_ADD')} disabled={!mapGenarts.get(this.esObjectStore.defaultLang)}
                onClick={() => this.showEditDialog()}
              />
              <TextField label={t('common:CUPI_FILTER')} value={filterText || ''} onChange={(event, newValue) => this.onFilterChange(newValue)} styles={{fieldGroup: { width: 250, height: 20 }}} />
              <DetailsList ariaLabelForGrid='Shops'
                items={items} 
                setKey='set'
                columns={this.setHeaderColumns()}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.single}
                selection={this.selection}
                enterModalSelectionOnTouch={true}
                selectionPreservedOnEmptyClick={true}
                constrainMode={ConstrainMode.unconstrained}
                onRenderItemColumn={this.handleItemColumn}
            />
        </div>
        </Stack>
        {showDialog && <Dialog
          minWidth={400}
          maxWidth={800}
          hidden={!showDialog}
          onDismiss={this.handleCloseInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: editItem.id === undefined ? t('common:CUPI_ADD') : t('common:CUPI_EDIT'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
          <Stack horizontal>
              <Label>{t('common:NODE_FILTER_GAID')}</Label>
              <LookupDropdown
                placeholder={t('common:NODE_FILTER_GAID')}
                defaultValue={editItem !== undefined ? editItem.id_genart : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={genarts}
                onChange={(e, { value }) => {
                    editItem.id_genart = value
                    editItem.gatxt = value + '-' + mapGenarts.get(this.esObjectStore.defaultLang).get(value)
                }}
              />
            </Stack>
            <TextField label={t('common:CUPI')} ref={this.cupiRef} id='cupi' defaultValue={editItem !== undefined ? editItem.cupi || '' : ''} styles={textFieldsStyles}/>
            <TextField label={t('common:ZONE')} ref={this.zoneRef} id='zone' defaultValue={editItem !== undefined ? editItem.zone || '' : ''} styles={textFieldsStyles}/>    
            <Dropdown id='vloc'
              label={t('common:FRONT')+'/'+ t('common:REAR') + ' '+ t('common:LOC')}
              defaultSelectedKey={editItem !== undefined ? editItem.vloc : ''}
              options={vloc}
              onChange={(event, { key }) => this.handleChange('vloc', key)}
              styles={{dropdown: { width: 250, height: 20, float: 'right' },
              caretDownWrapper: { height:20, lineHeight: 'normal' },
              dropdownItem: 'dropdownItem',
              dropdownItemSelected: 'dropdownItem' }}
            />    
            <Dropdown id='hloc'
              label={t('common:LEFT')+'/'+ t('common:RIGHT') + ' '+ t('common:LOC')}
              defaultSelectedKey={editItem !== undefined ? editItem.hloc : ''}
              options={hloc}
              onChange={(event, { key }) => this.handleChange('hloc', key)}
              styles={{dropdown: { width: 250, height: 20, float: 'right' },
              caretDownWrapper: { height:20, lineHeight: 'normal' },
              dropdownItem: 'dropdownItem',
              dropdownItemSelected: 'dropdownItem' }}
            />  
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {hideDelDialog === false && <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:CUPI_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:CUPI_DELETE_QESTION') + (delIndex !== undefined && items[delIndex] !== undefined ? items[delIndex].cupi : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}       
      </div>   
    )
  }
}

export { ConfigCupis }
export default withTranslation(['common'], { wait: true })(withMainContainer(ConfigCupis))