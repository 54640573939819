import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { TextField, Dropdown, ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from './Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import Tree from './unitree/Tree'

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const viewIcon = { iconName: 'LookupEntities' }

@inject('store')
@observer
class Shops extends Component {
  constructor (props) {
    super(props)
    this.shopsStore = this.props.store.shopsStore
    this.state = {
      hideDelDialog: true,
      hideEditDialog: true,
      showLanguagesDialog: false,
      showLanguageInsertDialog: false,
      hideLanguageDelDialog: true,
      showShopsDialog: false,
      showShopsInsertDialog: false,
      hideShopDelDialog: true
    }
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.shopsStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.shopsStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  componentDidMount () {
    this.shopsStore.fetchShops()
  }

  handleEdit = () => {
    this.shopsStore.editShop(this.state.editItem)
    this.setState({
        hideEditDialog: true,
        editItem: undefined
    })
  }

  showEditDialog = item => {
    if (item === undefined) {
      item = {
        shop_name: ''
      }
    }
    this.setState({
      hideEditDialog: false,
      editItem: item
    })
  }

  handleCloseEditDialog = () => {
    this.setState({ hideEditDialog: true })
    this.shopsStore.fetchShops()
  }
  
  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.shopsStore.removeShop(this.state.delIndex)
    this.setState({
      hideDelDialog: true,
      delIndex: -1
    })
  }

  handleItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:SHOP_EDIT')}
            onClick={() => this.showEditDialog(item)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:SHOP_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }
//---------------------languages dialog func --------------------------------------------
  showLanguagesDialog = () => {
    this.shopsStore.handleLanguagesDialog(this.state.editItem)
    this.setState({
        showLanguagesDialog: true
    })
  }

  handleCloseLanguagesDialog = () => {
    this.setState({
        showLanguagesDialog: false
    })
  }

  handleSaveLanguages = () => {
    this.state.editItem.languages = this.shopsStore.languages !== undefined && this.shopsStore.languages.length > 0 ? this.shopsStore.languages.map(lang => lang.language).join(',') : ''
    this.setState({
        showLanguagesDialog: false
    })
  }

  setLanguagesHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.shopsStore.languagesColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.shopsStore.constLanguagesHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  showLanguageDelDialog = index => {
    this.setState({
      hideLanguageDelDialog: false,
      languageDelIndex: index
    })
  }

  handleCloseLanguageDelDialog = () => {
    this.setState({ hideLanguageDelDialog: true })
  }

  handleDeleteLanguage = () => {
    this.shopsStore.removeLanguage(this.state.languageDelIndex)
    this.setState({
      hideLanguageDelDialog: true,
      languageDelIndex: undefined
    })
  }

  handleCloseLanguageInsertDialog = () => {
    this.setState({ showLanguageInsertDialog: false })
  }

  showLanguageInsertDialog = () => {
    const languageItem = {} 
    this.setState({
        showLanguageInsertDialog: true,
        languageItem
    })
  }

  handleLanguageInsert = () => {
    this.shopsStore.insertLanguage(this.state.languageItem)
    this.setState({ showLanguageInsertDialog: false })
  }

  handleLanguagesItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            onClick={() => this.showLanguageDelDialog(index)}
            iconProps={delIcon}
            title={t('common:LANGUAGE_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }
//---------------------shops dialog func --------------------------------------------
  showShopsDialog = () => {
    this.shopsStore.handleShopsDialog(this.state.editItem)
    this.setState({
        showShopsDialog: true
    })
  }

  handleCloseShopsDialog = () => {
    this.setState({
        showShopsDialog: false
    })
  }

  handleSaveShops = () => {
    this.state.editItem.shops = this.shopsStore.shops !== undefined && this.shopsStore.shops.length > 0 ? this.shopsStore.shops.map(shop => shop.shop).join(',') : ''
    this.setState({
        showShopsDialog: false
    })
  }

  setShopsHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.shopsStore.shopsColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.shopsStore.constShopsHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  showShopsDelDialog = index => {
    this.setState({
      hideShopDelDialog: false,
      shopDelIndex: index
    })
  }

  handleCloseShopDelDialog = () => {
    this.setState({ hideShopDelDialog: true })
  }

  handleDeleteShop = () => {
    this.shopsStore.removeShopShops(this.state.shopDelIndex)
    this.setState({
      hideShopDelDialog: true,
      shopDelIndex: undefined
    })
  }

  handleCloseShopInsertDialog = () => {
    this.setState({ showShopInsertDialog: false })
  }

  showShopInsertDialog = () => {
    const shopItem = {} 
    this.setState({
        showShopInsertDialog: true,
        shopItem
    })
  }

  handleShopInsert = () => {
    this.shopsStore.insertShop(this.state.shopItem)
    this.setState({ showShopInsertDialog: false })
  }

  handleShopsItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            onClick={() => this.showShopsDelDialog(index)}
            iconProps={delIcon}
            title={t('common:SHOP_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }
  render () {
    const { t, store: { shopsStore: { items, languages, languagesIso, shops, shopNames} } } = this.props
    const { hideEditDialog, hideDelDialog, editItem, delIndex, showLanguagesDialog, hideLanguageDelDialog,
        languageDelIndex, showLanguageInsertDialog, languageItem,
        showShopsDialog, hideShopDelDialog, shopDelIndex, showShopInsertDialog, shopItem } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const containerStyle = {
      root: {
        width: '100%',
        height: '100%'
      }
    }
    const growingStyles = {
        root: {
            display: 'flex',
            height: '50%'
        }
    }
    const textFieldsStyles = {
      fieldGroup: { width: 250, height: 20, float: 'right' }
    }
    return (
      <div className='page-container'>
        <Stack verticall tokens={stackTokens} styles={containerStyle}>
            <div id='shopsDetailsList'>
              <DetailsList ariaLabelForGrid='Shops'
                items={items} 
                setKey='set'
                columns={this.setHeaderColumns()}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.single}
                selection={this.selection}
                enterModalSelectionOnTouch={true}
                selectionPreservedOnEmptyClick={true}
                constrainMode={ConstrainMode.unconstrained}
                onRenderItemColumn={this.handleItemColumn}
            />
            <IconButton
              iconProps={addIcon}
              title={t('common:SHOP_ADD')}
              onClick={() => this.showEditDialog()}
          />
        </div>
        </Stack>
        <Dialog
          minWidth={400}
          maxWidth={800}
          hidden={hideEditDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: editItem !== undefined && editItem.id !== undefined ? t('common:SHOP_EDIT') : t('common:SHOP_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <TextField label={t('common:UNITREE_SHOP_NAME')} id='shop_name' defaultValue={editItem !== undefined ? editItem.shop_name : ''} 
              onChange={(event, newValue) => {
                if (editItem !== undefined) {
                  editItem.shop_name = newValue
                  this.setState({
                    editItem
                  })
                }
              }}
              styles={textFieldsStyles}/>
            <Stack horizontal tokens={stackTokens}>
                <Stack.Item styles={{root: {width: '100%'}}}>     
                    <TextField label={t('common:SHOP_LANGUAGES')} id='languages' readOnly value={editItem !== undefined ? editItem.languages ?? ''  : '' || ''} styles={{fieldGroup: { width: 215, height: 20, float: 'right' }}}/>
                </Stack.Item> 
                <Stack.Item styles={growingStyles}>  
                    <IconButton
                        iconProps={viewIcon}
                        title={t('common:SHOP_ADD_LANGUAGE')}
                        onClick={() => this.showLanguagesDialog()}
                    />
                </Stack.Item>  
            </Stack>  
            <Stack horizontal tokens={stackTokens}>
                <Stack.Item styles={{root: {width: '100%'}}}>     
                    <TextField label={t('common:SHOP_SHOPS')} id='shops' readOnly value={editItem !== undefined ? editItem.shops ?? ''  : '' || ''} styles={{fieldGroup: { width: 215, height: 20, float: 'right' }}}/>
                </Stack.Item> 
                <Stack.Item styles={growingStyles}>  
                    <IconButton
                        iconProps={viewIcon}
                        title={t('common:SHOP_ADD_SHOP')}
                        onClick={() => this.showShopsDialog()}
                    />
                </Stack.Item>  
            </Stack>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        {hideDelDialog === false && <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:SHOP_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:SHOP_DELETE_QESTION') + (delIndex !== undefined && items[delIndex] !== undefined ? items[delIndex].shop_name : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        {showLanguagesDialog && <Dialog
          minWidth={500}
          maxWidth={700}
          hidden={!showLanguagesDialog}
          onDismiss={this.handleCloseLanguagesDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:SHOP_ADD_LANGUAGE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
              <IconButton
                iconProps={addIcon}
                title={t('common:LANGUAGE_ADD')}
                onClick={() => this.showLanguageInsertDialog()}
              />         
              <div style={{ position: "relative", height: 300 }} id='languagesListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='languagesDetailsList'
                    items={languages} 
                    setKey='set'
                    columns={this.setLanguagesHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderItemColumn={this.handleLanguagesItemColumn}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSaveLanguages} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseLanguagesDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        {hideLanguageDelDialog === false && <Dialog
          hidden={hideLanguageDelDialog}
          onDismiss={this.handleCloseLanguageDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:LANGUAGE_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:LANGUAGE_DELETE_QESTION') + (languages[languageDelIndex] !== undefined ? languages[languageDelIndex].language : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteLanguage} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseLanguageDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        { showLanguageInsertDialog && <Dialog
          minWidth={400}
          maxWidth={600}
          hidden={!showLanguageInsertDialog}
          onDismiss={this.handleCloseLanguageInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:LANGUAGE_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
          <Dropdown id='shop_name'
                placeholder={t('common:UNITREE_DROPDOWN_LANG_PLACEHOLDER')}
                label={t('common:UNITREE_LANGUAGE_ISO')}
                options={languagesIso}
                onChange={(event, { key, text }) => {
                  if (languageItem !== undefined) {
                    languageItem.language = key
                    this.setState({
                        languageItem
                    })
                  }}
                }
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleLanguageInsert} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseLanguageInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {showShopsDialog && <Dialog
          minWidth={500}
          maxWidth={700}
          hidden={!showShopsDialog}
          onDismiss={this.handleCloseShopsDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:SHOP_ADD_SHOP'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
              <IconButton
                iconProps={addIcon}
                title={t('common:SHOP_ADD')}
                onClick={() => this.showShopInsertDialog()}
              />         
              <div style={{ position: "relative", height: 300 }} id='shopsListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='shopsDetailsList'
                    items={shops} 
                    setKey='set'
                    columns={this.setShopsHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderItemColumn={this.handleShopsItemColumn}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSaveShops} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseShopsDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        { hideShopDelDialog === false && <Dialog
          hidden={hideShopDelDialog}
          onDismiss={this.handleCloseShopDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:SHOP_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:SHOP_DELETE_QESTION') + (shops[shopDelIndex] !== undefined ? shops[shopDelIndex].shop : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteShop} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseShopDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        { showShopInsertDialog && <Dialog
          minWidth={400}
          maxWidth={600}
          hidden={!showShopInsertDialog}
          onDismiss={this.handleCloseShopInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:SHOP_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
          <Dropdown id='shop_name'
                placeholder={t('common:UNITREE_DROPDOWN_SHOP_PLACEHOLDER')}
                label={t('common:UNITREE_SHOP_NAME')}
                options={shopNames}
                onChange={(event, { key, text }) => {
                  if (shopItem !== undefined) {
                    shopItem.shop = key
                    this.setState({
                        shopItem
                    })
                  }}
                }
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleShopInsert} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseShopInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }       
      </div>             
    )
  }
}

export { Shops }
export default withTranslation(['common'], { wait: true })(withMainContainer(Shops))