import { observable } from 'mobx'

class DialogNodeFilterStore {
  @observable filterColumns = []  
  @observable hideEditFilterDialog = true
  @observable editFilterItem = undefined
  @observable hideDelFilterDialog = true
  @observable delFilterIndex = undefined

  origFilter = ''
  editFilterIndex = undefined

  constFilterHeader = [
    { key: 'filter_catxt', text: 'NODE_FILTER_CAID' },
    { key: 'filter_open', text: 'NODE_FILTER_OPEN' },
    { key: 'filter_bar', text : 'NODE_FILTER_BAR' },
    { key: 'filter_default', text: 'NODE_FILTER_DEFAULT' },
    { key: 'filter_uom', text: 'NODE_FILTER_UOM' },
    { key: 'buttons', text: '' }
  ]

  constructor (authStore, dialogNodeStore) {
    this.authStore = authStore 
    this.dialogNodeStore = dialogNodeStore

    this.constFilterHeader.forEach(({key}) => this.filterColumns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: key === 'filter_catxt' ? '40%' : '12%',
      minWidth: 45,
      maxWidth: key === 'filter_catxt' ? 250 : 45,
    }))
  }
  /**
   * Hide / Show dialog
   */
  handleEditFilterDialog = (filter) => {
    this.hideEditFilterDialog = !this.hideEditFilterDialog
    this.editFilterItem = filter
  }  

  /**
   * Hide / Show dialog
   */
  handleDelFilterDialog = (index) => {
    this.hideDelFilterDialog = !this.hideDelFilterDialog
    this.delFilterIndex = index
  } 

  editFilter = () => {
    if (!this.editFilterItem.isEdit) {
      this.dialogNodeStore.critFiltersItems.push(this.editFilterItem)
    }
    this.dialogNodeStore.filterItem.filter = this.dialogNodeStore.critFiltersItems
  }

  removeFilter = () => {
    this.dialogNodeStore.critFiltersItems.splice(this.delFilterIndex, 1)
    this.dialogNodeStore.filterItem.filter = this.dialogNodeStore.critFiltersItems
  }
}

export default DialogNodeFilterStore
