import React from 'react'
import { observable, action } from 'mobx'
import config from '../../config/config'
const data_config = require('../../config/data.json')
class CategoriesTreeStore {
  categories = []
  @observable items = new Map()
  @observable selectedTreeKey = null
  @observable hideDialog = true
  @observable editNode = undefined
  @observable expandedItems = []
  @observable selectedItems = []
  @observable focusedItem = undefined
  @observable loadingOnSave = false
  
  mapChildren = new Map()
  mapCategories = new Map()

  mapQuickCategoriesByCol = {}
  
  rootNodes = new Map()

  root = { sort: '000-00-00-00-00-00'}
  
  origEditNode = ''
  editIndex = undefined

  @observable searchTexts = []
  
  nodeParents=[]
  categoriesOptions = []
  
  modifiedCategories = []

  quickChecked = []

  constructor (authStore, esObjectStore, categoriesStore) {
    this.esObjectStore = esObjectStore
    this.authStore = authStore
    this.categoriesStore = categoriesStore
  }

  fetchCategoriesTranslations = async (esObjectId) => {
    const result = await fetch(`${config.server_address}/fe/categoryTexts/${esObjectId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    data.message.forEach(text => {
      this.categoriesStore.addTranslationToMap(`${text.category_id}@${text.text_key}`, text.language_iso, text.text)
    });
  }

  /**
   * Get the CategoriesTrees from DB
   */
  populateCategories = async (esObjectId, loadTranslations = true) => {
    // this.categoriesOptions = []
    this.categories = []
    this.mapCategories.clear()
    this.quickChecked = []
    // this.items.clear()
    this.searchTexts = []
    if (loadTranslations) {
      this.categoriesStore.mapTextsTranslations.clear()
      await this.fetchCategoriesTranslations(esObjectId)
    }
    const result = await fetch(`${config.server_address}/fe/categories/${esObjectId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    const results = data.message
    results.forEach(result => {
      const json_source = JSON.parse(result.json_source)
      this.categories.push({...json_source, 
        // set translations
        node_name_trans: this.categoriesStore.mapTextsTranslations.get(`${result.id}@leaftxt`),
        node_keywords_trans: this.categoriesStore.mapTextsTranslations.get(`${result.id}@node_keywords`),
        //set id
        id: result.id
      })
    })
    this.categories.sort((node1, node2) => node1.sort.localeCompare(node2.sort))
    this.categories.forEach(category => {
      this.mapCategories.set(category.leafid, category)
      // set optionsForDopdrown
      // this.categoriesOptions.push({
      //   key: category.leafid,
      //   value: category.leafid,
      //   text: `${category.node_name_trans !== undefined ? category.node_name_trans[this.esObjectStore.defaultLang] : '}`
      // })
      // set serchTextArray
      this.nodeParents = []
      this.getNodeParents(category)
      this.nodeParents.reverse()
      let name = ''
      this.nodeParents.forEach(parent => {
        name += parent + ' > '
      })
      name += category.node_name_trans !== undefined ? category.node_name_trans[this.esObjectStore.defaultLang] : ''
      this.searchTexts.push({ key: category.id, name, node: category })
    })
    data_config.vc.forEach(key =>
      this.buildTree(key)
    )
    this.buildMapQuickCategories()
    if (this.selectedTreeKey === null) {
      this.selectedTreeKey = data_config.vc[0]
    }
  }

  getNodeParents = (category) => {
    const parent = this.categories.find(node => node.leafid === category.parentid)
    if (parent !== undefined) {
      this.nodeParents.push(parent.node_name_trans !== undefined ? parent.node_name_trans[this.esObjectStore.defaultLang] : '')
      this.getNodeParents(parent)
    }
  }
  populateMapChildren = () => {
    this.mapChildren.clear()
    this.categories.forEach(item => {
      if (!this.mapChildren.has(item.parentid)) {
        this.mapChildren.set(item.parentid, [])
      }
      this.mapChildren.get(item.parentid).push({leafid: item.leafid, sort: item.sort, vc: item.vc})
      if (!this.mapCategories.has(item.parentid)) { // is node root
        console.log(item.parentid)
        if (!this.rootNodes.has(item.vc)) {
          this.rootNodes.set(item.vc, [])
        }
        if (this.rootNodes.get(item.vc).findIndex(root => root.leafid === item.parentid) === -1) {
          this.rootNodes.get(item.vc).push({leafid: item.parentid})
        }
      }
    })
    console.log(this.rootNodes)
  }

  buildTree = (vehicle_type) => {
    this.selectedItems = []
    this.expandedItems = []
    this.focusedItem = undefined
    this.populateMapChildren()
    let childrenRoot = []
    console.log(vehicle_type, this.rootNodes.get(vehicle_type))
    this.rootNodes.has(vehicle_type) && this.rootNodes.get(vehicle_type).forEach(root => {
      if (this.mapChildren.has(root.leafid)) {
        childrenRoot.push(...this.mapChildren.get(root.leafid).filter(child => child.vc === vehicle_type))
      }
    })
    if (!this.items.get(vehicle_type)) {
      this.items.set(vehicle_type, {})
    }
    if (childrenRoot.length > 0) {
      this.items.get(vehicle_type)['root']= {
        index: 'root',
        isFolder: true,
        children: childrenRoot.sort((c1,c2) => c1.sort.localeCompare(c2.sort)).map(c => c.leafid),
        data: this.root,
      }
    }
    for (let index = 0; index < this.categories.length; index++) {
        const item = this.categories[index]
        const id = item.leafid
        const children = this.mapChildren.has(id) ? this.mapChildren.get(id) : []
        if (item.vc === vehicle_type) {
          this.items.get(vehicle_type)[id] = {
            index: id,
            isFolder: children.length > 0,
            children: children.map(c => c.leafid),
            data: item,
            canMove: true
          }
        }
    }
  }

  buildMapQuickCategories = () => {
    this.mapQuickCategoriesByCol = {}
    const mapQuickCategories = new Map()
    this.categories.forEach(category => {
      // build quickCategories map
      if (!mapQuickCategories.has(category.vc)) {
        mapQuickCategories.set(category.vc, [])
       } 
       if (category.qrow && category.qcol && this.mapChildren.has(category.leafid)) {
        const children = this.mapChildren.get(category.leafid).map(child => this.mapCategories.get(child.leafid)).filter(child => child.qshow === "1" && child.qsort).sort((c1, c2) => c1.qsort - c2.qsort)
        if (children.length > 0) {
          mapQuickCategories.get(category.vc).push({parent: category, children: children})
        }
       }
     })
     for (const [key, values] of mapQuickCategories.entries()) {
      this.mapQuickCategoriesByCol[key] = new Map()
      values.sort((c1, c2) => c1.parent.qcol - c2.parent.qcol).forEach(category => {
        if (!this.mapQuickCategoriesByCol[key].has(category.parent.qcol)) {
          this.mapQuickCategoriesByCol[key].set(category.parent.qcol, [])
        }
        this.mapQuickCategoriesByCol[key].get(category.parent.qcol).push(category)
        this.mapQuickCategoriesByCol[key].get(category.parent.qcol).sort((c1,c2) => c1.parent.qrow - c2.parent.qrow)
      })
     }
  }
  expandParents = (item) => {
    const parent = Object.values(this.items.get(item.data.vc)).find(it =>
      it.data.leafid === item.data.parentid
    )
    if (parent !== undefined) {
      this.expandedItems = [...this.expandedItems, parent.index] // expand parent
      if(parent.index !== 'root') { // is not root expand upper parent
        this.expandParents(parent)
      }
    }
  }

  /**
   * Hide / Show dialog
   */
  handleHideDialog = (node) => {
    this.hideDialog = !this.hideDialog
    this.editNode = node
  }

  scrollToItem = (item) => {
    let section = document.getElementById(`node_${item.index}`)
    if (section) {
      section.scrollIntoView()
      return
    }
    const parent = Object.values(this.items.get(item.data.vc)).find(it =>
      it.data.leafid === item.data.parentid
    )
    if (parent !== undefined && parent.index !== 'root') {
      this.scrollToItem(parent)
    }
  }

  @action
  selectItem = (item) => {
    this.expandParents(item)
    this.selectedItems = []
    this.selectedItems.push(item.index)
    this.focusedItem = item.index
    this.scrollToItem(item)
  }

  saveNodes = async() => {
    if (this.modifiedCategories.length === 0) {
      return
    }
    this.loadingOnSave = true
    for (const category of this.modifiedCategories) {
      await this.saveNodeInDB(true, category.id, category)
    }
    await this.populateCategories(this.categoriesStore.selectedESObject.id, false)
    this.modifiedCategories = []
    this.loadingOnSave = false
  } 

  saveNodeInDB = async(isEdit, categoryId, node) => {
    // delete temp properties from the object
    delete node.node_name_trans
    delete node.node_keywords_trans
    delete node.id
    // save in DB 
    const result = await fetch(`${config.server_address}/fe/categories/${isEdit ? 'edit' : 'insert'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: categoryId,
        es_object_id: this.categoriesStore.selectedESObject.id,
        json_source:  JSON.parse(JSON.stringify(node)),
        updated_by: this.authStore.current_user.DisplayName
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    return result.json()
  }

  saveNode = async() => {
    this.loadingOnSave = true
    let categoryId = this.editNode.id
    const node_name_trans = Object.assign({}, this.editNode.node_name_trans)
    const node_keywords_trans = Object.assign({}, this.editNode.node_keywords_trans)
    const isEdit = this.editNode.isEdit
    // delete temp properties from genarts
    this.editNode.genarts.forEach(genart => {
      delete genart.gatxt
      delete genart.isEdit
      // criteria
      delete genart.criteria
      // cupis 
      delete genart.cupis
      // filters
      genart.filters.forEach(filter => {
        delete filter.filter_catxt
        delete filter.isEdit
      })
    })
    // delete temp properties from the object
    delete this.editNode.isEdit
    const res = await this.saveNodeInDB(isEdit, categoryId, this.editNode)
    if (res.success === true) {
      if (categoryId === undefined) {
        categoryId = res.message
      }
      // save texts for node in DB and in mapTranslations
      for (const [lang, text] of Object.entries(node_name_trans)) {
        this.categoriesStore.addTranslationToMap(`${categoryId}@leaftxt`, lang, text)
        await this.categoriesStore.editText(categoryId, 'leaftxt', lang, text)
      }
      for (const [lang, text] of Object.entries(node_keywords_trans)) {
        this.categoriesStore.addTranslationToMap(`${categoryId}@node_keywords`, lang, text) 
        await this.categoriesStore.editText(categoryId, 'node_keywords', lang, text)
      }
      await this.populateCategories(this.categoriesStore.selectedESObject.id, false)
    }
    this.loadingOnSave = false
    this.handleHideDialog()
  }

  removeChildren = async(parentid) => {
    const children = this.mapChildren.get(parentid)
    if (children === undefined) {
      return
    }
    for (const child_leaf_id of children) {
      const child = this.mapCategories.get(child_leaf_id.leafid)
      console.log(child)
      if (child) {
        await this.deleteTexts(child.id)
        await this.deleteCategoryFromDb(child.id)
      }
      if (this.mapChildren.has(child_leaf_id.leafid)) {
        await this.removeChildren(child_leaf_id.leafid)
      }
    }
  }

  deleteTexts = async(categoryId) => {
    this.categoriesStore.mapTextsTranslations.delete(`${categoryId}@leaftxt]`)
    await this.categoriesStore.removeText(categoryId, 'leaftxt')
    
    this.categoriesStore.mapTextsTranslations.delete(`${categoryId}@node_keywords`) 
    await this.categoriesStore.removeText(categoryId, 'node_keywords')
  }

  deleteCategoryFromDb = async(id) => {
    const result = await fetch(`${config.server_address}/fe/categories/delete/${id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    return await result.json()
  }

  removeNode = async(delNode) => {
    // delete children
    await this.removeChildren(delNode.leafid)
    // delete texts
    await this.deleteTexts(delNode.id)
    // delete node from DB 
    const result = await this.deleteCategoryFromDb(delNode.id)
    if (result.success === true) {
      await this.populateCategories(this.categoriesStore.selectedESObject.id, false)
    }
  }
} 

export default CategoriesTreeStore
