import { observable, action } from 'mobx'
import config from '../../config/config'
const shops = require('../../config/shops.json')

class OverviewStore {
  
  @observable items = []
  @observable columns = []

  @observable servoArticlesCount = ''
  @observable servoArticlesLastUpdate = ''
  @observable servoFitmentCount = ''
  @observable servoFitmentLastUpdate = ''
  @observable servoArticlesCountByShop = {}
  @observable tecDocLastUpdate = ''
  @observable esArticles = {}
  @observable esFitments = {}
  @observable esVga = {}
  @observable esWendung = {}
  @observable esVehicles = {}
  @observable esModels = {}
  @observable esMakes = {}
  shopsDropDown = [] 

  indices = [
    { name: 'Articles', index: 'articles', index_type:'refs', langDependent: true }, 
    { name: 'Fitments', index: 'vehicle_genart_art', index_type:'vga', langDependent: true }, 
    { name: 'Vehicle ga', index: 'vehicle_genart', index_type:'vehicle_ga', langDependent: false }, 
    { name: 'Wendung', index: 'article_vehicles', index_type:'article', langDependent: true }, 
    { name: 'Vehicles', index: 'vehicles', index_type:'refs', langDependent: true }, 
    { name: 'Models', index: 'models', index_type:'refs', langDependent: true }, 
    { name: 'Makes', index: 'makes', index_type:'refs', langDependent: false }
  ]

  @observable checked = []

  constHeader = []

  existingLangs = ['de','fr','it','cs','en','sr','hu','ro']

  constructor (authStore) {
    this.authStore = authStore 
    Object.keys(shops).forEach(shop => {
        this.shopsDropDown.push({
          key: shop,
          text: shop
        })
    })
  }

  buildHeader = (shop) => {
    this.columns = []
    this.constHeader = []
    this.constHeader.push({ name: '', key: 'detail', width: '20%', minWidth: 150, maxWidth: 150 })
    this.constHeader.push({ name: 'Servo', key: 'servo', width: '20%', minWidth: 100, maxWidth: 250 })
    const proc = 60/shops[shop].languages.split(',').length
    shops[shop].languages.split(',').map(lang => 
      this.constHeader.push({ name: lang, key: lang, width: `${proc}%`, minWidth: proc*10, maxWidth: proc*16 })
    )
    this.constHeader.forEach(header => this.columns.push({
      key: header.key,
      name: header.name,
      fieldName: header.key,
      isResizable: false,
      width: header.width,
      minWidth: header.minWidth,
      maxWidth: header.maxWidth
     })
    )
  }

  populateData = async(env, shop) => {
    this.clearServoData()
    this.clearESData()
    await Promise.all([this.populateServoData(env, shop), this.populateESData(env, shop)])
    this.populateItems(shop)
  }

  clearServoData = () => {
    this.servoArticlesCount = ''
    this.servoArticlesLastUpdate = ''
    this.servoFitmentCount = ''
    this.servoFitmentLastUpdate = ''
    this.servoArticlesCountByShop = {}
    this.tecDocLastUpdate = ''
  }
  /**
   * Get data from Servo
   */
  populateServoData = async (env, shop) => {
    // get last update of connect 
    const resultLastUpdate = await fetch(`${config.server_address}/fe/servo/jobs/${env}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
    })
    // token no longer valid => forward to welcome page
    if (resultLastUpdate.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const dataLastUpdate = await resultLastUpdate.json()
    if (dataLastUpdate.success && dataLastUpdate !== undefined) {
      this.servoArticlesLastUpdate = dataLastUpdate.message.articles
      this.servoFitmentLastUpdate = dataLastUpdate.message.fitments
      this.tecDocLastUpdate = dataLastUpdate.message.tecdoc
    }
    // get articles total records count
    const resultArticles = await fetch(`${config.server_address}/fe/servo/articlesCount/${env}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
    })
    // token no longer valid => forward to welcome page
    if (resultArticles.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const dataArticles = await resultArticles.json()
    if (dataArticles.success) {
      this.servoArticlesCount = dataArticles.message
    }

    // get fitments total records count
    const resultFitments = await fetch(`${config.server_address}/fe/servo/fitmentsCount/${env}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`,
          'Content-Type': 'application/json; charset=utf-8'
        },
      })
      // token no longer valid => forward to welcome page
      if (resultFitments.status === 401) {
        this.authStore.clearUserSession(true)
        return
      }
      const dataFitments = await resultFitments.json()
      if (dataFitments.success) {
        this.servoFitmentCount = dataFitments.message
      }
      
      await this.getArticleCountByShop(env, shop)
  }

  getArticleCountByShop = async(env, shop) => {
    const result = await fetch(`${config.server_address}/fe/servo/mappingArticlesCount/${env}/${shops[shop].articleMappingTable}/${shops[shop].name}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`,
          'Content-Type': 'application/json; charset=utf-8'
        },
      })
      // token no longer valid => forward to welcome page
      if (result.status === 401) {
        this.authStore.clearUserSession(true)
        return
      }
      const data = await result.json()
      if (data.success) {
        this.servoArticlesCountByShop[shop] = data.message
      }
  }

  clearESData = () => {
    this.esArticles = {}
    this.esFitments = {}
    this.esVga = {}
    this.esWendung = {}
    this.esVehicles = {}
    this.esModels = {}
    this.esMakes = {}
  } 

  // Add/Remove checked item from list
  handleCheck = (event, newValue) => {
    var updatedList = [...this.checked];
    if (newValue) {
      updatedList = [...this.checked, event.target.id]
    } else {
      updatedList.splice(this.checked.indexOf(event.target.id), 1)
    }
    this.checked  = updatedList;
  }
  /**
   * Get data from ES
   */
  populateESData = async (env, shop) => {
    const indexPrefix = shops[shop].indexPrefix
    for (const index of this.indices) {
      if (this.checked.includes(index.name)) {
        const result = await fetch(`${config.server_address}/fe/es/getResults/${env}/${shops[shop].esServer}/${index.name !== 'Makes' ? indexPrefix + index.index : index.index}/${index.index_type}${index.langDependent ? `/${shops[shop].languages}`: ''}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${this.authStore.token}`,
              'Content-Type': 'application/json; charset=utf-8'
            },
        })
        // token no longer valid => forward to welcome page
        if (result.status === 401) {
            this.authStore.clearUserSession(true)
            return
        }
        const data = await result.json()
        if (data.success) {
          switch (index.name) {
            case 'Articles':
              this.esArticles = data.message
              break;
            case 'Fitments':
              this.esFitments = data.message
              break;
            case 'Vehicle ga':
              this.esVga = data.message
              break;
            case 'Wendung':
              this.esWendung = data.message
              break;
            case 'Vehicles':
              this.esVehicles = data.message
              break;
            case 'Models':
              this.esModels = data.message
              break;
            case 'Makes':
              this.esMakes = data.message
              break;            
            default:
              break;
          }
        }
      }
    }
  }

  populateItems = (shop) => {
    const languages = shops[shop].languages.split(',')
    this.items = []
    // tecdoc
    this.items.push({
      detail: 'TecDoc last updates', 
      servo: this.tecDocLastUpdate ? new Date(this.tecDocLastUpdate).toISOString().slice(0, 19).replace('T', ' ') : '',
    })
    // Articles
    let item = { detail: 'Articles' }
    if (this.checked.includes('Articles')) {
      languages.map(lang => item[lang] = lang)
    }
    this.items.push(item)
    item = { 
      detail: 'Total number of records',
      servo: this.servoArticlesCount.toLocaleString()
    }
    if(this.checked.includes('Articles') && Object.keys(this.esArticles).length > 0) {
      languages.map(lang => item[lang] = this.esArticles[lang].recordCount?.toLocaleString() + ' (' + this.esArticles[lang].diff + '%)')
    } 
    this.items.push(item)
    item = { 
      detail: 'Last date of updates',
      servo: this.servoArticlesLastUpdate ? new Date(this.servoArticlesLastUpdate).toISOString().slice(0, 19).replace('T', ' ') : ''
    }
    if(this.checked.includes('Articles') && Object.keys(this.esArticles).length > 0) {
      languages.map(lang => item[lang] = this.esArticles[lang].lastUpdate)
    } 
    this.items.push(item)
    // Articles by country
    this.items.push({
      detail: `Articles by country (${shop})`, 
      servo: this.servoArticlesCountByShop[shop]?.toLocaleString()
    })
    // Fitments
    item = { detail: 'Fitments' }
    if(this.checked.includes('Fitments')) {
      languages.map(lang => item[lang] = lang)
    }
    this.items.push(item)
    
    item = { 
      detail: 'Total number of records',
      servo: this.servoFitmentCount.toLocaleString()
    }
    if(this.checked.includes('Fitments') && Object.keys(this.esFitments).length > 0) {
      languages.map(lang => item[lang] = this.esFitments[lang].recordCount?.toLocaleString() + ' (' + this.esFitments[lang].diff + '%)')
    } 
    this.items.push(item)

    item = { 
      detail: 'Last date of updates',
      servo: this.servoFitmentLastUpdate ? new Date(this.servoFitmentLastUpdate).toISOString().slice(0, 19).replace('T', ' ') : ''
    }
    if(this.checked.includes('Fitments') && Object.keys(this.esFitments).length > 0) {
      languages.map(lang => item[lang] = this.esFitments[lang].lastUpdate)
    } 
    this.items.push(item)
    // Vehicle Ga
    if (this.checked.includes('Vehicle ga') && this.esVga.recordCount !== undefined) {
      this.items.push({
        detail: 'Vehicle ga'
      })
      this.items.push({
        detail: 'Total number of records',
        [languages[0]]: this.esVga.recordCount?.toLocaleString() + ' (' + this.esVga.diff + '%)'
      })
      this.items.push({
        detail: 'Last date of updates',
        [languages[0]]: this.esVga.lastUpdate !== undefined ? this.esVga.lastUpdate : ''
      })
    }
    // Wendung
    if (this.checked.includes('Wendung') && Object.keys(this.esWendung).length > 0) {
      item = { detail: 'Wendung' }
      languages.map(lang => item[lang] = lang)
      this.items.push(item)
      
      item = { 
        detail: 'Total number of records'
      }
      languages.map(lang => item[lang] = this.esWendung[lang].recordCount?.toLocaleString() + ' (' + this.esWendung[lang].diff + '%)') 
      this.items.push(item)

      item = { 
        detail: 'Last date of updates'
      }
      languages.map(lang => item[lang] = this.esWendung[lang].lastUpdate) 
      this.items.push(item)
    }
    // Vehicles
    if (this.checked.includes('Vehicles') && Object.keys(this.esVehicles).length > 0) {
      item = { detail: 'Vehicles' }
      languages.map(lang => item[lang] = lang)
      this.items.push(item)
      
      item = { 
        detail: 'Total number of records'
      }
      languages.map(lang => item[lang] = this.esVehicles[lang].recordCount?.toLocaleString() + ' (' + this.esVehicles[lang].diff + '%)') 
      this.items.push(item)

      item = { 
        detail: 'Last date of updates'
      }
      languages.map(lang => item[lang] = this.esVehicles[lang].lastUpdate) 
      this.items.push(item)
    }
    // Vehicles
    if (this.checked.includes('Models') && Object.keys(this.esModels).length > 0) {
      item = { detail: 'Models' }
      languages.map(lang => item[lang] = lang)
      this.items.push(item)
      
      item = { 
        detail: 'Total number of records'
      }
      languages.map(lang => item[lang] = this.esModels[lang].recordCount?.toLocaleString() + ' (' + this.esModels[lang].diff + '%)') 
      this.items.push(item)

      item = { 
        detail: 'Last date of updates'
      }
      languages.map(lang => item[lang] = this.esModels[lang].lastUpdate) 
      this.items.push(item)
    }
    // Makes
    if (this.checked.includes('Makes') && this.esMakes.recordCount !== undefined) {
      this.items.push({
        detail: 'Makes'
      })
      this.items.push({
        detail: 'Total number of records',
        [languages[0]]: this.esMakes.recordCount?.toLocaleString() + ' (' + this.esMakes.diff + '%)'
      })
      this.items.push({
        detail: 'Last date of updates',
        [languages[0]]: this.esMakes.lastUpdate !== undefined ? this.esMakes.lastUpdate : ''
      })
    }
  }
}

export default OverviewStore