import React, { Component } from 'react'
import { Label, Dialog, DialogType, DialogFooter, TextField, Stack, PrimaryButton, DefaultButton, IconButton, Dropdown, 
  ScrollablePane, ScrollbarVisibility, ConstrainMode } from '@fluentui/react'
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import { Dropdown as LookupDropdown} from 'semantic-ui-react'
import Genarts from './Genarts';

const addIcon = { iconName: 'Add' }
const delIcon = { iconName: 'ErrorBadge' }
const viewIcon = { iconName: 'LookupEntities' }
@inject('store')
@withTranslation(['common'])
@observer
class DialogCategory extends Component {
  constructor (props) {
    super(props)
    this.categoriesTreeStore = this.props.store.categoriesTreeStore
    this.dialogCategoryStore = this.props.store.dialogCategoryStore
    this.esObjectStore = this.props.store.esObjectStore
    this.node_name_ref = {}
    this.esObjectStore.langs.map(lang => this.node_name_ref[lang] = React.createRef())
    this.qcol_ref = React.createRef()
    this.qrow_ref = React.createRef()
    this.qlevel_ref = React.createRef()
    this.qsort_ref = React.createRef()
    this.classic_col_ref = React.createRef()
    this.labour_time_ref = React.createRef()
    this.assembly_parts_ref = React.createRef()
    this.sag_code_ref = React.createRef()
    this.state = {
      hideClickGroupDelDialog: true
    }
  }

  handleClose = () => {
    this.categoriesTreeStore.handleHideDialog()
    const { editIndex, origEditNode } = this.categoriesTreeStore
    if (origEditNode !== '') {
      this.categoriesTreeStore.items.get(JSON.parse(origEditNode).data.vc)[editIndex] = JSON.parse(origEditNode)
    }
    this.categoriesTreeStore.origEditNode = ''
    this.categoriesTreeStore.editIndex = undefined
  }

  handleChange = (key, value) => {
    const editNode = this.categoriesTreeStore.editNode
    if (editNode !== undefined) {
      if (['qcol', 'qrow', 'qlevel', 'qsort', 'classic_col', 'labour_time', 'assembly_parts'].includes(key) && isNaN(value)) {
        value = editNode[key]
      }
      editNode[key] = value
    }
  }

  handleSave = () => {
    if (this.categoriesTreeStore.loadingOnSave) {
      return
    }
    let empty = false
    this.esObjectStore.langs.forEach(lang => {
      if (this.node_name_ref[lang].current.value === '' || this.node_name_ref[lang].current.value === undefined) {
        empty = true
      }
    })
    if (empty) {
      return
    }
    // save in editNode the values from refs inputs
    this.esObjectStore.langs.forEach(lang => {
      this.categoriesTreeStore.editNode.node_name_trans[lang] = this.node_name_ref[lang].current.value
    })
    this.categoriesTreeStore.editNode.qcol = this.qcol_ref.current.value
    this.categoriesTreeStore.editNode.qrow = this.qrow_ref.current.value
    this.categoriesTreeStore.editNode.qlevel = this.qlevel_ref.current.value
    this.categoriesTreeStore.editNode.qsort = this.qsort_ref.current.value
    this.categoriesTreeStore.editNode.classic_col = this.classic_col_ref.current.value
    this.categoriesTreeStore.editNode.labour_time = this.labour_time_ref.current.value
    this.categoriesTreeStore.editNode.assembly_parts = this.assembly_parts_ref.current.value
    this.categoriesTreeStore.editNode.sag_code = this.sag_code_ref.current.value

    this.categoriesTreeStore.saveNode()
  }
  
  //click group
  setClickGroupHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.dialogCategoryStore.clickGroupColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.dialogCategoryStore.constClickGroupHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }
  handleClickGroupItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            onClick={() => this.showClickGroupDelDialog(index)}
            iconProps={delIcon}
            title={t('common:CATEGORY_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }
  
  showClickGroupDialog = () => {
    this.dialogCategoryStore.handleClickGroupDialog(this.categoriesTreeStore.editNode.click_group)
  }

  handleCloseClickGroupDialog = () => {
    this.dialogCategoryStore.handleClickGroupDialog()
  }

  handleCloseClickGroupInsertDialog = () => {
    this.dialogCategoryStore.handleCloseClickGroupInsertDialog()
  }

  showClickGroupInsertDialog = () => {
    const item = {
       id: '', 
       text: ''
    } 
    this.dialogCategoryStore.handleCloseClickGroupInsertDialog(item)
  }

  handleClickGroupInsert = () => {
    this.dialogCategoryStore.insertClickGroup()
    this.dialogCategoryStore.handleCloseClickGroupInsertDialog()
  }

  showClickGroupDelDialog = index => {
    this.setState({
      hideClickGroupDelDialog: false,
      catDelIndex: index
    })
  }

  handleCloseClickGroupDelDialog = () => {
    this.setState({ hideClickGroupDelDialog: true })
  }

  handleDeleteClickGroup = () => {
    this.dialogCategoryStore.removeCategoryFromClickGroup(this.state.catDelIndex)
    this.setState({
      hideClickGroupDelDialog: true,
      catDelIndex: undefined
    })
  }

  handleSaveClickGroup = () => {
    if (this.dialogCategoryStore.clickGroup.length > 0) {
      this.categoriesTreeStore.editNode.click_group = this.dialogCategoryStore.clickGroup.map(cat => cat.id).join(',')
    } else {
      this.categoriesTreeStore.editNode.click_group = ''
    } 
    this.dialogCategoryStore.handleClickGroupDialog()
  }

  render () {
    const { t, store: { categoriesTreeStore: { hideDialog, editNode, mapCategories, categoriesOptions, loadingOnSave },
      dialogCategoryStore: { vc, external_service, external_service_attribute, link, clickGroup, showClickGroupDialog,
        showClickGroupInsertDialog, clickGroupItem },
      esObjectStore : { langs, suppliers, brands } } } = this.props
    const { hideClickGroupDelDialog, catDelIndex } = this.state
    const stackTokens = { childrenGap: 5 }
    const growingStyles = {
        root: {
          display: 'flex',
          height: '50%',
          width: 1000,
        }
    }
    const dialogTextFieldsStyles = {
      fieldGroup: { width: 280, height: 20, float: 'right' }
    }
    const textFieldsStyles = {
        fieldGroup: { width: 70, height: 20 }
    }  
    return (
      <div>
        <Dialog
          minWidth={1000}
          maxWidth={1200}
          hidden={hideDialog}
          onDismiss={this.handleClose}
          dialogContentProps={{
            type: DialogType.normal,
            title: editNode !== undefined && editNode.isEdit ? t('common:CATEGORY_EDIT') : t('common:CATEGORY_ADD')
          }}
          modalProps={{ isBlocking: true, dragOptions: true,
            styles: { main: { maxHeight: 690 } } }}
        >
        {loadingOnSave && <Spinner size={SpinnerSize.large} /> }
        <Stack horizontal tokens={stackTokens}>
        <Stack.Item styles={growingStyles}>   
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} styles={{root: {width: '100%'}}}>
            {langs.map(lang => {
              return (
              <TextField label={t('common:TREE_NAME')+ ' ' + lang} ref={this.node_name_ref[lang]} id={'node_name_'+ lang} key={'node_name_'+ lang} 
                defaultValue={editNode !== undefined && editNode.node_name_trans !== undefined? editNode.node_name_trans[lang] : ''} 
                required type='text' onGetErrorMessage={ value => { if (value === '') return lang + t('common:TREE_NAME_DIALOG_INPUT_ERR_MSG') }} 
                styles={dialogTextFieldsStyles}/>
              )})}
              <Stack horizontal tokens={stackTokens} >
                <Checkbox label={t('common:NODE_OPEN')} checked={editNode !== undefined && editNode.open === "1" ? true : false} onChange={(event, newValue) => this.handleChange('open', newValue ? "1" : "")} />
                <Checkbox label={t('common:CATEGORY_SERVICE')} checked={editNode !== undefined && editNode.service === "1" ? true : false} onChange={(event, newValue) => this.handleChange('service', newValue ? "1" : "")} />
                <Checkbox label={t('common:CATEGORY_CHECK')} checked={editNode !== undefined && editNode.is_check === "1" ? true : false} onChange={(event, newValue) => this.handleChange('is_check', newValue ? "1" : "0")} />
              </Stack>
              <Stack horizontal tokens={stackTokens} >
                <TextField label={t('common:CLICK_GROUP')} id='click_group' type='text'  multiline rows={2} readOnly value={this.dialogCategoryStore.getClickGroupTexts(editNode.click_group) || ''} styles={{fieldGroup: { width: 375, height: 20, float: 'right' }}}/>
                <IconButton iconProps={viewIcon} title={t('common:CLICK_GROUP_ADD')} onClick={() => this.showClickGroupDialog()} />
              </Stack>     
              <Stack horizontal tokens={stackTokens}>
                <Label>{t('common:SUPPLIER')}</Label>
                <LookupDropdown
                  placeholder={t('common:SUPPLIER')}
                  defaultValue={editNode !== undefined ? editNode.id_dlnr : ''}
                  fluid
                  search
                  selection
                  closeOnChange
                  clearable
                  options={suppliers}
                  onChange={(e, { value }) => {
                      editNode.id_dlnr  = value
                  }}
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Label>{t('common:BRAND')}&nbsp;&nbsp;&nbsp;&nbsp;</Label>
                <LookupDropdown
                  placeholder={t('common:BRAND')}
                  defaultValue={editNode !== undefined ? editNode.id_product_brand : ''}
                  fluid
                  search
                  selection
                  closeOnChange
                  clearable
                  options={brands}
                  onChange={(e, { value }) => {
                      editNode.id_product_brand  = value
                  }}
                />
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{root: {float: 'right'}}}>
                <Dropdown id='external_service'
                    label={t('common:CATEGORY_EXTERNAL_SERVICE')}
                    defaultSelectedKey={editNode !== undefined ? editNode.external_service : ''}
                    options={external_service}
                    onChange={(event, { key }) => this.handleChange('external_service', key !== '' ? key : null)}
                    styles={{dropdown: { width: 130, height: 20, float: 'right' },
                    caretDownWrapper: { height:20, lineHeight: 'normal' },
                    dropdownItem: 'dropdownItem',
                    dropdownItemSelected: 'dropdownItem',
                    label: { width: 100} 
                  }}
                />
                <Dropdown id='external_service_attribute'
                    label={t('common:TREE_EXTERNAL_SERVICE_ATTRIBUTE')}
                    defaultSelectedKey={editNode !== undefined ? editNode.external_service_attribute : ''}
                    options={external_service_attribute}
                    onChange={(event, { key }) => this.handleChange('external_service_attribute', key !== '' ? key : null)}
                    styles={{dropdown: { width: 135, height: 20, float: 'right' },
                    caretDownWrapper: { height:20, lineHeight: 'normal' },
                    dropdownItem: 'dropdownItem',
                    dropdownItemSelected: 'dropdownItem',
                    label: { width: 148} }}
                /> 
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{root: {float: 'right'}}}>
                <Dropdown id='link'
                    label={t('common:CATEGORY_LINK')}
                    defaultSelectedKey={editNode !== undefined ? editNode.link : ''}
                    options={link}
                    onChange={(event, { key }) => this.handleChange('link', key !== '' ? key : null)}
                    styles={{dropdown: { width: 130, height: 20, float: 'right' },
                    caretDownWrapper: { height:20, lineHeight: 'normal' },
                    dropdownItem: 'dropdownItem',
                    dropdownItemSelected: 'dropdownItem',
                    label: { width: 100} }}
                />
                <Dropdown id='vc'
                    label={t('common:CATEGORY_VC')}
                    disabled
                    defaultSelectedKey={editNode !== undefined ? editNode.vc : ''}
                    options={vc}
                    onChange={(event, { key }) => this.handleChange('vc', key !== '' ? key : null)}
                    styles={{dropdown: { width: 135, height: 20, float: 'right' },
                    caretDownWrapper: { height:20, lineHeight: 'normal' },
                    dropdownItem: 'dropdownItem',
                    dropdownItemSelected: 'dropdownItem',
                    label: { width: 148} }}
                /> 
              </Stack>  
              <Stack horizontal tokens={stackTokens} >
                <Checkbox label={t('common:CATEGORY_QFLAG')} checked={editNode !== undefined && editNode.qflag === "1" ? true : false} onChange={(event, newValue) => this.handleChange('qflag', newValue ? "1" : "0")} />
                <Checkbox label={t('common:CATEGORY_QSHOW')} checked={editNode !== undefined && editNode.qshow === "1" ? true : false} onChange={(event, newValue) => this.handleChange('qshow', newValue ? "1" : "0")} />       
              </Stack>
              <Stack horizontal tokens={stackTokens} >
                <TextField label={t('common:CATEGORY_QCOL')} ref={this.qcol_ref} id='qcol' defaultValue={editNode !== undefined ? editNode.qcol || '' : ''} 
                   styles={textFieldsStyles}/>
                <TextField label={t('common:CATEGORY_QROW')} ref={this.qrow_ref} id='qrow' defaultValue={editNode !== undefined ? editNode.qrow || '' : ''} 
                   styles={textFieldsStyles}/>
                <TextField label={t('common:CATEGORY_QLEVEL')} ref={this.qlevel_ref} id='qlevel' defaultValue={editNode !== undefined ? editNode.qlevel || '' : ''} 
                   styles={textFieldsStyles}/>
                <TextField label={t('common:CATEGORY_QSORT')} ref={this.qsort_ref} id='qsort' defaultValue={editNode !== undefined ? editNode.qsort || '' : ''} 
                   styles={textFieldsStyles}/> 
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{root: { float: 'right'}}}>
                <TextField label={t('common:CATEGORY_CLASICCOL')} ref={this.classic_col_ref} id='classic_col' defaultValue={editNode !== undefined ? editNode.classic_col || '' : ''} 
                  styles={{fieldGroup: { width: 91, height: 20 }}}/>
                <TextField label={t('common:CATEGORY_LABOUR_TIME')} ref={this.labour_time_ref} id='labour_time' defaultValue={editNode !== undefined ? editNode.labour_time || '' : ''} 
                  styles={{fieldGroup: { width: 91, height: 20 }}}/>
                <TextField label={t('common:CATEGORY_ASSEMBLY_PARTS')} ref={this.assembly_parts_ref} id='assembly_parts' defaultValue={editNode !== undefined ? editNode.assembly_parts || '' : ''} 
                  styles={{fieldGroup: { width: 91, height: 20 , float: 'right'}}}/>
              </Stack>
              <Stack horizontal tokens={stackTokens} styles={{root: { float: 'right'}}}>
                <TextField label={t('common:CATEGORY_SAGCODE')} ref={this.sag_code_ref} id='sag_code' defaultValue={editNode !== undefined ? editNode.sag_code || '' : ''} 
                  styles={{ fieldGroup: { width: 195, height: 20 }}}/>
                {/* <TextField label={t('common:TREE_SORT')} id='sort' defaultValue={editNode !== undefined ? editNode.sort || '' : ''} 
                    onChange={(event, newValue) => this.handleChange('sort', newValue)} styles={{
                        fieldGroup: { width: 195, height: 20, float: 'right' }
                    }}/> */}
              </Stack>
            {langs.map(lang => {
              return (
              <TextField label={t('common:NODE_KEYWORDS')+ ' ' + lang} id={'node_keywords_'+ lang} key={'node_keywords_'+ lang} 
                value={editNode !== undefined && editNode.node_keywords_trans && editNode.node_keywords_trans[lang] !== undefined ? editNode.node_keywords_trans[lang] : ''}
                type='text'  multiline rows={3}
                onChange={(event, newValue) => {
                  if (editNode !== undefined) {
                    editNode.node_keywords_trans[lang] = newValue
                  }
                }} styles={{
                  fieldGroup: { width: 350, height: 20, float: 'right' }
              }}/>
              )})}
          </Stack>
        </Stack.Item>  
        <Stack.Item styles={{root: {width: '100%', height: '100%'}}}>
          <div id='genarts'><Genarts/></div>
        </Stack.Item>
        </Stack>  
          <DialogFooter>
            <PrimaryButton onClick={this.handleSave} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleClose} text={t('common:BUTTON_CLOSE')} />
          </DialogFooter>
        </Dialog>
        {showClickGroupDialog && <Dialog
          minWidth={500}
          maxWidth={700}
          hidden={!showClickGroupDialog}
          onDismiss={this.handleCloseClickGroupDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:CLICK_GROUP_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
              <IconButton
                iconProps={addIcon}
                title={t('common:CLICK_GROUP_ADD')}
                onClick={() => this.showClickGroupInsertDialog()}
              />         
              <div style={{ position: "relative", height: 300 }} id='clickGroupListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='clickGroupDetailsList'
                    items={clickGroup} 
                    setKey='set'
                    columns={this.setClickGroupHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderItemColumn={this.handleClickGroupItemColumn}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSaveClickGroup} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseClickGroupDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        { showClickGroupInsertDialog && <Dialog
          minWidth={400}
          maxWidth={600}
          hidden={!showClickGroupInsertDialog}
          onDismiss={this.handleCloseClickGroupInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:CLICK_GROUP_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
            <Stack horizontal tokens={stackTokens}>
              <Stack horizontal tokens={stackTokens} style={{width: '100px'}}>
                <Label>{t('common:CATEGORY')}</Label>
              </Stack>
              <LookupDropdown
                placeholder={t('common:CATEGORY')}
                defaultValue={clickGroupItem !== undefined ? clickGroupItem.id : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={categoriesOptions}
                onChange={(e, { value }) => {
                  clickGroupItem.id = value
                  clickGroupItem.text = `${mapCategories.get(value)?.node_name_trans !== undefined ? mapCategories.get(value)?.node_name_trans[this.esObjectStore.defaultLang] : ''}`
                }}
              />
              </Stack>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleClickGroupInsert} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseClickGroupInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {!hideClickGroupDelDialog && <Dialog
          hidden={hideClickGroupDelDialog}
          onDismiss={this.handleCloseClickGroupDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:CATEGORY_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:CATEGORY_DELETE_CLICK_GROUP_QUESTION') + (clickGroup[catDelIndex] !== undefined ? clickGroup[catDelIndex].text : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteClickGroup} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseClickGroupDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
      </div>
    )
  }
}
export default DialogCategory
