import { observable, action } from 'mobx'
class GenartsStore {
  @observable genartsItems = []
  @observable genartsColumns = [] 
  @observable showGenartDialog = false
  @observable genartItem = undefined
  @observable filtersItems = []
  @observable criteriaItems = []
  @observable cupisItems = []
  
  origGaids = []

  constGenartsHeader = [
    { key: 'gatxt', text : 'NODE_FILTER_GAID' },
    { key: 'buttons', text: '' }
  ]
  constructor (authStore, esObjectStore, categoriesTreeStore) {
    this.authStore = authStore 
    this.esObjectStore = esObjectStore
    this.categoriesTreeStore = categoriesTreeStore 
    this.constGenartsHeader.forEach(({key}) => this.genartsColumns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: key === 'buttons' ? '10%' : '90%',
      minWidth: key === 'buttons' ? 50 : 200,
      maxWidth: key === 'buttons' ? 50 : 300,
    }))
  }

  /**
   * Hide / Show dialog
   */
  handleGenartDialog = (genart) => {
    if (genart) {
      this.origGaids = this.categoriesTreeStore.editNode.genarts.length > 0 ? this.categoriesTreeStore.editNode.genarts.map(genart => genart.gaid) : []
    }
    this.showGenartDialog = !this.showGenartDialog
    this.genartItem = genart
  } 
  
  editGenart = () => {
    if (!this.genartItem.isEdit) {
      this.genartsItems.push(this.genartItem)
    }
    this.categoriesTreeStore.editNode.genarts = this.genartsItems
    this.updateKeywordsOnEditGenarts()
  }

  removeGenart = (delIndex) => {
    if (!this.origGaids.includes(this.genartsItems[delIndex].gaid)) {
      this.origGaids.push(this.genartsItems[delIndex].gaid)
    }
    this.genartsItems.splice(delIndex, 1)
    this.categoriesTreeStore.editNode.genarts = this.genartsItems
    this.updateKeywordsOnEditGenarts(true)
  }
  
  @action
    updateKeywordsOnEditGenarts = (isDelete = false) => {
        const newGaids = this.genartsItems.map(genart => genart.gaid)
        const removedGaids = this.origGaids.filter(gaid => !newGaids.includes(gaid))
        const addedGaids = isDelete ? [] : newGaids.filter(gaid => !this.origGaids.includes(gaid))
        this.esObjectStore.langs.forEach(lang => {
            // remove genarts from keywords
            removedGaids.forEach(gaid => this.categoriesTreeStore.editNode.node_keywords_trans[lang] = this.categoriesTreeStore.editNode.node_keywords_trans[lang].replace(this.esObjectStore.mapGenarts.get(lang).get(gaid), '').replace(/^;|;$/g,'').replace(/;;/g,';'))
            // add new genarts to keywords
            addedGaids.forEach(gaid => this.categoriesTreeStore.editNode.node_keywords_trans[lang] = (this.categoriesTreeStore.editNode.node_keywords_trans[lang] ? this.categoriesTreeStore.editNode.node_keywords_trans[lang] + ';' : '') + this.esObjectStore.mapGenarts.get(lang).get(gaid))
        })
    }
}

export default GenartsStore
