/* eslint-disable camelcase */
import { observable } from 'mobx'
import { UserAgentApplication, Configuration } from 'msal';
// import config
import config from '../config/config'

const INITIAL_COMPONENT_AFTER_LOGIN = config.INITIAL_URL
class AuthStore {
  @observable current_user = {};
  @observable token = null;
  @observable isUserAuth = false;
  @observable selectedApp = null;
  myMSALObj
  reqData = {
    scopes: [`api://${config.clientId}/${config.scopeName}`] // Replace with Client ID and the Scope Name set up in the Expose API menu e.g. "api://a23a278a792-2a424-c242b/Files.Read"
  };

  usersMap = new Map()
  @observable userItems = []

  constructor (history, app_state_store) {
    this.history = history
    this.app_state_store = app_state_store

    // Config object to be passed to Msal on creation
    const msalConfig = {
      auth: {
        clientId: `${config.clientId}`,
        authority: `https://login.microsoftonline.com/${config.directoryId}`
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
      }
    };
    this.myMSALObj = new UserAgentApplication(msalConfig);

    this.myMSALObj.handleRedirectCallback(() => {});
  }

  clearUserSession = PUSH_WELCOME => {
    PUSH_WELCOME && this.history.push('/')
    // reset auth related vars
    this.token = null
    this.isUserAuth = false
    this.current_user = {}
    this.app_state_store.active_sidebar_item = window.location.pathname
  };

  initApp = async () => {
    const tokenResponse = await this.retrieveToken()
    this.token = tokenResponse.accessToken
    if (this.token !== null && this.token !== undefined && this.token !== '') {
      await this.fetchUsers()

      if (!this.userItems.find(user => user.user === tokenResponse.account.userName.toLowerCase())) {
        alert('You are not allowed to use the Connect Tools Application ! Contact your administrator !')
        this.clearUserSession()
        return
      }
      this.isUserAuth = true
      this.current_user = {
        UserName: tokenResponse.account.userName,
        DisplayName: tokenResponse.account.name,
      }  
      this.history.push(INITIAL_COMPONENT_AFTER_LOGIN)
    }
  }

  loginApp = () => {
    let app
    switch (this.selectedApp) {
      case 'unitree':
        app = '/unitree/unitrees'
        break;
      case 'categories':
        app = '/category/categories'
        break;
      case 'brand_priority':
        app = '/brand_priority'
        break;  
      default:
        app = '/dashboard/overview'
        break;
    }
    this.history.push(app)
  }
  
  signin = async() => {
    try {
      this.myMSALObj.loginRedirect(this.reqData);
    } catch (error) {
      throw error;
    }
  }

  signout = async() => {
    this.myMSALObj.logout();
  }

  retrieveToken = async() => {
    try {
      const tokenResponse = await this.myMSALObj
        .acquireTokenSilent(this.reqData)
        .catch(() => false);

      if (!tokenResponse) {
        this.signin();
        return;
      }
      return tokenResponse;
    } catch (error) {
      throw error;
    }
  }

  fetchUsers = async () => {
    this.usersMap.clear()
    const result = await fetch(`${config.server_address}/fe/users`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.clearUserSession(true)
      return
    }
    const data = await result.json()
    this.userItems = data.message

    const resultRoles = await fetch(`${config.server_address}/fe/users/userRoles`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (resultRoles.status === 401) {
      this.clearUserSession(true)
      return
    }
    const dataRoles = await resultRoles.json()
    dataRoles.message.forEach(userRole => {
        if (this.usersMap.get(userRole.id_user) === undefined) {
          this.usersMap.set(userRole.id_user, [])
        }
        this.usersMap.get(userRole.id_user).push({role: userRole.role_name, value: userRole.role_value})
    })
  }

  hasUserRight = (role) => {
    if (this.current_user.UserName === undefined) {
      return false
    }
    const user = this.userItems.find(user => user.user === this.current_user.UserName.toLowerCase())
    if (user !== undefined) {
      const roles = this.usersMap.get(user.id)
      if (roles !== undefined) {
        return roles.find(el => el.role === role && el.value === 1) !== undefined
      } 
    }
    return false
  }
}

export default AuthStore
