import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Selection
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Checkbox,
  Dropdown, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup'
import withMainContainer from '../withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown as LookupDropdown} from 'semantic-ui-react'
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';

const addIcon = { iconName: 'Add' }
const delIcon = { iconName: 'ErrorBadge' }
const data = require('../../../../config/data.json')

const theme = getTheme()
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})
@inject('store')
@observer
class Brands extends Component {
  constructor (props) {
    super(props)
    this.esObjectStore = this.props.store.esObjectStore
    this.brandsStore = this.props.store.brandsStore
    this.brandPriorities = this.props.store.brandPriorities
    this.brandPriorityStore = this.props.store.brandPriorityStore
    this.selection = new Selection()
    this.dragDropEvents = this.getDragDropEvents()
    this.draggedItem = undefined
    this.draggedIndex = -1
    this.state = {
      hideDelDialog: true,
      showPrioDialog: false,
      selectedMandant: data.mandant.brand[data.mandant_type.brand[0]][this.brandPriorityStore.selectedESObject.shop_name][0],
      prioOptions: [
        { key: 'B', text: 'Add to priority ' },
        { key: 'A', text: 'Add to priority ' },
        { key: 'N', text: 'Create a new main priority group' }
      ],
      selectedPrioOptio: undefined
    }
    this.brandsStore.populateMandants(data.mandant_type.brand[0])
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.brandsStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.brandsStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  handleItemColumn = (item, index, column) => {
    const { t } = this.props
    if (column.fieldName === 'buttons') {
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:BRAND_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  showInsertDialog = (item, index) => {
    if (item === undefined) {
      item = {
        priorities: [],
        isEdit: false,
      }
    }
    this.brandsStore.handleDialog(item)
  }

  handleCloseEditDialog = () => {
    this.brandsStore.checked = []
    this.brandsStore.handleDialog()
  }

  handleEdit = () => {
    if (!this.brandsStore.item.brand_id) {
      alert(this.props.t('common:MSG_SELECT_BRAND'))
      return
    }
    if (this.brandsStore.checked.length < 1) {
      alert(this.props.t('common:MSG_FILL_PRIORITY_FIELDS'))
      return
    }
    this.brandsStore.checked.forEach(mandant => {
      const item = {
        sort: {}
      }
      let indexBrand = -1
      if (this.brandsStore.allPrioritiesItemsByMandant.has(mandant)) {
        indexBrand = this.brandsStore.allPrioritiesItemsByMandant.get(mandant).findIndex(item => item.brand_id === this.brandsStore.item.brand_id)
      } 
      const index =  this.brandsStore.item.priorities.findIndex(it => it.mandant === mandant)
      if (indexBrand < 0 && index < 0) { // add only if the brand doesn't exist for the mandant and the mandant doesn't exist for the brand
        if (!this.brandsStore.allPrioritiesItemsByMandant.has(mandant)) {
          this.brandsStore.allPrioritiesItemsByMandant.set(mandant, [])
        }
        const maxPrio = this.brandsStore.allPrioritiesItemsByMandant.get(mandant).reduce((acc, curr) => Math.max(acc, Number(curr.prio)), 0)
        
        item.prio = (maxPrio + 1).toString()
        item.subprio = "1"
        item.sort["1"] = item.prio
        item.sort["2"] = item.subprio
        item.mandant = mandant
        item.type = Object.keys(data.mandant.brand).find(key => data.mandant.brand[key][this.brandPriorityStore.selectedESObject.shop_name].includes(mandant)) 
        item[item.type] = mandant
        this.brandsStore.item.priorities.push(item)
      
        this.brandsStore.allPrioritiesItemsByMandant.get(mandant).push({...item, brand_id: this.brandsStore.item.brand_id, brandName: this.esObjectStore.mapManufacturers.get(this.brandsStore.item.brand_id) !== undefined ? this.brandsStore.item.brand_id + ' - ' + this.esObjectStore.mapManufacturers.get(this.brandsStore.item.brand_id) : this.brandsStore.item.brand_id})  
      } 
    }) 
    this.brandsStore.checked = []
    this.brandsStore.handleDialog()
  }

  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => { 
    this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).splice(this.state.delIndex, 1)
    this.setState({
      hideDelDialog: true,
      delIndex: undefined
    })
  }

  getDragDropEvents = () => {
    return {
      canDrop: (dropContext, dragContext) => {
        return true
      },
      canDrag: (item) => {
        return true
      },
      onDragEnter: (item, event) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item, event) => {
        return
      },
      onDrop: (item, event) => {
        if (this.draggedItem) {
          this.insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        this.draggedItem = item;
        this.draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        this.draggedItem = undefined;
        this.draggedIndex = -1;
      },
    };
  }

  insertBeforeItem = async(item) => {
    const draggedItems = this.selection.isIndexSelected(this.draggedIndex)
      ? (this.selection.getSelection()) : [this.draggedItem]

    const insertIndex = this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).indexOf(item)
    const items = this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).filter(itm => draggedItems.indexOf(itm) === -1)
    
    let itemBefore
    let itemAfter
    if (insertIndex > 0) {
      itemBefore = this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant)[insertIndex-1]
    }
    if (this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).length > insertIndex) {
      itemAfter = this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant)[insertIndex]
    }
    console.log('itemBefore', itemBefore)
    console.log('itemAfter', itemAfter)
    // update prio and subprio fields
    if (itemBefore === undefined || itemBefore.prio === itemAfter.prio) {// moved on first position or between same prio
      items.splice(insertIndex, 0, ...draggedItems)
      this.brandsStore.allPrioritiesItemsByMandant.set(this.state.selectedMandant, items)
      
      this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant)[insertIndex].prio = itemAfter.prio  
      let modifiedItems = this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).filter(item => item.prio === itemAfter.prio)
      const sort = modifiedItems.map(item => Number(item.subprio))
      const minsort = sort.length > 0 ? Math.min(...sort) : 1
      let index = -1
      modifiedItems.map(item => { 
        index++
        return item.subprio = (minsort+index).toString()
      })
      const all = [...modifiedItems, ...this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).filter(item => item.prio !== itemAfter.prio)]
      this.brandsStore.allPrioritiesItemsByMandant.set(this.state.selectedMandant, all.slice().sort((p1,p2) => p1.prio !== p2.prio ? p1.prio - p2.prio : p1.subprio - p2.subprio))
      this.updatePriorities()
    } else {//diferent prio
      console.log(insertIndex + 1, this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).length)
      this.setState({
        item,
        itemBefore,
        itemAfter,
        prioOptions: [
          { key: 'B', text: `Add to priority ${itemBefore.prio}` },
          { key: 'A', text: `Add to priority ${itemAfter.prio}` },
          { key: 'N', text: 'Create a new main priority group' }],
        draggedItems,
        insertIndex,
        items,
        showPrioDialog: true
      })
    }
  }

  handleChoiceGroup = (event, option) => {
    console.log(option)
    this.setState({selectedPrioOptio: option.key})
  }

  handlePrio = () => {
    const { selectedMandant, itemBefore, itemAfter, draggedItems, insertIndex, items, selectedPrioOptio} = this.state
    if (selectedPrioOptio !== undefined) {
      items.splice(insertIndex, 0, ...draggedItems)
      this.brandsStore.allPrioritiesItemsByMandant.set(selectedMandant, items)
      let all = []
      if (selectedPrioOptio === 'B') { // choose to insert with the priority before
        this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant)[insertIndex].prio = itemBefore.prio
        this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant)[insertIndex].subprio = (Number(itemBefore.subprio)+1).toString()

        all = this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant)
      } else if (selectedPrioOptio === 'A') {  // choose to insert with the priority after
        this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant)[insertIndex].prio = itemAfter.prio
        this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant)[insertIndex].subprio = "0"
        
        const modifiedItems = this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant).filter(item => item.prio === itemAfter.prio)
        let index = 0
        modifiedItems.slice().sort((p1,p2) => p1.subprio - p2.subprio).map(item => { 
          index++
          return item.subprio = index.toString()
        })        

        all = [...modifiedItems, ...this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant).filter(item => item.prio !== itemAfter.prio)]
      } else { // choose to create a new main priority group
        const newMainGroupPrio = Number(itemAfter.prio)
        const draggedBrand = this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant)[insertIndex].brand_id

        this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant)[insertIndex].prio = newMainGroupPrio.toString()
        this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant)[insertIndex].subprio = "1"

        const modifiedItems = this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant).filter(item =>  Number(item.prio) >= newMainGroupPrio && item.brand_id !== draggedBrand)
        modifiedItems.map(item => { 
          return item.prio = (Number(item.prio) + 1).toString()
        })
        all = [...modifiedItems, this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant).find(item => item.brand_id === draggedBrand), ...this.brandsStore.allPrioritiesItemsByMandant.get(selectedMandant).filter(item => Number(item.prio) < newMainGroupPrio)]
      }
      this.brandsStore.allPrioritiesItemsByMandant.set(selectedMandant, [...new Set(all)].slice().sort((p1,p2) => p1.prio !== p2.prio ? p1.prio - p2.prio : p1.subprio - p2.subprio))
      this.updatePriorities()
    }
    this.setState({showPrioDialog: false})
  }

  handleClosePrioDialog = () => {
    this.setState({showPrioDialog: false})
  }

  updatePriorities = () => {
    let currentPrio = this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant)[0].prio
    let prio = 1
    let subprio = 1
    const modifiedItems = this.brandsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).map(item => {
      if (item.prio !== currentPrio) {
        currentPrio = item.prio
        item.prio = (++prio).toString()
        subprio = 1
        item.subprio = (subprio++).toString()
      } else {
        item.prio = prio.toString()
        item.subprio = (subprio++).toString()
      }
      return item
    })
    this.brandsStore.allPrioritiesItemsByMandant.set(this.state.selectedMandant, modifiedItems)
  }

  render () {
    const { t, store: { brandsStore: { allPrioritiesItemsByMandant, showDialog, item, mandant_type, mandant },
      esObjectStore : { manufacturers, mapManufacturers } } }  = this.props
    const { hideDelDialog, showPrioDialog, delIndex, prioOptions } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    return (
      <React.Fragment>
        <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} styles={{root: {width: '100%', paddingLeft: 10}}}>
            <Label>{t('common:BRANDS')}</Label>
            <Stack horizontal tokens={stackTokens}>
              <Stack.Item styles={{root: {display: 'flex', width: '20%', height: '100%'}}}>
                <IconButton
                  iconProps={addIcon}
                  title={t('common:BRAND_ADD')}
                  onClick={() => this.showInsertDialog()}
                />
              </Stack.Item>
              <Stack.Item styles={{root: {display: 'flex', width: '80%', height: '100%'}}}>
                <Dropdown id='mandantType'
                    label={t('common:TYPE')}
                    defaultSelectedKey={mandant_type[0].key}
                    disabled={data.mandant.brand[data.mandant_type.brand[1]][this.brandPriorityStore.selectedESObject.shop_name].length < 1}
                    options={mandant_type}
                    onChange={(event, { key }) => {
                      this.brandsStore.populateMandants(key)
                      this.setState({selectedMandant: data.mandant.brand[key][this.brandPriorityStore.selectedESObject.shop_name][0]})
                    }}
                    styles={{dropdown: { width: 130, height: 20, float: 'right'},
                        caretDownWrapper: { height:20, lineHeight: 'normal' },
                        dropdownItem: 'dropdownItem',
                        dropdownItemSelected: 'dropdownItem',
                        root: { paddingLeft: 10} 
                    }}
                />
                <Dropdown id='mandant'
                    label={t('common:MANDANT')}
                    defaultSelectedKey={mandant.length > 0 ? mandant[0].key : ''}
                    options={mandant}
                    onChange={(event, { key }) => {
                      // populate priorities by mandant
                      this.setState({selectedMandant: key})
                    }}
                    styles={{dropdown: { width: 130, height: 20, float: 'right' },
                        caretDownWrapper: { height:20, lineHeight: 'normal' },
                        dropdownItem: 'dropdownItem',
                        dropdownItemSelected: 'dropdownItem',
                        root: { paddingLeft: 10}  
                    }}
                />
              </Stack.Item>
            </Stack>
            <div style={{ position: "relative", height: 450 }} id='brandsListItem'>    
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                <DetailsList id='brandsDetailsList'
                  items={allPrioritiesItemsByMandant.get(this.state.selectedMandant) !== undefined ? allPrioritiesItemsByMandant.get(this.state.selectedMandant) : []} 
                  setKey='set'
                  columns={this.setHeaderColumns()}
                  layoutMode={DetailsListLayoutMode.justified}
                  selection={this.selection}
                  selectionMode={SelectionMode.none}
                  enterModalSelectionOnTouch={true}
                  selectionPreservedOnEmptyClick={true}
                  constrainMode={ConstrainMode.unconstrained}
                  onRenderItemColumn={this.handleItemColumn}
                  dragDropEvents={this.dragDropEvents}
                />
              </ScrollablePane>   
            </div>
          </Stack>
        { showDialog && <Dialog
          minWidth={700}
          maxWidth={900}
          hidden={!showDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: item !== undefined && item.isEdit === true ? t('common:BRAND_EDIT') : t('common:BRAND_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
        <Stack verticall tokens={stackTokens}>
          <Stack.Item verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:BRAND')}</Label>
              <LookupDropdown
                placeholder={t('common:BRAND')}
                defaultValue={item !== undefined ? item.brand_id : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={manufacturers}
                onChange={(e, { value }) => {
                    item.brand_id = value
                    item.brandName = value + '-' + mapManufacturers.get(value)
                }}
              />
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{root: {width: '100%', height: '100%'}}}>
            { Object.keys(data.mandant.brand).map((key, index) => (
              <Stack key={index+"_"+key} verticall tokens={stackTokens}>
                <Label> {t('common:MANDANT')} {key} </Label> 
                <Stack horizontal tokens={stackTokens} style={{columnCount: 3, display: 'block'}}>
                  {data.mandant.brand[key][this.brandPriorityStore.selectedESObject.shop_name].map((item, index) => (
                    <div key={index+"_"+item} style={index !== 0 ? {padding:5} : {marginLeft: 5, padding: 5}}>
                      <Checkbox key={item} id={item} label={item} checked={this.brandsStore.checked.includes(item)} onChange={this.brandsStore.handleCheck} />
                    </div>
                  ))}
                </Stack>
              </Stack>
            ))}
            </Stack.Item>
        </Stack> 
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        { showPrioDialog && <Dialog
          minWidth={400}
          maxWidth={400}
          hidden={!showPrioDialog}
          onDismiss={this.handleClosePrioDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:PRIO'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
        <Stack verticall tokens={stackTokens}>
          <ChoiceGroup options={prioOptions} onChange={this.handleChoiceGroup} required={true}/>
        </Stack> 
          <DialogFooter>
            <PrimaryButton onClick={this.handlePrio} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleClosePrioDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:BRAND_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:BRAND_DELETE_QESTION') + ' (' + (allPrioritiesItemsByMandant.get(this.state.selectedMandant) !== undefined && allPrioritiesItemsByMandant.get(this.state.selectedMandant)[delIndex] !== undefined ? allPrioritiesItemsByMandant.get(this.state.selectedMandant)[delIndex].brandName : '') + ')'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
      </React.Fragment> 
    )
  }
}

export { Brands }
export default withTranslation(['common'], { wait: true })(withMainContainer(Brands))