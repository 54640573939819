import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from '@fluentui/react/lib-commonjs/DetailsList'
import { TextField, Dropdown, ScrollablePane, ScrollbarVisibility, Label, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from './../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown as LookupDropdown} from 'semantic-ui-react'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const viewIcon = { iconName: 'LookupEntities' }

@inject('store')
@observer
class ConfigAm extends Component {
  constructor (props) {
    super(props)
    this.configAmStore = this.props.store.configAmStore
    this.esObjectStore = this.props.store.esObjectStore
    this.state = {
      hideDelDialog: true,
      hideInsertDialog: true
    }
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.configAmStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.configAmStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  componentDidMount() {
    this.configAmStore.items = []
    if (this.esObjectStore.mapShops.size === 0 && this.esObjectStore.shops.length === 0) {
        this.esObjectStore.fetchShops()
    }
  }

  loadData = () => {
    if (!this.state.env) {
        alert('Select an environment!')
        return
    }
    if (!this.state.shop_id) {
        alert('Select a shop!')
        return
    }
    this.setState({
        loadingData: true
    })
    this.configAmStore.items = []
    const shop =  [...this.esObjectStore.mapShops.values()].find(el => el.id === this.state.shop_id)
    if (shop !== undefined) {
      this.setState({
        shop_name: shop.shop_name
      })
      this.esObjectStore.initialiseData(shop, this.props.store.appStateStore.get_gui_language())
      this.esObjectStore.fetchGenartsByLang(this.esObjectStore.defaultLang, this.state.env).
        then(() => this.configAmStore.fetchGenartsAm(this.state.shop_id).then(() => {
            this.setState({
                loadingData: false
            })
            if (!this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang)) {
                alert('Select the right environment for the shop!')
            }
        }
      ))
    }
  }

  handleInsert = () => {
    this.configAmStore.insertGenartAm(this.state.item)
    this.setState({
        hideInsertDialog: true,
        item: undefined
    })
  }

  showInsertDialog = item => {
    if (item === undefined) {
      item = {
        shop_id: this.state.shop_id,
        shop_name: this.state.shop_name
      }
    }
    this.setState({
      hideInsertDialog: false,
      item: item
    })
  }

  handleCloseInsertDialog = () => {
    this.setState({ hideInsertDialog: true })
    this.configAmStore.fetchGenartsAm(this.state.shop_id)
  }
  
  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.configAmStore.removeGenartAm(this.state.delIndex)
    this.setState({
      hideDelDialog: true,
      delIndex: -1
    })
  }

  handleItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:GENART_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  render () {
    const { t, store: { configAmStore: { items }, esObjectStore : {envs, shops, genarts, mapGenarts } }} = this.props
    const { hideInsertDialog, hideDelDialog, item, delIndex, loadingData, shop_id } = this.state
    const stackTokens = {
      childrenGap: 5,
      padding: 5
    }
    const containerStyle = {
      root: {
        width: '100%',
        height: '100%'
      }
    }
    const growingStyles = {
        root: {
            display: 'flex',
            height: '50%'
        }
    }
    const textFieldsStyles = {
      fieldGroup: { width: 250, height: 20, float: 'right' }
    }
    return (
      <div className='page-container'>
        <Stack verticall tokens={stackTokens} styles={containerStyle}>
        <h1>{t('common:ARTICLES_GENARTS') + ' ' + t('common:CATEGORY_GENART_AM')}</h1>
        <Stack horizontal tokens={stackTokens} styles={{ root: { width: '70vw'} }}>
            <Dropdown id='env'
                placeholder={t('common:UNITREE_DROPDOWN_ENV_PLACEHOLDER')}
                label={t('common:UNITREE_ENV')}
                defaultSelectedKey={''}
                options={envs}
                onChange={(event, { key }) => {
                    this.configAmStore.items = []
                    this.setState({
                        env: key
                    })
                }}
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
            <Dropdown id='shop_name'
                placeholder={t('common:UNITREE_DROPDOWN_SHOP_PLACEHOLDER')}
                label={t('common:UNITREE_SHOP_NAME')}
                defaultSelectedKey={''}
                options={shops}
                onChange={(event, { key, text }) => {
                    this.configAmStore.items = []
                    this.setState({
                        shop_id: key
                    })
                }}
                styles={{dropdown: { width: 200, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
             <PrimaryButton onClick={() => this.loadData()} text={t('common:LOAD_DATA')} />
             {loadingData && <Spinner size={SpinnerSize.large} /> } 
          </Stack>
            <div id='shopsDetailsList'>
              <IconButton
                iconProps={addIcon}
                title={t('common:GENART_ADD')} disabled={!mapGenarts.get(this.esObjectStore.defaultLang) || !shop_id}
                onClick={() => this.showInsertDialog()}
              />
              <DetailsList ariaLabelForGrid='Shops'
                items={items} 
                setKey='set'
                columns={this.setHeaderColumns()}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.single}
                selection={this.selection}
                enterModalSelectionOnTouch={true}
                selectionPreservedOnEmptyClick={true}
                constrainMode={ConstrainMode.unconstrained}
                onRenderItemColumn={this.handleItemColumn}
            />
        </div>
        </Stack>
        {hideInsertDialog === false && <Dialog
          minWidth={400}
          maxWidth={800}
          hidden={hideInsertDialog}
          onDismiss={this.handleCloseInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:GENART_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
          <Label>{t('common:UNITREE_SHOP_NAME')}: {item.shop_name}</Label>
          <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:NODE_FILTER_GAID')}</Label>
              <LookupDropdown
                placeholder={t('common:NODE_FILTER_GAID')}
                defaultValue={''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={genarts}
                onChange={(e, { value }) => {
                    item.id_genart = value
                    item.gatxt = value + '-' + mapGenarts.get(this.esObjectStore.defaultLang).get(value)
                }}
              />
            </Stack>
            
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleInsert} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {hideDelDialog === false && <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:GENART_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:GENART_DELETE_QESTION') + (delIndex !== undefined && items[delIndex] !== undefined ? items[delIndex].id_genart : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
      </div>             
    )
  }
}

export { ConfigAm }
export default withTranslation(['common'], { wait: true })(withMainContainer(ConfigAm))