// import libs
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from '@fluentui/react';
import { withTranslation } from 'react-i18next'

// import components
import withMainContainer from './Extentions/withMainContainer'

// import styles
import './Welcome.scss'

@inject('store')
@observer
class ChooseApp extends Component {
  constructor (props) {
    super(props)
    this.authStore = this.props.store.authStore
  }

  handleClick = (right, app) => {
    if (!this.authStore.hasUserRight(right)) {
      alert(`You are not allowed to use the ${right} ! Contact your administrator !`)
      return
    }
    this.props.store.authStore.selectedApp = app
    this.authStore.loginApp()
  }

  handleClickOnUnitreeLink = (ev) => {
    this.handleClick('Unitree Builder', 'unitree')
  }

  handleClickOnDashboardLink = (ev) => {
    this.handleClick('Connect Dashboard Tool', 'dashboard')
  }

  handleClickOnCategoryLink = (ev) => {
    this.handleClick('Category Builder', 'categories')
  }

  handleClickOnBrandPriorityLink = (ev) => {
    this.handleClick('Brand Priority Builder', 'brand_priority')
  }

  render () {
    const { t } = this.props
    const APP_NAME = process.env.REACT_APP_NAME
    return (
      <div className='welcome-container'>
        <div className='welcome-wrapper'>
          <h1>{t('common:WELCOME_HEADER')}{' '}{APP_NAME}</h1>
          <h5><Link onClick={this.handleClickOnUnitreeLink} underline> Unitree Builder </Link> to build the Uniparts tree for Connect.</h5>
          <h5><Link onClick={this.handleClickOnCategoryLink} underline> Categories Builder </Link> to build the Categories tree for Connect.</h5>  
          <h5><Link onClick={this.handleClickOnBrandPriorityLink} underline> Brand Priority Builder </Link> to build the Brand Priorities for Connect.</h5>  
          <h5><Link onClick={this.handleClickOnDashboardLink} underline> Connect Dashboard Tool </Link> to get an overview of the data from Servo and in the ES indexes and view single record details.</h5>  
        </div>
      </div>
    )
  }
}

export { ChooseApp }
export default withTranslation(['common'], { wait: true })(withMainContainer(ChooseApp))