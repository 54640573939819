import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import withMainContainer from '../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Stack, IconButton, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react'
import { ControlledTreeEnvironment, Tree } from 'react-complex-tree';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import 'react-complex-tree/lib/style-modern.css';
import DialogNode from '../Extentions/unitree/nodes/DialogNode'
const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const syncIcon = {iconName: 'Sync'}

@inject('store')
@observer
class Nodes extends Component {
  
  constructor (props) {
    super(props)
    this.nodeStore = this.props.store.nodeStore
    this.dialogNodeStore = this.props.store.dialogNodeStore
    this.esObjectStore = this.props.store.esObjectStore
    this.unitreeStore = this.props.store.unitreeStore
    this.articlesStore = this.props.store.articlesStore
    this.unitreeStore = this.props.store.unitreeStore
    this.state = {}
  }

  showEditDialog = (item, parentId) => {
    let node = item !== undefined ? item.data : undefined
    console.log(node)
    if (node === undefined) {
      if ((this.unitreeStore.leafIds.length === 0 || this.unitreeStore.nodesOptions.length === 0) && this.nodeStore.nodes.length > 0) {
        alert('Connection Problem ! Try again !')
        return
      }
      const maxLeafId = this.unitreeStore.leafIds.length > 0 ? Math.max(...this.unitreeStore.leafIds) + 1 : 10001
      const ids = this.unitreeStore.nodesOptions.map(opt => Number(opt.key))
      const maxId = ids.length > 0 ? ((Math.max(...ids)) + 1) : 1000000
      // create root node
      if (this.nodeStore.nodes.length === 0) {
        this.nodeStore.rootNode = {
          node_external_type: null,
          filters: [],
          sort: '00-00-00-00-00',
          node_keywords: '',
          parent_id: '10000',
          leaf_id: maxLeafId.toString(),
          id: maxId.toString(),
          node_name: '',
          node_external_service_attribute: null,
          open: 0,
          include:  {
            id_dlnr: '',
            artids: '',
            genarts: '',
            criteria: [],
            artgrp: ''
          },
          exclude:  {
            id_dlnr: '',
            artids: '',
            genarts: '',
            criteria: [],
            artgrp: ''
          }
        }
        this.unitreeStore.leafIds.push(Number(this.nodeStore.rootNode.leaf_id))
      }
      node = {
        parent_id: parentId !== undefined ? parentId : (this.nodeStore.nodes.length > 0 ? this.nodeStore.nodes[0].leaf_id : this.nodeStore.rootNode.leaf_id),
        leaf_id: this.nodeStore.nodes.length > 0 ? maxLeafId.toString() : (maxLeafId + 1).toString(),
        id: this.nodeStore.nodes.length > 0 ? maxId.toString() : (maxId + 1).toString(),
        node_name_trans: {},
        open: 0,
        node_external_type: '',
        node_external_service_attribute_trans: {},
        node_keywords_trans: {},
        filters: [],
        isEdit: false,
        include:  {
          id_dlnr: '',
          artids: '',
          genarts: '',
          criteria: [],
          artgrp: ''
        },
        exclude:  {
          id_dlnr: '',
          artids: '',
          genarts: '',
          criteria: [],
          artgrp: ''
        }
      }
      this.unitreeStore.leafIds.push(Number(node.leaf_id))
    } else {
      node.isEdit = true
      this.nodeStore.editIndex = item.index
      this.nodeStore.origEditNode = JSON.stringify(item)
    }
    if (node.node_name_trans === undefined) {
      node.node_name_trans = {}
    }
    if (node.node_external_service_attribute_trans === undefined) {
      node.node_external_service_attribute_trans = {}
    }
    if (node.node_keywords_trans === undefined) {
      node.node_keywords_trans = {}
    }
    // populate filtersItems in detailList
    this.dialogNodeStore.filtersItems = []
    node.filters.forEach(filter => {
      this.dialogNodeStore.filtersItems.push({gaid: filter.gaid, gatxt: this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(filter.gaid), filter:filter.filter})
    })
    // populate articleCriteria
    this.articlesStore.includeCriteria = []
    node.include.criteria.forEach(criteria => this.articlesStore.includeCriteria.push(criteria))
    this.articlesStore.excludeCriteria = []
    node.exclude.criteria.forEach(criteria => this.articlesStore.excludeCriteria.push(criteria))
    this.nodeStore.handleHideDialog(node)
  }

  showDelDialog = item => {
    this.setState({
      hideDelDialog: false,
      delNode: item
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.nodeStore.removeNode(this.state.delNode.data).then(()=> this.props.store.treeStore.updateLastDateAndUserOnTree(this.nodeStore.nodeDB.tree_id))
    this.setState({
      hideDelDialog: true,
      delNode: undefined
    })
  }

  getNodeName = (node) => {
    return node.node_name_trans !== undefined ? (node.node_name_trans[this.esObjectStore.defaultLang]) : node.node_name
  }

  onDrop = (item, target) => {
      const parent = Object.values(this.nodeStore.items).find(potentialParent =>
        potentialParent.children?.includes(item.index)
      )
      if (!parent || !parent.children) {
        return
      }
      if (target.targetType === 'item' || target.targetType === 'root') {
        if (target.targetItem === parent.index) {
          // NOOP
        } else {
          this.nodeStore.items[parent.index].children = parent.children.filter(child => child !== item.index)
          this.nodeStore.items[target.targetItem].children = [...(this.nodeStore.items[target.targetItem].children ?? []), item.index]
          this.nodeStore.items[target.targetItem].isFolder=true
          const indexItem = this.nodeStore.nodes.findIndex(node => node.leaf_id === item.index)
          if (indexItem !== -1) {
            this.nodeStore.nodes[indexItem].parent_id = target.targetItem === 'root' ? this.nodeStore.nodes[0].leaf_id : target.targetItem
          }
        }
      } else {
        const newParent = this.nodeStore.items[target.parentItem];
        const newParentChildren = [...(newParent.children ?? [])].filter(
          child => child !== item.index
        )
        if (target.parentItem === item.index) {
          // Trying to drop inside itself
          return
        }
        if (target.parentItem === parent.index) {
          const isOldItemPriorToNewItem =
            ((newParent.children ?? []).findIndex(
              child => child === item.index
            ) ?? Infinity) < target.childIndex;
          newParentChildren.splice(
            target.childIndex - (isOldItemPriorToNewItem ? 1 : 0),
            0,
            item.index
          );
          this.nodeStore.items[target.parentItem].children = newParentChildren
        } else {
          newParentChildren.splice(target.childIndex, 0, item.index);
          this.nodeStore.items[parent.index].children = parent.children.filter(child => child !== item.index)
          this.nodeStore.items[target.parentItem].children = newParentChildren
          this.nodeStore.items[target.parentItem].isFolder=true
           const indexItem = this.nodeStore.nodes.findIndex(node => node.leaf_id === item.index)
          if (indexItem !== -1) {
            this.nodeStore.nodes[indexItem].parent_id = target.parentItem === 'root' ? this.nodeStore.nodes[0].leaf_id : target.parentItem
          }
        }
      }
  }

  render () {
    const { t, store: { nodeStore: { items, hideDialog, loadingNodes, loadingOnSave } } } = this.props
    const { delNode, hideDelDialog } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const growingStyles = {
      root: {
        display: 'flex',
        height: '50%'
      }
    }
    return (
      <div>
      {loadingNodes && <Spinner size={SpinnerSize.large} /> }
      {loadingOnSave && <Spinner size={SpinnerSize.large} /> }
      {hideDialog === false && <DialogNode />}
      <Stack horizontal tokens={stackTokens}>
        <Stack.Item styles={growingStyles}>
        <Stack verticall tokens={stackTokens}>
          <Stack horizontal tokens={stackTokens}>
            <IconButton
              iconProps={addIcon}
              title={t('common:NODE_ADD')}
              onClick={() => this.showEditDialog()}
            />
            <IconButton
              iconProps={syncIcon}
              title={t('common:TREE_REBUILD')}
              onClick={() => this.nodeStore.saveNodes('Rebuild tree').then(()=> this.props.store.treeStore.updateLastDateAndUserOnTree(this.nodeStore.nodeDB.tree_id))}
            />
          </Stack>
          <ControlledTreeEnvironment
            canDragAndDrop={true}
            canDropOnFolder={true}
            canDropOnNonFolder={true}
            canReorderItems={true}
            canSearch={true}
            items={items}
            getItemTitle={item => 
              this.getNodeName(item.data)
            }
            viewState={{
              'tree-1': {
                focusedItem: this.nodeStore.focusedItem,
                selectedItems: this.nodeStore.selectedItems,
                expandedItems: this.nodeStore.expandedItems
              },
            }}
            onSelectItems={(items) => {    
              this.nodeStore.selectedItems = items
            }} 
            onDrop={(itemsToMove, target) => {
              itemsToMove.length > 0 && this.onDrop(itemsToMove[0], target)
            }}
            onFocusItem={item => 
              this.nodeStore.focusedItem = item.index
            }
            onExpandItem={item => {
              this.nodeStore.expandedItems = [...this.nodeStore.expandedItems, item.index]
            }
            }
            onCollapseItem={item =>
              this.nodeStore.expandedItems = this.nodeStore.expandedItems.filter(expandedItemIndex => expandedItemIndex !== item.index) 
            }
            renderItem={({ item, depth, children, title, context, arrow }) => {
              const InteractiveComponent = context.isRenaming ? 'div' : 'button';
              const type = context.isRenaming ? undefined : 'button';
              return (
                <li
                  {...(context.itemContainerWithChildrenProps)}
                  className="rct-tree-item-li"
                >
                  <div
                    {...(context.itemContainerWithoutChildrenProps)}
                    style={{ paddingLeft: `${(depth + 1) * 12}px` }}
                    className={[
                      'rct-tree-item-title-container',
                      item.isFolder && 'rct-tree-item-title-container-isFolder',
                      context.isSelected && 'rct-tree-item-title-container-selected',
                      context.isExpanded && 'rct-tree-item-title-container-expanded',
                      context.isFocused && 'rct-tree-item-title-container-focused',
                      context.isDraggingOver &&
                        'rct-tree-item-title-container-dragging-over',
                      context.isSearchMatching &&
                        'rct-tree-item-title-container-search-match',
                    ].join(' ')}
                  >
                    {arrow}
                    <InteractiveComponent
                      type={type}
                      {...(context.interactiveElementProps)}
                      className="rct-tree-item-button"
                    >
                      {title} 
                    </InteractiveComponent>
                    <IconButton iconProps={addIcon} title={t('common:NODE_ADD')} onClick={() => this.showEditDialog(undefined, item != undefined ? item.data.leaf_id.toString() : undefined)} />
                    <IconButton iconProps={editIcon} title={t('common:NODE_EDIT')} onClick={() => this.showEditDialog(item)} />
                    <IconButton iconProps={delIcon} title={t('common:NODE_DELETE')} onClick={() => this.showDelDialog(item)} />
                  </div>
                  {children}
                </li>
              );
            }}
          >
            <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" />
          </ControlledTreeEnvironment>
        </Stack>
      </Stack.Item>  
    </Stack>
    <Dialog
      hidden={hideDelDialog}
      onDismiss={this.handleCloseDelDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: t('common:NODE_DELETE'),
        closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
        subText: t('common:NODE_DELETE_QESTION') + (delNode !== undefined ? this.getNodeName(delNode.data) : '') + '?'
      }}
      modalProps={{
        isBlocking: true,
        dragOptions: true,
        styles: { main: { maxWidth: 450 } }
      }}
    >
      <DialogFooter>
        <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
        <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
      </DialogFooter>
    </Dialog> 
    </div>
    )
  }
}

export { Nodes }
export default withTranslation(['common'], { wait: true })(withMainContainer(Nodes))