
import { observable } from 'mobx'
import config from '../../config/config'
const data = require('../../config/data.json')
class ConfigCupisStore {
  @observable items = []
  @observable columns = []  
  @observable editItem
  @observable showDialog = false
  @observable filterText
  allItems = []
  constHeader = [
    { key: 'gatxt', text : 'ARTICLES_GENARTS' },
    { key: 'cupi', text : 'CUPI' },
    { key: 'vloc', text : 'LOC' },
    { key: 'hloc', text : 'LOC' },
    { key: 'zone', text : 'ZONE' },
    { key: 'buttons', text: '' }
  ]

  vloc = [{ key: '', text: '' }]
  hloc = [{ key: '', text: '' }]
  constructor (authStore, esObjectStore) {
    this.authStore = authStore
    this.esObjectStore = esObjectStore 
    data.vloc.forEach(vl => this.vloc.push({key: vl, text: vl}))
    data.hloc.forEach(hl => this.hloc.push({key: hl, text: hl}))
    this.constHeader.forEach(({key}) => this.columns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: key === 'gatxt' ? '20%' : '60%',
      minWidth: key === 'gatxt' ? 300 : 120,
      maxWidth: key === 'gatxt' ? 400 : 200,
    })
    )
  }

  /**
   * Hide / Show dialog
   */
  handleShowDialog = (item) => {
    this.showDialog = !this.showDialog
    this.editItem = item
  }
  /**
   * Load Cupis from the DB
   */
  fetchGenartsCupis = async () => {
    this.filterText = ''
    const result = await fetch(`${config.server_address}/fe/categories/genart/cupis`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang) !== undefined) {
        this.items = data.message.map(item => ({...item, gatxt: item.id_genart + '-' + this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(item.id_genart) }))
    }
    this.allItems = this.items
  }

  /**
   * Remove a Cupi from the DB
   */
  removeCupi = async (key) => {
    // remove from DB 
    const item = this.items[key]
    const result = await fetch(`${config.server_address}/fe/categories/cupis/delete/${item.id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      alert(`Cupi ${item.cupi} successfully deleted !`)
      await this.fetchGenartsCupis()
    }
  }
  /**
   * Save a Cupi in the DB
   */
  saveCupi = async (item) => {
    // edit in DB 
    const result = await fetch(`${config.server_address}/fe/categories/cupis/${item.id === undefined ? 'insert' : 'edit'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: item.id,
        id_genart: item.id_genart,
        cupi: item.cupi,
        zone: item.zone,
        vloc: item.vloc,
        hloc: item.hloc
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      this.handleShowDialog()
      alert(`Cupi ${item.cupi} for genart ${item.id_genart} successfully saved!`)
      await this.fetchGenartsCupis()
    }
  }
}
export default ConfigCupisStore
