import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Label, Stack, PrimaryButton, DefaultButton, TextField, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from '../../withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'

@inject('store')
@observer
class DialogNodeFilter extends Component {
  constructor (props) {
    super(props)
    this.dialogNodeStore = this.props.store.dialogNodeStore
    this.dialogNodeFilterStore = this.props.store.dialogNodeFilterStore
  }

  handleCloseFilterEditDialog = () => {
    this.dialogNodeFilterStore.handleEditFilterDialog()
    const { origFilter, editFilterIndex } = this.dialogNodeFilterStore
    if (editFilterIndex !== undefined && this.dialogNodeStore.critFiltersItems[editFilterIndex] !== undefined) {
      this.dialogNodeStore.critFiltersItems[editFilterIndex] = JSON.parse(origFilter)
    }
    this.setState({
      editFilterIndex: undefined,
      origFilter: '',
    })
  }

  handleFilterChange = (key, value) => {
    const editFilterItem = this.dialogNodeFilterStore.editFilterItem
    if (editFilterItem !== undefined) {
      editFilterItem[key] = value
    }
  }

  handleFilterEdit = () => {
    if (!this.dialogNodeFilterStore.editFilterItem.filter_caid) {
      alert(this.props.t('common:MSG_SELECT_CRITERIA'))
      return
    }
    this.dialogNodeFilterStore.editFilter()
    this.dialogNodeFilterStore.handleEditFilterDialog()
  }

  render () {
    const { t, store: { dialogNodeFilterStore: { editFilterItem, hideEditFilterDialog },
      esObjectStore : { criterias, mapCriterias } } }  = this.props
      const stackTokens = {
          childrenGap: 5
        }
        const textFieldsStyles = {
            fieldGroup: { width: 230, height: 20, float: 'right' }
        }  
    return (
        <div>
          <Dialog
            minWidth={400}
            maxWidth={600}
            hidden={hideEditFilterDialog}
            onDismiss={this.handleCloseFilterEditDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: editFilterItem !== undefined && editFilterItem.isEdit === true ? t('common:FILTER_EDIT') : t('common:FILTER_ADD'),
              closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            }}
            modalProps={{
              isBlocking: true, dragOptions: true
            }}
          >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:NODE_FILTER_CAID')}</Label>
              <Dropdown
                placeholder={t('common:NODE_FILTER_CAID')}
                defaultValue={editFilterItem !== undefined ? editFilterItem.filter_caid : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={criterias}
                onChange={(e, { value }) => {
                  this.handleFilterChange('filter_caid', value)
                  this.handleFilterChange('filter_catxt', mapCriterias.get(value))
                }}
              />
            </Stack>
            <Checkbox label={t('common:NODE_FILTER_OPEN')} defaultChecked={editFilterItem !== undefined && editFilterItem.filter_open === 1 ? true : false} onChange={(event, newValue) => this.handleFilterChange('filter_open', newValue ? 1 : 0)} />
            <Checkbox label={t('common:NODE_FILTER_BAR')} defaultChecked={editFilterItem !== undefined && editFilterItem.filter_bar === "1" ? true : false} onChange={(event, newValue) => this.handleFilterChange('filter_bar', newValue ? "1" : null)} />
            <TextField label={t('common:NODE_FILTER_DEFAULT')} id='filter_default' defaultValue={editFilterItem !== undefined ? editFilterItem.filter_default : ''} 
              onChange={(event, newValue) => this.handleFilterChange('filter_default', newValue)} styles={textFieldsStyles}/>  
            <TextField label={t('common:NODE_FILTER_UOM')} id='filter_uom' defaultValue={editFilterItem !== undefined ? editFilterItem.filter_uom : ''} 
              onChange={(event, newValue) => this.handleFilterChange('filter_uom', newValue)} styles={textFieldsStyles}/> 
          </Stack>
            <DialogFooter>
              <PrimaryButton onClick={this.handleFilterEdit} text={t('common:BUTTON_SAVE')} />
              <DefaultButton onClick={this.handleCloseFilterEditDialog} text={t('common:BUTTON_CANCEL')} />
            </DialogFooter>
          </Dialog>
        </div>
    )
  }
}

export { DialogNodeFilter }
export default withTranslation(['common'], { wait: true })(withMainContainer(DialogNodeFilter))