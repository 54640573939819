
import { observable, action } from 'mobx'
import config from '../../config/config'

class CategoriesStore {
  @observable esObjects = []
  
  @observable editing = false

  @observable selectedESObject = null
  mapTextsTranslations = new Map()
  mapCriteriaTexts = new Map()
  mapCupis = new Map()
  genartAm = []
  loc_cupis = {}

  constructor (authStore, esObjectStore) {
    this.authStore = authStore 
    this.esObjectStore = esObjectStore
  }

  /**
   * Get loc_cupis config by language from backend common.js file
   */
  fetchLocCupis = async () => {
    const result = await fetch(`${config.server_address}/fe/categories/common/loc_cupis`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    this.loc_cupis = data.message
  }

  /**
   * Get the esObjectStore from DB
   */
  populateESObjects = async (reloadShops = false) => {
    this.esObjects = (await this.esObjectStore.populateESObjects('categories', reloadShops)).filter(item => this.authStore.hasUserRight(this.esObjectStore.mapShops.get(item.shop_id).shop_name) === true).map(item => ({...item, env_txt: this.esObjectStore.environments[item.env], shop_name: this.esObjectStore.mapShops.get(item.shop_id).shop_name }))
  }

  addTranslationToMap = (mapKey, lang, text) => {
    if (this.mapTextsTranslations.has(mapKey)) {
      this.mapTextsTranslations.get(mapKey)[lang] = text
    } else {
      this.mapTextsTranslations.set(mapKey, {[lang] : text})
    }
  }

  /**
   * Get array of criteria texts from DB based on the current language
   */
  @action
  fetchCriteriaTexts = async (lang) => {
    this.mapCriteriaTexts.clear()
    const result = await fetch(`${config.server_address}/fe/categories/criteriaTexts/${lang}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success) {
      data.message.map(criteria => { 
        if (!this.mapCriteriaTexts.has(criteria.type)) {
          this.mapCriteriaTexts.set(criteria.type, [])
        }
        this.mapCriteriaTexts.get(criteria.type).push(criteria.text)
      })
    }
  }

  /**
   * Get array of cupis from DB based on the current language
   */
  @action
  fetchCupis = async (lang) => {
    await this.fetchLocCupis()
    this.mapCupis.clear()
    const result = await fetch(`${config.server_address}/fe/categories/genart/cupis`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success) {
      data.message.map(cupi => { 
        cupi.loc = ((cupi.vloc ? this.loc_cupis[lang][cupi.vloc] : '') + ' ' +  (cupi.hloc ? this.loc_cupis[lang][cupi.hloc] : '')).trim() 
        if (!this.mapCupis.has(cupi.id_genart)) {
          this.mapCupis.set(cupi.id_genart, [])
        }
        this.mapCupis.get(cupi.id_genart).push(cupi)
      })
      // remove cupi duplicates and keep the last and order by cupi
      for (let [gaid, cupis] of this.mapCupis.entries()) {
        const cupisNoDuplicates = cupis.filter((value, index, self) => index === self.findLastIndex(t => t.cupi === value.cupi)).
          sort((cupi1, cupi2) => cupi1.cupi.localeCompare(cupi2.cupi))
        this.mapCupis.set(gaid, cupisNoDuplicates)
      }
    }
  }

  /**
   * Get array of genartAm from DB based on the current shop
   */
  @action
  fetchGenartAm = async (shop_id) => {
    this.genartAm = []
    const result = await fetch(`${config.server_address}/fe/categories/genartAm/${shop_id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (data.success) {
      this.genartAm = data.message.map(am => am.id_genart)
    }
  }
 /**
   * Save categoryText in the DB
   */
  editText = async (categoryId, key, lang, text) => {
    const result = await fetch(`${config.server_address}/fe/categoryTexts/save`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        es_object_id: this.selectedESObject.id,
        category_id: categoryId,
        text_key: key,
        language_iso: lang,
        text: text
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
  }
  /**
   * Delete categoryText from the DB
   */
  removeText = async (categoryId, key) => {
    const result = await fetch(`${config.server_address}/fe/categoryTexts/delete`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        es_object_id: this.selectedESObject.id,
        category_id: categoryId,
        text_key: key
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
  }
}

export default CategoriesStore
