import { observable } from 'mobx'
import config from '../../config/config'

class TileStore {
  @observable tiles = []
  tileDB = {}
  @observable loadingTiles = false
  @observable columns = []
  @observable editTile = undefined
  @observable showDialog = false
  
  origEditTile = ''
  editIndex = undefined

  constHeader = [
    { key: 'tile_name_trans', text : 'TREE_NAME', width: '35%', maxWidth: 400 },
    { key: 'tile_type', text: 'TILE_TYPE', width: '15%', maxWidth: 100 },
    { key: 'tile_image', text: 'TREE_IMAGE', width: '10%', minWidth: 75, maxWidth: 75 },
    { key: 'tile_node', text : 'TILE_NODE', width: '30%', minWidth: 90, maxWidth: 400 },
    { key: 'buttons', text: '', width: '10%', maxWidth: 50 }
  ]

  tileTypes = [
    { key: 'node', text: 'node' }
  ]

  constructor (authStore, unitreeStore, nodeStore) {
    this.authStore = authStore 
    this.nodeStore = nodeStore
    this.unitreeStore = unitreeStore
    this.constHeader.forEach(({key, width, maxWidth}) => this.columns.push({
        key,
        fieldName: key,
        isResizable: true,
        width,
        minWidth: 50,
        maxWidth,
      })
    )
  }

  populateTiles = async (treeId) => {
    this.loadingTiles = true
    this.tiles = []
    const result = await fetch(`${config.server_address}/fe/tiles/${treeId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    this.tileDB = data.message !== undefined ? data.message : {}

    if (this.tileDB.tiles_source !== undefined) {
      this.tiles = JSON.parse(this.tileDB.tiles_source)
    } else {
      this.tileDB.tree_id = treeId
    }  
    // set translations
    this.tiles.forEach(tile => {
      tile.tile_name_trans = this.unitreeStore.mapTextsTranslations.get(`${treeId}@tile_name[${tile.tile_id}]`)
      const tileNode = this.unitreeStore.nodesOptions.find(option => option.value === tile.tile_node_id)
      tile.tile_node = tileNode !== undefined ? tileNode.text : ''
      
      tile.tile_external.forEach(tile_external => { 
        tile_external.tile_link_text_trans = this.unitreeStore.mapTextsTranslations.get(`${treeId}@tile_link_text[${tile_external.tile_link_id}]`)
        const node = this.unitreeStore.nodesOptions.find(option => option.value === tile_external.tile_link)
        tile_external.tile_link_str = node !== undefined ? node.text : ''
      })
    })
    this.loadingTiles = false
  }

  /**
   * Hide / Show dialog
   */
  handleShowDialog = (tile) => {
    this.showDialog = !this.showDialog
    this.editTile = tile
  }

  removeTranslationsFromTiles = () => {
    this.tiles.forEach(tile => {
      delete tile.isEdit
      delete tile.tile_name_trans
      delete tile.tile_node
      tile.tile_external.forEach(tile_external => {
        delete tile_external.isEdit
        delete tile_external.tile_link_text_trans
        delete tile_external.tile_link_str
      })
    })
  }

  saveTile = async() => {
    // save texts for tile in DB and in mapTranslations
    for (const [lang, text] of Object.entries(this.editTile.tile_name_trans)) {
      this.unitreeStore.addTranslationToMap(`${this.tileDB.tree_id}@tile_name[${this.editTile.tile_id}]`, lang, text)
      await this.unitreeStore.editText(this.tileDB.tree_id, `tile_name[${this.editTile.tile_id}]`, lang, text)
    }
    for (const external of this.editTile.tile_external) {
      for (const [lang, text] of Object.entries(external.tile_link_text_trans)) {
        this.unitreeStore.addTranslationToMap(`${this.tileDB.tree_id}@tile_link_text[${external.tile_link_id}]`, lang, text)
        await this.unitreeStore.editText(this.tileDB.tree_id, `tile_link_text[${external.tile_link_id}]`, lang, text)
      }
    }
    const isEdit = this.editTile.isEdit
    // add tile to tiles array
    if (!isEdit) {
      this.tiles.push(this.editTile)
    } else {
      const editIndex = this.tiles.findIndex(tile => tile.tile_id === this.editTile.tile_id)
      this.tiles[editIndex] = this.editTile
    }
    await this.saveTiles()
    this.handleShowDialog()
  }

  saveTiles = async() => {
    this.removeTranslationsFromTiles()
    this.tileDB.tiles_source =  JSON.parse(JSON.stringify(this.tiles))
    // save in DB 
    const result = await fetch(`${config.server_address}/fe/tiles/${this.tileDB.id === undefined ? 'insert' : 'edit'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        id: this.tileDB.id,
        tree_id: this.tileDB.tree_id,
        tiles_source: this.tileDB.tiles_source
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      // update date on tree
      const resultTreeUpdate = await fetch(`${config.server_address}/fe/tree/editLastUpdated`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`,
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          id: this.tileDB.tree_id,
          last_updated_date: new Date(),
          updated_by: this.authStore.current_user.DisplayName
        })
      })
      // token no longer valid => forward to welcome page
      if (resultTreeUpdate.status === 401) {
        this.authStore.clearUserSession(true)
        return
      }
      this.unitreeStore.selectedTreeLastUpdate = new Date()
      this.unitreeStore.selectedTreeLastUpdatedBy = this.authStore.current_user.DisplayName
      await this.populateTiles(this.tileDB.tree_id)
    }
  }

  deleteTexts = async(tile) => {
    this.unitreeStore.mapTextsTranslations.delete(`${this.tileDB.tree_id}@tile_name[${tile.tile_id}]`)
    await this.unitreeStore.removeText(this.tileDB.tree_id, `tile_name[${tile.tile_id}]`)
    
    for (const external of tile.tile_external) {
      this.unitreeStore.mapTextsTranslations.delete(`${this.tileDB.tree_id}@tile_link_text[${external.tile_link_id}]`)
      await this.unitreeStore.removeText(this.tileDB.tree_id, `tile_link_text[${external.tile_link_id}]`)
    }
  }

  removeTile = async(delIndex) => {
     // delete texts
     await this.deleteTexts(this.tiles[delIndex])
     // delete tile
     if (delIndex !== -1) {
       this.tiles.splice(delIndex, 1)
     }
     await this.saveTiles()
  }
} 

export default TileStore
