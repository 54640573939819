import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, TextField,
  Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from '../../withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const viewIcon = { iconName: 'LookupEntities' }

@inject('store')
@observer
class Exclude extends Component {
  constructor (props) {
    super(props)
    this.articlesStore = this.props.store.articlesStore
    this.nodeStore = this.props.store.nodeStore
    this.dialogNodeStore = this.props.store.dialogNodeStore
    this.state = {
        hideGenartDelDialog: true,
        hideSupplierDelDialog: true,
        hideArtGrpDelDialog: true,
        hideCriteriaDelDialog: true
    }
  }

  setCriteriaHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.articlesStore.criteriaColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.articlesStore.constCriteriaHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  handleCriteriaItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:CRITERIA_EDIT')}
            onClick={() => this.showCriteriaDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showCriteriaDelDialog(index)}
            iconProps={delIcon}
            title={t('common:CRITERIA_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  showCriteriaDialog = (item, index) => {
    if (item === undefined) {
      item = {
       cid: '', 
       cvp: '',
       isEdit: false
      }
    } else {
      item.isEdit = true
      this.setState({
        criteriaEditIndex: index,
        criteriaOrigCvp: item.cvp,
        criteriaOrigCid: item.cid
      })
    }
    this.articlesStore.handleCriteriaDialog('exclude', item)
  }

  setGenartHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.articlesStore.genartColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.articlesStore.constGenartHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }
  handleGenartsItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            onClick={() => this.showGenartDelDialog(index)}
            iconProps={delIcon}
            title={t('common:GENART_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }
  
  showGenartsDialog = () => {
    this.articlesStore.handleGenartsDialog('exclude', this.nodeStore.editNode.exclude.genarts)
  }

  handleCloseGenartsDialog = () => {
    this.articlesStore.handleGenartsDialog('exclude')
  }

  handleCloseGenartInsertDialog = () => {
    this.articlesStore.handleCloseGenartInsertDialog('exclude')
  }

  showGenartInsertDialog = () => {
    const item = {
      gaid: '', 
      gatxt: ''
    } 
    this.articlesStore.handleCloseGenartInsertDialog('exclude', item)
  }

  handleGenartInsert = () => {
    this.articlesStore.insertGenart('exclude')
    this.articlesStore.handleCloseGenartInsertDialog('exclude')
  }

  showGenartDelDialog = index => {
    this.setState({
      hideGenartDelDialog: false,
      genartDelIndex: index
    })
  }

  handleCloseGenartDelDialog = () => {
    this.setState({ hideGenartDelDialog: true })
  }

  handleDeleteGenart = () => {
    this.articlesStore.removeGenart('exclude', this.state.genartDelIndex)
    this.setState({
      hideGenartDelDialog: true,
      genartDelIndex: undefined
    })
  }

  handleSaveGenarts = () => {
    if (this.articlesStore.excludeGenarts.length > 0) {
      this.nodeStore.editNode.exclude.genarts = this.articlesStore.excludeGenarts.map(genart => genart.gaid).join(',')
    } else {
      this.nodeStore.editNode.exclude.genarts = ''
    }
    this.articlesStore.handleGenartsDialog('exclude')
  }
  // suppliers
  setSupplierHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.articlesStore.supplierColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.articlesStore.constSupplierHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }
  handleSuppliersItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            onClick={() => this.showSupplierDelDialog(index)}
            iconProps={delIcon}
            title={t('common:SUPPLIER_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }
  
  showSuppliersDialog = () => {
    this.articlesStore.handleSuppliersDialog('exclude', this.nodeStore.editNode.exclude.id_dlnr)
  }

  handleCloseSuppliersDialog = () => {
    this.articlesStore.handleSuppliersDialog('exclude')
  }

  handleCloseSupplierInsertDialog = () => {
    this.articlesStore.handleCloseSupplierInsertDialog('exclude')
  }

  showSupplierInsertDialog = () => {
    const item = {
       id: '', 
       text: ''
    } 
    this.articlesStore.handleCloseSupplierInsertDialog('exclude', item)
  }

  handleSupplierInsert = () => {
    this.articlesStore.insertSupplier('exclude')
    this.articlesStore.handleCloseSupplierInsertDialog('exclude')
  }

  showSupplierDelDialog = index => {
    this.setState({
      hideSupplierDelDialog: false,
      supplierDelIndex: index
    })
  }

  handleCloseSupplierDelDialog = () => {
    this.setState({ hideSupplierDelDialog: true })
  }

  handleDeleteSupplier = () => {
    this.articlesStore.removeSupplier('exclude', this.state.supplierDelIndex)
    this.setState({
      hideSupplierDelDialog: true,
      supplierDelIndex: undefined
    })
  }

  handleSaveSuppliers = () => {
    if (this.articlesStore.excludeSuppliers.length > 0) {
        this.nodeStore.editNode.exclude.id_dlnr = this.articlesStore.excludeSuppliers.map(supplier => supplier.id).join(',')
    } else {
      this.nodeStore.editNode.exclude.id_dlnr = ''
    }
    this.articlesStore.handleSuppliersDialog('exclude')
  }

  // article groups
  setArtGrpHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.articlesStore.artGrpColumns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.articlesStore.constArtGrpHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }
  handleArtGrpsItemColumn = (item, index, column) => {
    if (column.fieldName === 'buttons') {
      const { t } = this.props
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            onClick={() => this.showArtGrpDelDialog(index)}
            iconProps={delIcon}
            title={t('common:ARTGRP_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }
  
  showArtGrpsDialog = () => {
    this.articlesStore.handleArtGrpsDialog('exclude', this.nodeStore.editNode.exclude.artgrp)
  }

  handleCloseArtGrpsDialog = () => {
    this.articlesStore.handleArtGrpsDialog('exclude')
  }

  handleCloseArtGrpInsertDialog = () => {
    this.articlesStore.handleCloseArtGrpInsertDialog('exclude')
  }

  showArtGrpInsertDialog = () => {
    const item = {
       id: '', 
       text: ''
    } 
    this.articlesStore.handleCloseArtGrpInsertDialog('exclude', item)
  }

  handleArtGrpInsert = () => {
    this.articlesStore.insertArtGrp('exclude')
    this.articlesStore.handleCloseArtGrpInsertDialog('exclude')
  }

  showArtGrpDelDialog = index => {
    this.setState({
      hideArtGrpDelDialog: false,
      artGrpDelIndex: index
    })
  }

  handleCloseArtGrpDelDialog = () => {
    this.setState({ hideArtGrpDelDialog: true })
  }

  handleDeleteArtGrp = () => {
    this.articlesStore.removeArtGrp('exclude', this.state.artGrpDelIndex)
    this.setState({
      hideArtGrpDelDialog: true,
      artGrpDelIndex: undefined
    })
  }

  handleSaveArtGrps = () => {
    if (this.articlesStore.excludeArtGrps.length > 0) {
      this.nodeStore.editNode.exclude.artgrp = this.articlesStore.excludeArtGrps.map(artgrp => artgrp.id).join(',')
    } else {
      this.nodeStore.editNode.exclude.artgrp = ''
    } 
    this.articlesStore.handleArtGrpsDialog('exclude')
  }
  handleCloseCriteriaDialog = () => {
    this.articlesStore.handleCriteriaDialog('exclude')
    const { criteriaEditIndex, criteriaOrigCvp, criteriaOrigCid } = this.state
    if (criteriaEditIndex !== undefined) {
      this.nodeStore.editNode.exclude.criteria[criteriaEditIndex].cvp = criteriaOrigCvp
      this.nodeStore.editNode.exclude.criteria[criteriaEditIndex].cid = criteriaOrigCid
      this.setState({
        criteriaEditIndex: undefined,
        criteriaOrigCvp: '',
        criteriaOrigCid: ''
      })
    }
  }

  handleCriteriaChange = (key, value) => {
    if (this.articlesStore.criteriaItem !== undefined) {
        this.articlesStore.criteriaItem[key] = value
    }
  }
  
  handleCriteriaEdit = () => {
    if (!this.articlesStore.criteriaItem.cid || !this.articlesStore.criteriaItem.cvp) {
      alert(this.props.t('common:MSG_SELECT_CRITERIA'))
      return
    }
    this.articlesStore.editCriteria('exclude')
  }

  showCriteriaDelDialog = index => {
    this.setState({
      hideCriteriaDelDialog: false,
      criteriaDelIndex: index
    })
  }

  handleCloseCriteriaDelDialog = () => {
    this.setState({ hideCriteriaDelDialog: true })
  }

  handleDeleteCriteria = () => {
    this.articlesStore.removeCriteria('exclude', this.state.criteriaDelIndex)
    this.setState({
      hideCriteriaDelDialog: true,
      criteriaDelIndex: undefined
    })
  }

  render () {
    const { t, store: { nodeStore: { editNode }, articlesStore: { excludeGenarts, showExcludeGenartsDialog, 
      showExcludeGenartInsertDialog, genartItem, excludeSuppliers, showExcludeSuppliersDialog, 
      showExcludeSupplierInsertDialog, supplierItem, excludeArtGrps, showExcludeArtGrpsDialog, 
      showExcludeArtGrpInsertDialog, artGrpItem, showExcludeCriteriaDialog, criteriaItem, excludeCriteria },
      esObjectStore : { genarts, mapGenarts, defaultLang, suppliers, mapSuppliers, criterias, mapCriterias, artGrps, mapArtGrps } } }  = this.props
    const { hideGenartDelDialog, genartDelIndex, hideSupplierDelDialog, supplierDelIndex, hideArtGrpDelDialog, 
      artGrpDelIndex, hideCriteriaDelDialog, criteriaDelIndex } = this.state
    const stackTokens = {
        childrenGap: 5
    }
    const containerStackTokens = {
        childrenGap: 10,
        padding: 10
    }
    const growingStyles = {
        root: {
            display: 'flex',
            height: '50%'
        }
    }
    const textFieldsStyles = {
        fieldGroup: { width: 400, height: 20, float: 'right' }
    } 
    return (
        <React.Fragment>
            <Stack tokens={containerStackTokens} style={{height: 'inherit'}}>  
                <Stack verticall tokens={stackTokens} >
                    <Stack horizontal tokens={stackTokens}>
                    <Stack.Item styles={{root: {width: '100%'}}}>     
                      <TextField label={t('common:ARTICLES_GENARTS')} id='genarts' readOnly value={editNode !== undefined ? editNode.exclude.genarts : '' || ''} styles={{fieldGroup: { width: 365, height: 20, float: 'right' }}}/>
                    </Stack.Item> 
                    <Stack.Item styles={growingStyles}>  
                      <IconButton
                        iconProps={viewIcon}
                        title={t('common:ARTICLES_GENARTS_ADD')}
                        onClick={() => this.showGenartsDialog()}
                      />
                    </Stack.Item>  
                    </Stack>
                    <Stack horizontal tokens={stackTokens}>
                    <Stack.Item styles={{root: {width: '100%'}}}>     
                      <TextField label={t('common:ARTICLES_ID_DLNR')} id='id_dlnr' readOnly value={editNode !== undefined ? editNode.exclude.id_dlnr : '' || ''} styles={{fieldGroup: { width: 365, height: 20, float: 'right' }}}/>
                    </Stack.Item> 
                    <Stack.Item styles={growingStyles}>  
                      <IconButton
                        iconProps={viewIcon}
                        title={t('common:ARTICLES_ID_DLNR_ADD')}
                        onClick={() => this.showSuppliersDialog()}
                      />
                    </Stack.Item>  
                    </Stack>
                    <TextField label={t('common:ARTICLES_ARTIDS')} id='artids' 
                        value={editNode !== undefined ? editNode.exclude.artids : '' || ''} 
                        onChange={(event, newValue) => {
                            if (editNode !== undefined) {
                              editNode.exclude.artids = newValue
                            }
                        }}
                        styles={textFieldsStyles}/>
                    <Stack horizontal tokens={stackTokens}>
                    <Stack.Item styles={{root: {width: '100%'}}}>     
                      <TextField label={t('common:ARTICLES_ARTGRP')} id='artgrp' readOnly value={editNode !== undefined ? editNode.exclude.artgrp : '' || ''} styles={{fieldGroup: { width: 365, height: 20, float: 'right' }}}/>
                    </Stack.Item> 
                    <Stack.Item styles={growingStyles}>  
                      <IconButton
                        iconProps={viewIcon}
                        title={t('common:ARTICLES_ARTGRP_ADD')}
                        onClick={() => this.showArtGrpsDialog()}
                      />
                    </Stack.Item>  
                    </Stack>    
                    <Stack horizontal tokens={stackTokens}>
                        <span><b>Criteria</b></span>
                        <IconButton
                            iconProps={addIcon}
                            title={t('common:CRITERIA_ADD')}
                            onClick={() => this.showCriteriaDialog()}
                        />
                    </Stack>    
                    <div style={{ position: "relative", height: 300 }} id='criteriaListItem'>    
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                        <DetailsList id='criteriaDetailsList'
                            items={ excludeCriteria } 
                            setKey='set'
                            columns={this.setCriteriaHeaderColumns()}
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={SelectionMode.none}
                            enterModalSelectionOnTouch
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderItemColumn={this.handleCriteriaItemColumn}
                        />
                        </ScrollablePane>   
                    </div>    
                </Stack>
            </Stack>
        { showExcludeGenartsDialog && <Dialog
          minWidth={500}
          maxWidth={700}
          hidden={!showExcludeGenartsDialog}
          onDismiss={this.handleCloseGenartsDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:ARTICLES_GENARTS_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
              <IconButton
                iconProps={addIcon}
                title={t('common:GENART_ADD')}
                onClick={() => this.showGenartInsertDialog()}
              />         
              <div style={{ position: "relative", height: 300 }} id='genartListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='genartDetailsList'
                    items={excludeGenarts} 
                    setKey='set'
                    columns={this.setGenartHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderItemColumn={this.handleGenartsItemColumn}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSaveGenarts} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseGenartsDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        { showExcludeGenartInsertDialog && <Dialog
          minWidth={400}
          maxWidth={600}
          hidden={!showExcludeGenartInsertDialog}
          onDismiss={this.handleCloseGenartInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:GENART_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:NODE_FILTER_GAID')}</Label>
              <Dropdown
                placeholder={t('common:NODE_FILTER_GAID')}
                defaultValue={genartItem !== undefined ? genartItem.gaid : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={genarts}
                onChange={(e, { value }) => {
                    genartItem.gaid = value
                    genartItem.gatxt = `${value}-${mapGenarts.get(defaultLang).get(value)}`
                }}
              />
              </Stack>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleGenartInsert} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseGenartInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {!hideGenartDelDialog && <Dialog
          hidden={hideGenartDelDialog}
          onDismiss={this.handleCloseGenartDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:GENART_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:GENART_DELETE_QESTION') + (excludeGenarts[genartDelIndex] !== undefined ? excludeGenarts[genartDelIndex].gatxt : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteGenart} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseGenartDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        {showExcludeSuppliersDialog && <Dialog
          minWidth={500}
          maxWidth={700}
          hidden={!showExcludeSuppliersDialog}
          onDismiss={this.handleCloseSuppliersDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:ARTICLES_ID_DLNR_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
              <IconButton
                iconProps={addIcon}
                title={t('common:SUPPLIER_ADD')}
                onClick={() => this.showSupplierInsertDialog()}
              />         
              <div style={{ position: "relative", height: 300 }} id='suppliersListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='suppliersDetailsList'
                    items={excludeSuppliers} 
                    setKey='set'
                    columns={this.setSupplierHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderItemColumn={this.handleSuppliersItemColumn}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSaveSuppliers} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseSuppliersDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        { showExcludeSupplierInsertDialog && <Dialog
          minWidth={400}
          maxWidth={600}
          hidden={!showExcludeSupplierInsertDialog}
          onDismiss={this.handleCloseSupplierInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:SUPPLIER_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:SUPPLIER')}</Label>
              <Dropdown
                placeholder={t('common:SUPPLIER')}
                defaultValue={supplierItem !== undefined ? supplierItem.id : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={suppliers}
                onChange={(e, { value }) => {
                    supplierItem.id = value
                    supplierItem.text = `${value}-${mapSuppliers.get(value)}`
                }}
              />
              </Stack>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSupplierInsert} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseSupplierInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {!hideSupplierDelDialog && <Dialog
          hidden={hideSupplierDelDialog}
          onDismiss={this.handleCloseSupplierDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:SUPPLIER_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:SUPPLIER_DELETE_QESTION') + (excludeSuppliers[supplierDelIndex] !== undefined ? excludeSuppliers[supplierDelIndex].text : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteSupplier} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseSupplierDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        {showExcludeArtGrpsDialog && <Dialog
          minWidth={500}
          maxWidth={700}
          hidden={!showExcludeArtGrpsDialog}
          onDismiss={this.handleCloseArtGrpsDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:ARTICLES_ARTGRP_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
              <IconButton
                iconProps={addIcon}
                title={t('common:ARTGRP_ADD')}
                onClick={() => this.showArtGrpInsertDialog()}
              />         
              <div style={{ position: "relative", height: 300 }} id='artGrpsListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='artGrpsDetailsList'
                    items={excludeArtGrps} 
                    setKey='set'
                    columns={this.setArtGrpHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderItemColumn={this.handleArtGrpsItemColumn}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleSaveArtGrps} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseArtGrpsDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        { showExcludeArtGrpInsertDialog && <Dialog
          minWidth={400}
          maxWidth={600}
          hidden={!showExcludeArtGrpInsertDialog}
          onDismiss={this.handleCloseArtGrpInsertDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:ARTGRP_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
            <Stack horizontal tokens={stackTokens}>
              <Stack horizontal tokens={stackTokens} style={{width: '100px'}}>
                <Label>{t('common:ARTGRP')}</Label>
              </Stack>
              <Dropdown
                placeholder={t('common:ARTGRP')}
                defaultValue={artGrpItem !== undefined ? artGrpItem.id : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={artGrps}
                onChange={(e, { value }) => {
                    artGrpItem.id = value
                    artGrpItem.text = `${value}-${mapArtGrps.get(value)}`
                }}
              />
              </Stack>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleArtGrpInsert} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseArtGrpInsertDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        {!hideArtGrpDelDialog && <Dialog
          hidden={hideArtGrpDelDialog}
          onDismiss={this.handleCloseArtGrpDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:ARTGRP_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:ARTGRP_DELETE_QESTION') + (excludeArtGrps[artGrpDelIndex] !== undefined ? excludeArtGrps[artGrpDelIndex].text : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteArtGrp} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseArtGrpDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>}
        { showExcludeCriteriaDialog && <Dialog
            minWidth={400}
            maxWidth={600}
            hidden={!showExcludeCriteriaDialog}
            onDismiss={this.handleCloseCriteriaDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: criteriaItem !== undefined && criteriaItem.isEdit === true ? t('common:CRITERIA_EDIT') : t('common:CRITERIA_ADD'),
              closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            }}
            modalProps={{
              isBlocking: true, dragOptions: true
            }}
          >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} style={{height: '20rem'}}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:NODE_FILTER_CAID')}</Label>
              <Dropdown
                placeholder={t('common:NODE_FILTER_CAID')}
                defaultValue={criteriaItem !== undefined ? criteriaItem.cid : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={criterias}
                onChange={(e, { value }) => {
                  this.handleCriteriaChange('cid', value)
                  this.handleCriteriaChange('cvp', mapCriterias.get(value))
                }}
              />
            </Stack>
            <TextField label={t('common:CRITERIA_CID')} id='cid' value={criteriaItem !== undefined ? criteriaItem.cid : ''} 
              onChange={(event, newValue) => this.handleCriteriaChange('cid', newValue)} styles={textFieldsStyles}/>  
            <TextField label={t('common:CRITERIA_CVP')} id='cvp' value={criteriaItem !== undefined && criteriaItem.cvp !== undefined ? criteriaItem.cvp : ''} 
              onChange={(event, newValue) => this.handleCriteriaChange('cvp', newValue)} styles={textFieldsStyles}/> 
          </Stack>
            <DialogFooter>
              <PrimaryButton onClick={this.handleCriteriaEdit} text={t('common:BUTTON_SAVE')} />
              <DefaultButton onClick={this.handleCloseCriteriaDialog} text={t('common:BUTTON_CANCEL')} />
            </DialogFooter>
        </Dialog>
        }
        <Dialog
          hidden={hideCriteriaDelDialog}
          onDismiss={this.handleCloseCriteriaDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:CRITERIA_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:CRITERIA_DELETE_QESTION') + (excludeCriteria[criteriaDelIndex] !== undefined ? excludeCriteria[criteriaDelIndex].cvp : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDeleteCriteria} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseCriteriaDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        </React.Fragment>
    )
  }
}

export { Exclude }
export default withTranslation(['common'], { wait: true })(withMainContainer(Exclude))