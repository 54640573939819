import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, Dropdown, ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, TextField,
  Dialog, DialogType, DialogFooter, StackItem } from '@fluentui/react'
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';
import { Dropdown as LookupDropdown} from 'semantic-ui-react'

import withMainContainer from '../../withMainContainer'
import { withTranslation } from 'react-i18next'

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }

const theme = getTheme()
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})

@inject('store')
@observer
class TilesExternal extends Component {
  constructor (props) {
    super(props)
    this.tileStore = this.props.store.tileStore
    this.tilesExternalStore = this.props.store.tilesExternalStore
    this.esObjectStore = this.props.store.esObjectStore
    this.unitreeStore = this.props.store.unitreeStore
    this.selection = new Selection();
    this.dragDropEvents = this.getDragDropEvents()
    this.draggedItem = undefined
    this.draggedIndex = -1
    this.state = {
      hideDelDialog: true
    }
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.tilesExternalStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.tilesExternalStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  showEditDialog = (item, index) => {
    if (item === undefined) {
      const sort = this.tilesExternalStore.externalItems.map(external => Number(external.tile_link_sort))
      item = {
        tile_link_text_trans: {},
        tile_link_sort: (sort.length > 0 ? Math.max(...sort) + 1 : 1).toString(),
        tile_link_attr: '',
        tile_link_type: '',
        tile_link_highlighted: '',
        isEdit: false
      }
      const date = new Date().toISOString().slice(2,19).replace(/[^0-9]/g,'')
      item.tile_link_id = this.tileStore.editTile.tile_id +'_'+ date
    } else {
      item.isEdit = true
      this.setState({
        editIndex: index,
        origExternal: JSON.stringify(item),
      })
    }
    if (item.tile_link_text_trans === undefined) {
        item.tile_link_text_trans = {}
    }
    this.tilesExternalStore.handleShowEditDialog(item)
  }

  handleCloseEditDialog = () => {
    this.tilesExternalStore.handleShowEditDialog()
    const { origExternal, editIndex } = this.state
    if (editIndex !== undefined) {
      this.tilesExternalStore.externalItems[editIndex] = JSON.parse(origExternal)
    }
    this.setState({
      editIndex: undefined,
      origExternal: '',
    })
  }

  handleItemColumn = (item, index, column) => {
    const { t } = this.props
    if (column.fieldName === 'tile_link_text_trans') {
      return (
        item.tile_link_text_trans !== undefined ? Object.entries(item.tile_link_text_trans).map(([key, value]) => (<div key={key}><b>{key}</b>: {value}</div>)) : ''
      )
    } else if (column.fieldName === 'buttons') {
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:TILE_EXTERNAL_EDIT')}
            onClick={() => this.showEditDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:TILE_EXTERNAL_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  handleEdit = () => {
    let empty = false
    this.esObjectStore.langs.forEach(lang => {
      if (this.tilesExternalStore.externalItem.tile_link_text_trans[lang] === '' || this.tilesExternalStore.externalItem.tile_link_text_trans[lang] === undefined) {
        empty = true
      }
    })
    if (empty) {
      return
    }
    this.tilesExternalStore.editExternal()
    this.tilesExternalStore.handleShowEditDialog()
  }

  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.tilesExternalStore.removeExternal(this.state.delIndex)
    this.setState({
      hideDelDialog: true,
      delIndex: undefined
    })
  }

  getExternalTileName = (external) => {
    return external.tile_link_text_trans !== undefined ? (external.tile_link_text_trans[this.esObjectStore.defaultLang]) : external.tile_link_text
  }

  handleChange = (key, value) => {
    const externalItem = this.tilesExternalStore.externalItem
    if (externalItem !== undefined) {
        externalItem[key] = value
    }
  }

  getDragDropEvents = () => {
    return {
      canDrop: (dropContext, dragContext) => {
        return true
      },
      canDrag: (item) => {
        return true
      },
      onDragEnter: (item, event) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item, event) => {
        return
      },
      onDrop: (item, event) => {
        if (this.draggedItem) {
          this.insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        this.draggedItem = item;
        this.draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        this.draggedItem = undefined;
        this.draggedIndex = -1;
      },
    };
  }

  insertBeforeItem = async(item) => {
    const draggedItems = this.selection.isIndexSelected(this.draggedIndex)
      ? (this.selection.getSelection()) : [this.draggedItem]

    const insertIndex = this.tilesExternalStore.externalItems.indexOf(item)
    const items = this.tilesExternalStore.externalItems.filter(itm => draggedItems.indexOf(itm) === -1)

    items.splice(insertIndex, 0, ...draggedItems)
    this.tilesExternalStore.externalItems = items

    // update sort field
    const sort = this.tilesExternalStore.externalItems.map(item => Number(item.tile_link_sort))
    const minsort = sort.length > 0 ? Math.min(...sort) : 1
    for (let index = 0; index < this.tilesExternalStore.externalItems.length; index++) {
      this.tilesExternalStore.externalItems[index].tile_link_sort = (minsort+index).toString()
    }
    this.tileStore.editTile.tile_external = this.tilesExternalStore.externalItems
  }

  render () {
    const { t, store: { tilesExternalStore: { externalItems, externalItem, showEditDialog, externalTypes },
      esObjectStore : { langs }, unitreeStore : { nodesOptions } } }  = this.props
    const { hideDelDialog, delIndex } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const stackStyles = {
      root: {
        width: '280px'
      }
    }
    const dialogTextFieldsStyles = {
        fieldGroup: { width: 280, height: 20, float: 'right' }
    }
    return (
      <React.Fragment>
        <Stack verticall tokens={stackTokens}>
          <Stack horizontal tokens={stackTokens} styles={stackStyles}>
            <span><h4>{ 'Tile external' }</h4></span>
            <IconButton
              iconProps={addIcon}
              title={t('common:TILE_EXTERNAL_ADD')}
              onClick={() => this.showEditDialog()}
            />
          </Stack>
          <div style={{ position: "relative", height: 350 }} id='tileExternal'>    
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
              <DetailsList id='tileExternalDetailsList'
                items={externalItems} 
                setKey='set'
                columns={this.setHeaderColumns()}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                selection={this.selection}
                enterModalSelectionOnTouch
                constrainMode={ConstrainMode.unconstrained}
                onRenderItemColumn={this.handleItemColumn}
                dragDropEvents={this.dragDropEvents}
              />
            </ScrollablePane>   
          </div>
        </Stack>
        { showEditDialog && <Dialog
          minWidth={600}
          maxWidth={800}
          hidden={!showEditDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: externalItem !== undefined && externalItem.isEdit === true ? t('common:TILE_EXTERNAL_EDIT') : t('common:TILE_EXTERNAL_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
          <Stack horizontal tokens={stackTokens}>
            <Stack.Item styles={{root: {width: '20%'}}}>
              <Label>{t('common:TILE_LINK')}</Label>
            </Stack.Item>
            <Stack.Item styles={{root: {width: '80%'}}}>
              <LookupDropdown
                placeholder={t('common:SELECT_TILE_LINK')}
                defaultValue={externalItem !== undefined ? externalItem.tile_link : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={nodesOptions}
                onChange={(e, { value }) => {
                  externalItem.tile_link = value
                  const node = this.unitreeStore.nodesOptions.find(option => option.value === value)
                  externalItem.tile_link_str = node !== undefined ? node.text : ''
                }}
              />
            </Stack.Item>  
            </Stack>
            {langs.map(lang => {
              return (
              <TextField label={t('common:TREE_NAME')+ ' ' + lang} id={'tile_link_text_'+ lang} key={'tile_link_text_'+ lang} 
                defaultValue={externalItem !== undefined && externalItem.tile_link_text_trans !== undefined? externalItem.tile_link_text_trans[lang] : ''} 
                required type='text' onGetErrorMessage={ value => { if (value === '') return lang + t('common:TREE_NAME_DIALOG_INPUT_ERR_MSG') }} 
                onChange={(event, newValue) => {
                  if (externalItem !== undefined) {
                    externalItem.tile_link_text_trans[lang] = newValue
                  }
                }
                } styles={dialogTextFieldsStyles}/>
              )})}   
            <Dropdown id='tile_link_type'
                label={t('common:TILE_TYPE')}
                defaultSelectedKey={externalItem !== undefined ? externalItem.tile_link_type : ''}
                options={externalTypes}
                onChange={(event, { key }) => this.handleChange('tile_link_type', key !== '' ? key : null)}
                styles={{dropdown: { width: 280, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
            <TextField label={t('common:TILE_EXTERNAL_ATTR')} id='tile_link_attr' defaultValue={externalItem !== undefined ? externalItem.tile_link_attr : ''} 
                onChange={(event, newValue) => this.handleChange('tile_link_attr', newValue)} styles={dialogTextFieldsStyles}/>
            <TextField label={t('common:TILE_EXTERNAL_HIGHLIGHTED')} id='tile_link_highlighted' defaultValue={externalItem !== undefined ? externalItem.tile_link_highlighted : ''} 
                onChange={(event, newValue) => this.handleChange('tile_link_highlighted', newValue)} styles={dialogTextFieldsStyles}/>
          </Stack>
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:TILE_EXTERNAL_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:TILE_EXTERNAL_DELETE_QESTION') + (externalItems[delIndex] !== undefined ? this.getExternalTileName(externalItems[delIndex]) : '') + '?'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
      </React.Fragment> 
    )
  }
}

export { TilesExternal }
export default withTranslation(['common'], { wait: true })(withMainContainer(TilesExternal))