import { observable } from 'mobx'
const data = require('../../config/data.json')
class GenartCriteriaStore {
  @observable criteriaColumns = []  
  @observable editCriteriaItem = undefined

  origCriteriaType = ''
  editCriteriaIndex = undefined

  constCriteriaHeader = [
    { key: 'cid', text : 'CRITERIA_CID' },
    { key: 'cvp', text : 'CRITERIA_CVP' }
  ]

  criteriaTypes = []

  constructor (authStore, categoriesStore, genartsStore) {
    this.authStore = authStore 
    this.categoriesStore = categoriesStore
    this.genartsStore = genartsStore

    this.constCriteriaHeader.forEach(({key}) => this.criteriaColumns.push({
        key,
        fieldName: key,
        isResizable: true,
        width: key === 'cvp' ? '75%' : '25%',
        minWidth: key === 'cvp' ? 150 : 50,
        maxWidth: key === 'cvp' ? 300 : 100,
    }))
  }

  addDefaultCriterias = (type) => {
    this.genartsStore.criteriaItems = []
    const criterias = this.categoriesStore.mapCriteriaTexts.get(type)
    criterias !== undefined && criterias.forEach(criteria => {
      this.genartsStore.criteriaItems.push({
        'cvp': criteria,
        'cid': '100'
      })
    })
    this.genartsStore.genartItem.criteria = this.genartsStore.criteriaItems
    this.genartsStore.genartItem.criteriaType = type
  }

  deleteCriterias = () => {
    this.genartsStore.criteriaItems = []
    this.genartsStore.genartItem.criteria = []
    this.genartsStore.genartItem.criteriaType = undefined
  }
}

export default GenartCriteriaStore
