import { observable } from 'mobx'
const data = require('../../config/data.json')
class DialogCategoryStore {
  // click group
  @observable clickGroupColumns = []  
  @observable showClickGroupDialog = false
  @observable clickGroup = []
  @observable showClickGroupInsertDialog = false
  @observable clickGroupItem = undefined

  vc = []

  constFiltersHeader = [
    { key: 'gatxt', text : 'NODE_FILTER_GAID' },
    { key: 'filter_str', text : 'NODE_FILTER' },
    { key: 'buttons', text: '' }
  ]

  constClickGroupHeader = [
    { key: 'text', text : 'CATEGORY' },
    { key: 'buttons', text: '' }
  ]

  external_service_attribute = [{ key: '', text: '' }]
  external_service = [{ key: '', text: '' }]
  link = [{ key: '', text: '' }]
  filters = new Map()
  constructor (authStore, esObjectStore, categoriesTreeStore) {
    this.authStore = authStore 
    this.esObjectStore = esObjectStore
    this.categoriesTreeStore = categoriesTreeStore 
    data.vc.forEach(v => this.vc.push({key: v, text: v}))
    data.external_service_attribute.forEach(esa => this.external_service_attribute.push({key: esa, text: esa}))
    data.external_service.forEach(es => this.external_service.push({key: es, text: es}))
    data.link.forEach(lk => this.link.push({key: lk, text: lk}))
    data.filters.forEach(filter => this.filters.set(filter.gaid, filter.filter))
    this.constClickGroupHeader.forEach(({key}) => this.clickGroupColumns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: key === 'text' ? '75%' : '25%',
      minWidth: key === 'text' ? 150 : 50,
      maxWidth: key === 'text' ? 300 : 100
  }))
  }

  getClickGroupTexts = (clickGroupIds) => {
    if (!clickGroupIds) {
      return ''
    }
    return clickGroupIds.split(',').map(cG => this.categoriesTreeStore.mapCategories.get(cG)?.node_name_trans !== undefined ? this.categoriesTreeStore.mapCategories.get(cG)?.node_name_trans[this.esObjectStore.defaultLang] : '') 
  }

  /**
    * Hide / Show dialog
    */
  handleClickGroupDialog = (click_group) => {
    this.showClickGroupDialog = !this.showClickGroupDialog
    this.clickGroup = []
    if (click_group && click_group.length > 0) {
        const clickGroupArr = click_group.split(',')
        clickGroupArr.forEach(cG => {
            const obj = {id : cG, text: `${this.categoriesTreeStore.mapCategories.get(cG)?.node_name_trans !== undefined ? this.categoriesTreeStore.mapCategories.get(cG)?.node_name_trans[this.esObjectStore.defaultLang] : ''}` }
            this.clickGroup.push(obj)
        })
    }
  }  

  removeCategoryFromClickGroup= (catDelIndex) => {
    this.clickGroup.splice(catDelIndex, 1)
  }

  handleCloseClickGroupInsertDialog = (item) => {
    this.showClickGroupInsertDialog = !this.showClickGroupInsertDialog
    this.clickGroupItem = item
  }

  insertClickGroup = () => {
    this.clickGroup.push(this.clickGroupItem)
  }
}

export default DialogCategoryStore
