// import libs
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from '@fluentui/react';
import { withTranslation } from 'react-i18next'

// import components
import withMainContainer from './Extentions/withMainContainer'

// import styles
import './Welcome.scss'

@inject('store')
@observer
class Welcome extends Component {
  constructor (props) {
    super(props)
    this.authStore = this.props.store.authStore
  }

  handleClickOnLink = (ev) => {
    this.props.store.authStore.initApp()
  }

  render () {
    const { t } = this.props
    const APP_NAME = process.env.REACT_APP_NAME
    return (
      <div className='welcome-container'>
        <div className='welcome-wrapper'>
          <h1>{t('common:WELCOME_HEADER')}{' '}{APP_NAME}</h1>
          <h5><Link onClick={this.handleClickOnLink} underline> Login with Microsoft Azure account </Link>{t('common:WELCOME_SUB')}</h5>
        </div>
      </div>
    )
  }
}

export { Welcome }
export default withTranslation(['common'], { wait: true })(withMainContainer(Welcome))