import React, { Component } from 'react'

// import styles
import './Footer.scss'

class Footer extends Component {
  render () {
    const CURRENT_YEAR = new Date().getFullYear()
    const APP_VERSION = process.env.REACT_APP_VERSION
    const APP_NAME = process.env.REACT_APP_NAME

    return (
      <footer className='ex--footer'>
        <div>&copy;</div>
        <div>{CURRENT_YEAR}</div>
        <div className='bolder'>SAG-AG</div>
        <div>Alle Rechte vorbehalten.</div>
        <div>|</div>
        <div>{APP_NAME} Version</div>
        <div>{APP_VERSION}</div>
      </footer>
    )
  }
}

export default Footer
