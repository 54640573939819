import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Selection
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, Dropdown } from '@fluentui/react'
import withMainContainer from '../withMainContainer'
import { withTranslation } from 'react-i18next'

const delIcon = { iconName: 'ErrorBadge' }

@inject('store')
@observer
class GenartCriteria extends Component {
  constructor (props) {
    super(props)
    this.genartsStore = this.props.store.genartsStore
    this.genartCriteriaStore = this.props.store.genartCriteriaStore
    this.esObjectStore = this.props.store.esObjectStore
    this.selection = new Selection();
  }

  setCriteriaHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.genartCriteriaStore.criteriaColumns.forEach((col, index) => {
      col.name = t(`common:${this.genartCriteriaStore.constCriteriaHeader[index].text}`)
      columnsName.push(col)
    })
    return columnsName
  }

  render () {
    const { t, store: { genartsStore: { criteriaItems, genartItem },
      genartCriteriaStore: { criteriaTypes } }}  = this.props
    const stackTokens = {
      childrenGap: 5
    }
    return (
      <React.Fragment>
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens}>
          <Stack tokens={stackTokens} styles={{root: {padding: 10, width: '480px'}}} horizontal>
            <Dropdown id='criteria_type'
                label={t('common:CRITERIA_TYPE')}
                selectedKey={genartItem.criteriaType || ''}
                options={criteriaTypes}
                onChange={(event, { key }) => this.genartCriteriaStore.addDefaultCriterias(key) }
                styles={{dropdown: { width: 235, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' 
            }}
            />
            <IconButton
              onClick={() => this.genartCriteriaStore.deleteCriterias()}
              iconProps={delIcon}
              title={t('common:CRITERIA_DELETE')}
            />
            </Stack>
              <div style={{ position: "relative", height: 250 }} id='criteriaListItem'>    
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                  <DetailsList id='criteriaDetailsList'
                    items={criteriaItems} 
                    setKey='set'
                    columns={this.setCriteriaHeaderColumns()}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    selection={this.selection}
                    enterModalSelectionOnTouch
                    constrainMode={ConstrainMode.unconstrained}
                  />
                </ScrollablePane>   
              </div>
          </Stack>
      </React.Fragment> 
    )
  }
}

export { GenartCriteria }
export default withTranslation(['common'], { wait: true })(withMainContainer(GenartCriteria))