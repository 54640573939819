
import { observable } from 'mobx'
import { Logger } from 'msal'
import config from '../config/config'
import React, { useState } from "react";

class ImporterStore {
  @observable items = []
  @observable checked = []  
  @observable loading = false

  constructor (authStore) {
    this.authStore = authStore 
  }

  /**
   * Get the indices from ES
   */
  populateIndices = async (env) => {
    this.items = []
    const result = await fetch(`${config.server_address}/fe/importer/${env}/${this.authStore.selectedApp}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    if (result.status === 200) {
      const data = await result.json()
      console.log(data.message)
      const data_items = []
      JSON.parse(data.message).forEach(shop_group => {
        if (this.authStore.hasUserRight(shop_group['shop_name']) === true) {
          data_items.push(shop_group)
          console.log(shop_group['shop_name'] + ' => ' + shop_group['indices'].length)
        }
      });
      this.items = data_items
    }
    console.log(this.items)
    this.checked = []
  }

  // Add/Remove checked item from list
  handleCheck = (event, newValue) => {
    console.log('Handle check (' +  event.target.id  + ", " + newValue + ')')
    var updatedList = [...this.checked];
    if (newValue) {
      updatedList = [...this.checked, event.target.id];
    } else {
      updatedList.splice(this.checked.indexOf(event.target.id), 1);
    }
    this.checked  = updatedList;
    console.log(this.checked)
  }
  doImport = async (env, shop, indices) => {
    if (indices.length == 0) {
      alert(`No selection available!`)
      return
    }
    if (this.authStore.selectedApp === 'brand_priority' && indices.length > 1) {
      alert(`Select only one index!`)
      return
    }
    this.loading = true
    const result = await fetch(`${config.server_address}/fe/importer/index/${env}/${shop}/${indices.join(',')}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    this.loading = false
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    } else if (result.status === 500) {
      alert(`Error: ${ (await result.json())['message']}!`)
      return
    } else if (result.status === 200) {
      const res = await result.json()
      if (res.success === true) {
        alert(`EsObject created!`)
      }
    }
  }
  setAlias = async (env, indices, selectedShop) => {
    console.log(selectedShop)
    if (indices.length == 0) {
      alert(`No selection available!`)
      return false
    }
    if (!selectedShop) {
      return false
    }

    const proposedAlias = (this.authStore.selectedApp === 'unitree' ? selectedShop : selectedShop.indexOf('_') > 0 ? selectedShop.substring(selectedShop.indexOf('_') + 1) : selectedShop)  + '_' + this.authStore.selectedApp
    // set alias for brand_priority app
    if (this.authStore.selectedApp === 'brand_priority') {
      if (indices.length > 1) {
        alert(`Select only one index!`)
        return false
      }
      let aliasName = prompt(`Alias to be set to the selected index`, proposedAlias)
      if (aliasName) {
        let actions = [{add: {alias: aliasName, index: indices[0]}}]
        let result = await fetch(`${config.server_address}/fe/importer/alias/${env}/${aliasName}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.authStore.token}`,
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(actions)
        })
        // token no longer valid => forward to welcome page
        if (result.status === 401) {
          this.authStore.clearUserSession(true)
          return false
        } else if (result.status === 500) {
          alert(`Error: ${ (await result.json())['message']}!`)
          return false
        } else if (result.status === 200) {
          const res = await result.json()
          if (res.success === true) {
            alert(res['message'])
            this.checked = []
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    } 
    // set alias for unitree or categories app
    let res_shops = await fetch(`${config.server_address}/fe/shops`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (res_shops.status === 401) {
      this.authStore.clearUserSession(true)
      return false
    }
    let shops = (await res_shops.json()).message
    let detected_shop = shops.find(shop => shop.shop_name === selectedShop)
    if (!detected_shop) {
      alert(`Could not detect the shop from (${indices.join(',')})!`)
      return false
    }
    let languageToIndex = new Map()
    let langs = detected_shop.languages.split(',')
    indices.forEach(an_index => {
      let parts = an_index.split('_');
      let detected_languages = parts.slice(1).filter(item => langs.includes(item))
      if (detected_languages.length > 0) {
        languageToIndex.set(detected_languages[0], an_index)
      } else {
        alert(`No languages detected for ${an_index}! ${detected_languages} (Select indexes in these languages: ${detected_shop.languages})`)
        return false
      }
    });
    if (languageToIndex.size < langs.length) {
      alert(`Not all languages were selected(${languageToIndex.size} < ${langs.length})!`)
      return false
    }
    if (languageToIndex.size < indices.length && langs.length < indices.length) {
      alert(`Select indexes only in these languages: ${detected_shop.languages} (${languageToIndex.size} < ${indices.length})!`)
      return false
    }
    if (languageToIndex.size < indices.length) {
      alert(`A language appears in more than one index(${languageToIndex.size} < ${indices.length})!`)
      return false
    }
    let aliasName = prompt(`Alias to be set to the selected indices(they will have the languages ${langs.join(',') } as suffix)`, proposedAlias)
    if (aliasName) {
      let actions = []
      for (const lang of langs) {
        actions.push({add: {alias: aliasName + '_' + lang, index: languageToIndex.get(lang)}})
      }
      let result = await fetch(`${config.server_address}/fe/importer/alias/${env}/${aliasName}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`,
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(actions)
      })
      // token no longer valid => forward to welcome page
      if (result.status === 401) {
        this.authStore.clearUserSession(true)
        return false
      } else if (result.status === 500) {
        alert(`Error: ${ (await result.json())['message']}!`)
        return false
      } else if (result.status === 200) {
        const res = await result.json()
        if (res.success === true) {
          alert(res['message'])
          this.checked = []
          return true
        }
      }
    } else {
      return false
    }
  }

  deleteIndexes = async (env, indices) => {
    if (indices.length == 0) {
      alert(`No selection available!`)
      return false
    }
    if (this.authStore.selectedApp !== 'brand_priority') {
      let res_shops = await fetch(`${config.server_address}/fe/shops`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.authStore.token}`,
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      // token no longer valid => forward to welcome page
      if (res_shops.status === 401) {
        this.authStore.clearUserSession(true)
        return false
      }
      let shops = (await res_shops.json()).message
      let detected_shops = shops.filter(function (shop) { return indices.some((an_index) => an_index.startsWith(shop.shop_name + '_')); });
      let langs;
      let languageToIndex = new Map()
      if (detected_shops.length > 1) {
        alert(`The selection contains more than one shop(${detected_shops.map(s => s.shop_name).join(',')})!`)
        return false
      } else if (detected_shops.length == 0) {
        alert(`Could not detect the shop from (${indices.join(',')})!`)
        return false
      } else {
        langs = detected_shops[0].languages.split(',')
        indices.forEach(an_index => {
          let parts = an_index.split('_');
          let detected_languages = parts.slice(1).filter(item => langs.includes(item))
          if (detected_languages.length > 0) {
            languageToIndex.set(detected_languages[0], an_index)
          } else {
            alert(`No languages detected for ${an_index}! ${detected_languages}`)
            return false
          }
        });
        if (languageToIndex.size < langs.length) {
          alert(`Not all languages were selected(${languageToIndex.size} < ${langs.length})!`)
          return false
        }
        if (languageToIndex.size < indices.length) {
          alert(`A language appears in more than one index(${languageToIndex.size} < ${indices.length})!`)
          return false
        }
      }
    }
    let result = await fetch(`${config.server_address}/fe/importer/deleteIndexes`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        env,
        indices
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return false
    } else if (result.status === 500) {
      alert(`Error: ${ (await result.json())['message']}!`)
      return false
    } else if (result.status === 200) {
      const res = await result.json()
      if (res.success === true) {
        alert(res['message'])
        this.checked = []
        return true
      }
    }
  }
}

export default ImporterStore 