
import { observable } from 'mobx'
import config from '../../config/config'

class ConfigAmStore {
  @observable items = []
  @observable columns = []  

  constHeader = [
    { key: 'shop_name', text : 'UNITREE_SHOP_NAME' },
    { key: 'gatxt', text : 'ARTICLES_GENARTS' },
    { key: 'buttons', text: '' }
  ]

  constructor (authStore, esObjectStore) {
    this.authStore = authStore
    this.esObjectStore = esObjectStore 
    this.constHeader.forEach(({key}) => this.columns.push({
      key,
      fieldName: key,
      isResizable: true,
      width: key === 'gatxt' ? '60%' : '20%',
      minWidth: key === 'gatxt' ? 300 : 120,
      maxWidth: key === 'gatxt' ? 400 : 200,
    })
    )
  }
  /**
   * Load GenartsAm from DB depending on the shop
   */
  fetchGenartsAm = async (shop_id) => {
    const result = await fetch(`${config.server_address}/fe/categories/genartAm/${shop_id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const data = await result.json()
    if (this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang) !== undefined) {
        this.items = data.message.map(item => ({...item, shop_name: this.esObjectStore.mapShops.get(item.shop_id).shop_name,
            gatxt: item.id_genart + '-' + this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(item.id_genart) }))
    }
  }

  /**
   * Remove a GenartAM from the DB
   */
  removeGenartAm = async (key) => {
    // remove from DB 
    const item = this.items[key]
    const result = await fetch(`${config.server_address}/fe/categories/genartAm/delete/${item.id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      alert(`Genart ${item.id_genart} successfully deleted !`)
      await this.fetchGenartsAm(item.shop_id)
    }
  }
  /**
   * Insert a GenartAM from the DB
   */
  insertGenartAm = async (item) => {
    // edit in DB 
    const result = await fetch(`${config.server_address}/fe/categories/genartAm/insert`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        shop_id: item.shop_id, 
        id_genart: item.id_genart
      })
    })
    // token no longer valid => forward to welcome page
    if (result.status === 401) {
      this.authStore.clearUserSession(true)
      return
    }
    const res = await result.json()
    if (res.success === true) {
      alert(`Genart ${item.id_genart} successfully inserted!`)
      await this.fetchGenartsAm(item.shop_id)
    }
  }
}
export default ConfigAmStore
