import { action, observable } from 'mobx'

class ArticlesStore {
    @observable criteriaColumns = []  
    @observable showIncludeCriteriaDialog = false
    @observable showExcludeCriteriaDialog = false
    @observable criteriaItem = undefined
    @observable includeCriteria = []
    @observable excludeCriteria = []

    @observable genartColumns = []  
    @observable showIncludeGenartsDialog = false
    @observable includeGenarts = []
    @observable showExcludeGenartsDialog = false
    @observable excludeGenarts = []

    @observable showIncludeGenartInsertDialog = false
    @observable showExcludeGenartInsertDialog = false
    @observable genartItem = undefined
    
    // suppliers
    @observable supplierColumns = []  
    @observable showIncludeSuppliersDialog = false
    @observable includeSuppliers = []
    @observable showExcludeSuppliersDialog = false
    @observable excludeSuppliers = []

    @observable showIncludeSuppliersInsertDialog = false
    @observable showExcludeSuppliersInsertDialog = false
    @observable supplierItem = undefined
    
    // article groups
    @observable artGrpColumns = []  
    @observable showIncludeArtGrpsDialog = false
    @observable includeArtGrps = []
    @observable showExcludeArtGrpsDialog = false
    @observable excludeArtGrps = []

    @observable showIncludeArtGrpsInsertDialog = false
    @observable showExcludeArtGrpsInsertDialog = false
    @observable artGrpItem = undefined

    constCriteriaHeader = [
        { key: 'cid', text : 'CRITERIA_CID' },
        { key: 'cvp', text : 'CRITERIA_CVP' },
        { key: 'buttons', text: '' }
    ]
    
    constGenartHeader = [
        { key: 'gatxt', text : 'NODE_FILTER_GAID' },
        { key: 'buttons', text: '' }
    ]

    constSupplierHeader = [
        { key: 'text', text : 'ARTICLES_ID_DLNR' },
        { key: 'buttons', text: '' }
    ]

    constArtGrpHeader = [
        { key: 'text', text : 'ARTICLES_ARTGRP' },
        { key: 'buttons', text: '' }
    ]
    constructor (authStore, esObjectStore, nodeStore) {
        this.authStore = authStore 
        this.esObjectStore = esObjectStore
        this.nodeStore = nodeStore
        this.constCriteriaHeader.forEach(({key}) => this.criteriaColumns.push({
            key,
            fieldName: key,
            isResizable: true,
            width: key === 'cvp' ? '50%' : '25%',
            minWidth: key === 'cvp' ? 150 : 50,
            maxWidth: key === 'cvp' ? 350 : 100,
        }))
        this.constGenartHeader.forEach(({key}) => this.genartColumns.push({
            key,
            fieldName: key,
            isResizable: true,
            width: key === 'gatxt' ? '75%' : '25%',
            minWidth: key === 'gatxt' ? 150 : 50,
            maxWidth: key === 'gatxt' ? 300 : 100
        }))
        this.constSupplierHeader.forEach(({key}) => this.supplierColumns.push({
            key,
            fieldName: key,
            isResizable: true,
            width: key === 'text' ? '75%' : '25%',
            minWidth: key === 'text' ? 150 : 50,
            maxWidth: key === 'text' ? 300 : 100
        }))
        this.constArtGrpHeader.forEach(({key}) => this.artGrpColumns.push({
            key,
            fieldName: key,
            isResizable: true,
            width: key === 'text' ? '75%' : '25%',
            minWidth: key === 'text' ? 150 : 50,
            maxWidth: key === 'text' ? 300 : 100
        }))
    }

    /**
    * Hide / Show dialog
    */
    handleCriteriaDialog = (type, criteria) => {
        type === 'include' ? this.showIncludeCriteriaDialog = !this.showIncludeCriteriaDialog : this.showExcludeCriteriaDialog = !this.showExcludeCriteriaDialog
        this.criteriaItem = criteria
    }  
    editCriteria = (type) => {
        if (type === 'include') {
            if (!this.criteriaItem.isEdit) {
                this.includeCriteria.push(this.criteriaItem)
            }
            this.nodeStore.editNode.include.criteria = this.includeCriteria
        } else {
            if (!this.criteriaItem.isEdit) {
                this.excludeCriteria.push(this.criteriaItem)
            }
            this.nodeStore.editNode.exclude.criteria = this.excludeCriteria
        }
        this.handleCriteriaDialog(type)
    }

     /**
    * Hide / Show dialog
    */
    handleGenartsDialog = (type, genarts) => {
        type === 'include' ? this.showIncludeGenartsDialog = !this.showIncludeGenartsDialog : this.showExcludeGenartsDialog = !this.showExcludeGenartsDialog
        type === 'include' ? this.includeGenarts = [] : this.excludeGenarts = []
        if (genarts !== undefined && genarts.length > 0) {
            const genartsArr = genarts.split(',')
            genartsArr.forEach(genart => {
                const obj = {gaid : genart, gatxt: `${genart}-${this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(genart)}` }
                type === 'include' ? this.includeGenarts.push(obj) : this.excludeGenarts.push(obj)
            })
        }
    }  
    
    removeGenart = (type, genartDelIndex) => {
        if (type === 'include') {
            this.includeGenarts.splice(genartDelIndex, 1)
        } else {
            this.excludeGenarts.splice(genartDelIndex, 1)
        }
    }

    handleCloseGenartInsertDialog = (type, item) => {
        type === 'include' ? this.showIncludeGenartInsertDialog = !this.showIncludeGenartInsertDialog : this.showExcludeGenartInsertDialog = !this.showExcludeGenartInsertDialog
        this.genartItem = item
    }

    insertGenart = (type) => {
        type === 'include' ? this.includeGenarts.push(this.genartItem) : this.excludeGenarts.push(this.genartItem)
    }

    removeCriteria = (type, criteriaDelIndex) => {
        if (type === 'include') {
            this.includeCriteria.splice(criteriaDelIndex, 1)
            this.nodeStore.editNode.include.criteria = this.includeCriteria
        } else {
            this.excludeCriteria.splice(criteriaDelIndex, 1)
            this.nodeStore.editNode.exclude.criteria = this.excludeCriteria
        }
    }

     /**
    * Hide / Show dialog
    */
     handleSuppliersDialog = (type, suppliers) => {
        type === 'include' ? this.showIncludeSuppliersDialog = !this.showIncludeSuppliersDialog : this.showExcludeSuppliersDialog = !this.showExcludeSuppliersDialog
        type === 'include' ? this.includeSuppliers = [] : this.excludeSuppliers = []
        if (suppliers !== undefined && suppliers.length > 0) {
            const suppliersArr = suppliers.split(',')
            suppliersArr.forEach(supplier => {
                const obj = {id : supplier, text: `${supplier}-${this.esObjectStore.mapSuppliers.get(supplier)}` }
                type === 'include' ? this.includeSuppliers.push(obj) : this.excludeSuppliers.push(obj)
            })
        }
    }  

    removeSupplier = (type, supplierDelIndex) => {
        if (type === 'include') {
            this.includeSuppliers.splice(supplierDelIndex, 1)
        } else {
            this.excludeSuppliers.splice(supplierDelIndex, 1)
        }
    }

    handleCloseSupplierInsertDialog = (type, item) => {
        type === 'include' ? this.showIncludeSupplierInsertDialog = !this.showIncludeSupplierInsertDialog : this.showExcludeSupplierInsertDialog = !this.showExcludeSupplierInsertDialog
        this.supplierItem = item
    }

    insertSupplier = (type) => {
        type === 'include' ? this.includeSuppliers.push(this.supplierItem) : this.excludeSuppliers.push(this.supplierItem)
    }

     /**
    * Hide / Show dialog
    */
     handleArtGrpsDialog = (type, artGrps) => {
        type === 'include' ? this.showIncludeArtGrpsDialog = !this.showIncludeArtGrpsDialog : this.showExcludeArtGrpsDialog = !this.showExcludeArtGrpsDialog
        type === 'include' ? this.includeArtGrps = [] : this.excludeArtGrps = []
        if (artGrps !== undefined && artGrps.length > 0) {
            const artGrpsArr = artGrps.split(',')
            artGrpsArr.forEach(artGrp => {
                const obj = {id : artGrp, text: `${artGrp}-${this.esObjectStore.mapArtGrps.get(artGrp)}` }
                type === 'include' ? this.includeArtGrps.push(obj) : this.excludeArtGrps.push(obj)
            })
        }
    }  

    removeArtGrp = (type, artGrpDelIndex) => {
        if (type === 'include') {
            this.includeArtGrps.splice(artGrpDelIndex, 1)
        } else {
            this.excludeArtGrps.splice(artGrpDelIndex, 1)
        }
    }

    handleCloseArtGrpInsertDialog = (type, item) => {
        type === 'include' ? this.showIncludeArtGrpInsertDialog = !this.showIncludeArtGrpInsertDialog : this.showExcludeArtGrpInsertDialog = !this.showExcludeArtGrpInsertDialog
        this.artGrpItem = item
    }

    insertArtGrp = (type) => {
        type === 'include' ? this.includeArtGrps.push(this.artGrpItem) : this.excludeArtGrps.push(this.artGrpItem)
    }

    @action
    updateKeywordsOnEditGenarts = () => {
        const origGaids = this.nodeStore.editNode.include.genarts.length > 0 ? this.nodeStore.editNode.include.genarts.split(',') : []
        const newGaids = this.includeGenarts.map(genart => genart.gaid)
        const removedGaids = origGaids.filter(gaid => !newGaids.includes(gaid))
        const addedGaids = newGaids.filter(gaid => !origGaids.includes(gaid))
        this.esObjectStore.langs.forEach(lang => {
            // remove genarts from keywords
            removedGaids.forEach(gaid => this.nodeStore.editNode.node_keywords_trans[lang] = this.nodeStore.editNode.node_keywords_trans[lang].replace(this.esObjectStore.mapGenarts.get(lang).get(gaid), ''))
            // add new genarts to keywords
            addedGaids.forEach(gaid => this.nodeStore.editNode.node_keywords_trans[lang] = (this.nodeStore.editNode.node_keywords_trans[lang] ? this.nodeStore.editNode.node_keywords_trans[lang] + ' ' : '') + this.esObjectStore.mapGenarts.get(lang).get(gaid))
        })
    }
}

export default ArticlesStore
