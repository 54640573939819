import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import withMainContainer from '../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Stack, IconButton, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, Pivot, PivotItem, Label, Checkbox } from '@fluentui/react'
import { ControlledTreeEnvironment, Tree } from 'react-complex-tree';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import 'react-complex-tree/lib/style-modern.css';
import DialogCategory from '../Extentions/categories/DialogCategory'
const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }
const syncIcon = {iconName: 'Sync'}
const data = require('../../../config/data.json')
@inject('store')
@observer
class CategoriesTree extends Component {
  
  constructor (props) {
    super(props)
    this.esObjectStore = this.props.store.esObjectStore
    this.categoriesStore = this.props.store.categoriesStore
    this.categoriesTreeStore = this.props.store.categoriesTreeStore
    this.genartsStore = this.props.store.genartsStore
    this.state = {}
  }

  showEditDialog = (vehicle_type, level, item, parent) => {
    if (this.categoriesTreeStore.modifiedCategories.length > 0) {
      alert(this.props.t('common:CATEGORIES_SAVE_TREE_MSG'))
      return
    }
    let node = item !== undefined ? item.data : undefined
    const parentId = parent?.data.leafid.toString()
    console.log(node)
    if (node === undefined) {
      const ids = [...this.categoriesTreeStore.mapCategories.keys()].map(leafId => Number(leafId))
      const maxId = ids.length > 0 ? ((Math.max(...ids)) + 1) : 100000
      let sort
      if (parent === undefined) { // is root child
        const root = this.categoriesTreeStore.items.get(vehicle_type)['root']
        const lastSortRoot = root !== undefined && root.children.length > 0 ? 
          this.categoriesTreeStore.items.get(vehicle_type)[root.children[root.children.length -1]].data.sort.split('-')[0] : 0
        sort = (Number(lastSortRoot) + 1).toString().padStart(3, '0') + '-00-00-00-00-00'
      } else {
        let sortCounter = parent.data.sort.split('-')
        if (parent.children.length > 0) {
          const lastSortChild = this.categoriesTreeStore.items.get(vehicle_type)[parent.children[parent.children.length -1]].data.sort.split('-')
          sortCounter[Number(level)+1]= (Number(lastSortChild[Number(level)+1]) + 1).toString().padStart(2, '0') 
        } else {
          sortCounter[Number(level)+1]= "1".padStart(2, '0') 
        }
        sort = sortCounter.join('-')
      }
      node = {
        parentid: parentId !== undefined ? parentId : (this.categoriesTreeStore.rootNodes.get(vehicle_type).length > 0 ? this.categoriesTreeStore.rootNodes.get(vehicle_type)[0].leafid : '10000000'),
        leafid: maxId.toString(),
        open: '',
        service: '',
        is_check : null,
        click_group: null,
        id_dlnr: '',
        id_product_brand: '',
        external_service: null,
        external_service_attribute: null,
        link: null,
        vc: vehicle_type,
        qflag: '',
        qshow: '',
        qcol:'',
        qrow:'',
        qlevel:'',
        qsort:'',
        classic_col: '',
        assembly_parts: null,
        labour_time: null,
        sag_code: '',
        sort: sort,
        genarts: [],
        node_name_trans: {},
        node_keywords_trans: {},
        isEdit: false
      }
    } else {
      node.isEdit = true
      this.categoriesTreeStore.editIndex = item.index
      this.categoriesTreeStore.origEditNode = JSON.stringify(item)
    }
    if (node.node_name_trans === undefined) {
      node.node_name_trans = {}
    }
    if (node.node_keywords_trans === undefined) {
      node.node_keywords_trans = {}
    }
    // populate genartsItems in detailList
    this.genartsStore.genartsItems = []
    node.genarts.forEach(genart => {
      this.genartsStore.genartsItems.push({...genart, gatxt: genart.gaid + '-' + this.esObjectStore.mapGenarts.get(this.esObjectStore.defaultLang).get(genart.gaid)})
    })   
    this.categoriesTreeStore.categoriesOptions = []
    const categories = node.parentid !== undefined ? this.categoriesTreeStore.categories.filter(category => category.parentid === node.parentid) : this.categoriesTreeStore.categories
    categories.forEach(category => {
      // set optionsForDopdrown
      this.categoriesTreeStore.categoriesOptions.push({
        key: category.leafid,
        value: category.leafid,
        text: `${category.node_name_trans !== undefined ? category.node_name_trans[this.esObjectStore.defaultLang] : ''}`
      })
    })
    this.categoriesTreeStore.handleHideDialog(node)
  }

  showDelDialog = item => {
    if (this.state.loadingOnSave) {
      return
    }
    if (this.categoriesTreeStore.modifiedCategories.length > 0) {
      alert(this.props.t('common:CATEGORIES_SAVE_TREE_MSG'))
      return
    }
    this.setState({
      hideDelDialog: false,
      delNode: item
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.setState({loadingOnSave: true})
    this.categoriesTreeStore.removeNode(this.state.delNode.data).then(() => {
      this.setState({
        hideDelDialog: true,
        delNode: undefined,
        loadingOnSave: false
      })
    })
  }

  getNodeName = (node) => {
    return node.node_name_trans !== undefined ? (node.node_name_trans[this.esObjectStore.defaultLang]) : ''
  }

  updateSort = (targetParent, vehicle_type) => {
    const parent_sort = this.categoriesTreeStore.items.get(vehicle_type)[targetParent].data.sort
    let sortCounter = parent_sort.split('-')
    let childLevel
    for (let index = 0; index < sortCounter.length; index++) {
      const sortElement = sortCounter[index];
      if (sortElement === '00' || sortElement === '000') {
        childLevel = index
        break
      }
    }
    for (let index = 0; index < this.categoriesTreeStore.items.get(vehicle_type)[targetParent].children.length; index++) {
      const child = this.categoriesTreeStore.items.get(vehicle_type)[targetParent].children[index]
      sortCounter[childLevel] = (index + 1).toString().padStart(targetParent === 'root' ? 3 : 2, '0') 
      this.categoriesTreeStore.items.get(vehicle_type)[child].data.sort = sortCounter.join('-')
      const it = this.categoriesTreeStore.mapCategories.get(child)
      if (it !== undefined) {
        it.parentid = targetParent === 'root' ? this.categoriesTreeStore.rootNodes.get(vehicle_type)[0].leafid : targetParent
        it.sort = this.categoriesTreeStore.items.get(vehicle_type)[child].data.sort
        if (this.categoriesTreeStore.modifiedCategories.findIndex(cat => cat.id === it.id) === -1) {
          this.categoriesTreeStore.modifiedCategories.push(it)
        }
      }
      this.updateSort(child, vehicle_type)
    }
  }

  onDrop = (item, target) => {
    const vehicle_type = item.data.vc
    const parent = Object.values(this.categoriesTreeStore.items.get(vehicle_type)).find(potentialParent =>
      potentialParent.children?.includes(item.index)
    )
    if (!parent || !parent.children) {
      return
    }
    if (target.targetType === 'item' || target.targetType === 'root') {
      if (target.targetItem === parent.index) {
        // NOOP
      } else {
        this.categoriesTreeStore.items.get(vehicle_type)[parent.index].children = parent.children.filter(child => child !== item.index)
        this.categoriesTreeStore.items.get(vehicle_type)[target.targetItem].children = [...(this.categoriesTreeStore.items.get(vehicle_type)[target.targetItem].children ?? []), item.index]
        this.categoriesTreeStore.items.get(vehicle_type)[target.targetItem].isFolder=true
        this.updateSort(target.targetItem, vehicle_type)
      }
    } else {
      const newParent = this.categoriesTreeStore.items.get(vehicle_type)[target.parentItem];
      const newParentChildren = [...(newParent.children ?? [])].filter(
        child => child !== item.index
      )
      if (target.parentItem === item.index) {
        // Trying to drop inside itself
        return
      }
      if (target.parentItem === parent.index) {
        const isOldItemPriorToNewItem =
          ((newParent.children ?? []).findIndex(
            child => child === item.index
          ) ?? Infinity) < target.childIndex;
        newParentChildren.splice(
          target.childIndex - (isOldItemPriorToNewItem ? 1 : 0),
          0,
          item.index
        );
        this.categoriesTreeStore.items.get(vehicle_type)[target.parentItem].children = newParentChildren
        this.updateSort(target.parentItem, vehicle_type)
      } else {
        newParentChildren.splice(target.childIndex, 0, item.index);
        this.categoriesTreeStore.items.get(vehicle_type)[parent.index].children = parent.children.filter(child => child !== item.index)
        this.categoriesTreeStore.items.get(vehicle_type)[target.parentItem].children = newParentChildren
        this.categoriesTreeStore.items.get(vehicle_type)[target.parentItem].isFolder=true
        this.updateSort(target.parentItem, vehicle_type)
      }
    }
    console.log(this.categoriesTreeStore.modifiedCategories)
  }

  handleLinkClick = (item) => {
    this.categoriesTreeStore.selectedTreeKey = item.props.itemKey
  }

  handleCheck = (event, newValue) => {
    this.categoriesTreeStore.expandedItems = []
    this.categoriesTreeStore.quickChecked = []
    if (newValue) {
      this.categoriesTreeStore.quickChecked.push(event.target.id)
      const nodeTree = this.categoriesTreeStore.items.get(this.categoriesTreeStore.selectedTreeKey)[event.target.id]
      if (nodeTree !== undefined) {
        this.categoriesTreeStore.selectItem(nodeTree)
      }
    } else { // move scroll to top page
      let section = document.getElementById('vc_tabs')
      if (section) {
        section.scrollIntoView()
      }
    }
  }
  render () {
    const { t, store: { categoriesTreeStore: { items, hideDialog, loadingOnSave, mapQuickCategoriesByCol } } } = this.props
    const { delNode, hideDelDialog } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const growingStyles = {
      root: {
        display: 'flex',
        height: '50%',
        paddingRight: 30,
        minWidth: 400
      }
    }
    return (
      <div className='page-container'>
        {hideDialog === false && <DialogCategory />}
        <Stack horizontal tokens={stackTokens}>
          {loadingOnSave && <Spinner size={SpinnerSize.large} /> } 
          <Pivot linkFormat="tabs"  onLinkClick={this.handleLinkClick} id='vc_tabs'> 
            {data.vc.filter(key => Object.keys(items.get(key)).length > 0).map(key => {
              return (
                <PivotItem headerText={key} itemKey={key} key={key} >
                  <Stack horizontal tokens={stackTokens}>
                    <Stack.Item styles={growingStyles}>
                      <Stack verticall tokens={stackTokens}>
                        <Stack horizontal tokens={stackTokens}>
                          <IconButton
                            iconProps={addIcon}
                            title={t('common:CATEGORY_ADD')}
                            onClick={() => this.showEditDialog(key)}
                          />
                          <IconButton
                            iconProps={syncIcon}
                            title={t('common:TREE_SAVE')}
                            onClick={() => this.categoriesTreeStore.saveNodes()}
                          />
                        </Stack>
                        <ControlledTreeEnvironment
                          canDragAndDrop={true}
                          canDropOnFolder={true}
                          canDropOnNonFolder={true}
                          canReorderItems={true}
                          canSearch={true}
                          items={items.get(key)}
                          getItemTitle={item => 
                            this.getNodeName(item.data)
                          }
                          viewState={{
                            'tree-1': {
                              focusedItem: this.categoriesTreeStore.focusedItem,
                              selectedItems: this.categoriesTreeStore.selectedItems,
                              expandedItems: this.categoriesTreeStore.expandedItems
                            },
                          }}
                          onSelectItems={(items) => {    
                            this.categoriesTreeStore.selectedItems = items
                          }} 
                          onDrop={(itemsToMove, target) => {
                            itemsToMove.length > 0 && this.onDrop(itemsToMove[0], target)
                          }}
                          onFocusItem={item => 
                            this.categoriesTreeStore.focusedItem = item.index
                          }
                          onExpandItem={item => {
                            this.categoriesTreeStore.expandedItems = [...this.categoriesTreeStore.expandedItems, item.index]
                          }
                          }
                          onCollapseItem={item =>
                            this.categoriesTreeStore.expandedItems = this.categoriesTreeStore.expandedItems.filter(expandedItemIndex => expandedItemIndex !== item.index) 
                          }
                          renderItem={({ item, depth, children, title, context, arrow }) => {
                            const InteractiveComponent = context.isRenaming ? 'div' : 'button';
                            const type = context.isRenaming ? undefined : 'button';
                            return (
                              <li
                                {...(context.itemContainerWithChildrenProps)}
                                className="rct-tree-item-li"
                              >
                                <div id={`node_${item.index}`}
                                  {...(context.itemContainerWithoutChildrenProps)}
                                  style={{ paddingLeft: `${(depth + 1) * 12}px` }}
                                  className={[
                                    'rct-tree-item-title-container',
                                    item.isFolder && 'rct-tree-item-title-container-isFolder',
                                    context.isSelected && 'rct-tree-item-title-container-selected',
                                    context.isExpanded && 'rct-tree-item-title-container-expanded',
                                    context.isFocused && 'rct-tree-item-title-container-focused',
                                    context.isDraggingOver &&
                                      'rct-tree-item-title-container-dragging-over',
                                    context.isSearchMatching &&
                                      'rct-tree-item-title-container-search-match',
                                  ].join(' ')}
                                >
                                  {arrow}
                                  <InteractiveComponent
                                    type={type}
                                    {...(context.interactiveElementProps)}
                                    className="rct-tree-item-button"
                                  >
                                    {title}  {/*{item.data.vc} {item.data.sort} ({item.data.sag_code})  ( {item.data.leafid} - {item.data.parentid}) */}
                                  </InteractiveComponent>
                                  <IconButton iconProps={addIcon} title={t('common:NODE_ADD')} onClick={() => this.showEditDialog(key, depth, undefined, item)} />
                                  <IconButton iconProps={editIcon} title={t('common:NODE_EDIT')} onClick={() => this.showEditDialog(key, depth, item)} />
                                  <IconButton iconProps={delIcon} title={t('common:NODE_DELETE')} onClick={() => this.showDelDialog(item)} />
                                </div>
                                {children}
                              </li>
                            );
                          }}
                        >
                          <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" />
                        </ControlledTreeEnvironment>
                      </Stack>
                    </Stack.Item>  
                    <Stack.Item styles={growingStyles}>
                      <Stack tokens={stackTokens}>
                        <Label style={{fontSize: 13, paddingBottom: 10}}>{t('common:QUICK_CLICK')}</Label>
                        <div style={{display: 'flex'}}>
                          {mapQuickCategoriesByCol[key] !== undefined && Array.from(mapQuickCategoriesByCol[key].keys()).map(qcol =>
                            <div key={qcol} style={{ flexBasis: 0, fontFamily: 'sans-serif', fontSize: 11.2, paddingRight: 30, minWidth: '15vw' }}>
                              {mapQuickCategoriesByCol[key].get(qcol).map(category => 
                                <div key={category.parent.leafid} title={'qrow: ' + category.parent.qrow + ' qcol: ' + category.parent.qcol} style={{ paddingBottom: 15 }}>
                                  <b> {this.getNodeName(category.parent)}</b>
                                  {category.children.map(child =>
                                    <div key={child.leafid} title={'qsort: ' + child.qsort}>
                                      <Stack horizontal tokens={stackTokens}>
                                        <Checkbox className='quickClass' id={child.leafid} checked={this.categoriesTreeStore.quickChecked.includes(child.leafid)}  onChange={this.handleCheck} />
                                        {this.getNodeName(child)}
                                      </Stack>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </PivotItem>
              );
            })}
          </Pivot>
        </Stack>
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:NODE_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:NODE_DELETE_QESTION') + (delNode !== undefined ? this.getNodeName(delNode.data) : '') + '?'
          }}
          modalProps={{
            isBlocking: true,
            dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog> 
      </div>
    )
  }
}

export { CategoriesTree }
export default withTranslation(['common'], { wait: true })(withMainContainer(CategoriesTree))