import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import withMainContainer from '../Extentions/withMainContainer'
import { withTranslation } from 'react-i18next'
import { Stack, IconButton, ConstrainMode, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react'
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import 'react-complex-tree/lib/style-modern.css';
import DialogTile from '../Extentions/unitree/tiles/DialogTile'
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';

const addIcon = { iconName: 'Add' }
const editIcon = { iconName: 'SingleColumnEdit' }
const delIcon = { iconName: 'ErrorBadge' }

const theme = getTheme()
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})

@inject('store')
@observer
class Tiles extends Component {
  constructor (props) {
    super(props)
    this.esObjectStore = this.props.store.esObjectStore
    this.unitreeStore = this.props.store.unitreeStore
    this.nodeStore = this.props.store.nodeStore
    this.tileStore = this.props.store.tileStore
    this.tilesExternalStore = this.props.store.tilesExternalStore
    this.selection = new Selection();
    this.dragDropEvents = this.getDragDropEvents()
    this.draggedItem = undefined
    this.draggedIndex = -1
    this.state = {
      hideDelDialog: true
    }
  }
  
  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.tileStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.tileStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }
  
  showEditDialog = (tile, index) => {
    if (tile === undefined) {
        const tileIds = this.tileStore.tiles.map(item => Number(item.tile_id))
        const sort = this.tileStore.tiles.map(item => Number(item.tile_sort))
        tile = {
            tile_id: tileIds.length > 0 ? ((Math.max(...tileIds)) + 1).toString() : '101',
            tile_name_trans: {},
            tile_image: '',
            tile_type: '',
            tile_external: [],
            tile_sort: (sort.length > 0 ? Math.max(...sort) + 1 : 1).toString(),
            isEdit: false,
        }
    } else {
        tile.isEdit = true
        this.tileStore.editIndex = index,
        this.tileStore.origEditTile = JSON.stringify(tile)
    }
    if (tile.tile_name_trans === undefined) {
        tile.tile_name_trans = {}
    }
    // populate external tiles
    this.tilesExternalStore.externalItems = []
    tile.tile_external.forEach(external => {
        this.tilesExternalStore.externalItems.push(external)
    })
    
    this.tileStore.handleShowDialog(tile)
  }

  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => {
    this.tileStore.removeTile(this.state.delIndex).then(()=> this.props.store.treeStore.updateLastDateAndUserOnTree(this.tileStore.tileDB.tree_id))
    this.setState({
      hideDelDialog: true,
      delIndex: undefined
    })
  }

  getTileName = (tile) => {
    return tile !== undefined && tile.tile_name_trans !== undefined ? (tile.tile_name_trans[this.esObjectStore.defaultLang]) : ''
  }

  handleItemColumn = (item, index, column) => {
    const { t } = this.props
    if (column.fieldName === 'tile_name_trans') {
      return (
        item.tile_name_trans !== undefined ? Object.entries(item.tile_name_trans).map(([key, value]) => (<div key={key}><b>{key}</b>: {value}</div>)) : ''
      )
    } else if (column.fieldName === 'tile_image' && item.tile_image) { 
      return (
        <img src={item.tile_image} alt={item.tile_image} width="75" height="75"/>
      )
    } else if (column.fieldName === 'buttons') {
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            iconProps={editIcon}
            title={t('common:TILE_EDIT')}
            onClick={() => this.showEditDialog(item, index)}
          />
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:TILE_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  getDragDropEvents = () => {
    return {
      canDrop: (dropContext, dragContext) => {
        return true
      },
      canDrag: (item) => {
        return true
      },
      onDragEnter: (item, event) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item, event) => {
        return
      },
      onDrop: (item, event) => {
        if (this.draggedItem) {
          this.insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        this.draggedItem = item;
        this.draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        this.draggedItem = undefined;
        this.draggedIndex = -1;
      },
    };
  }

  insertBeforeItem = async(item) => {
    const draggedItems = this.selection.isIndexSelected(this.draggedIndex)
      ? (this.selection.getSelection()) : [this.draggedItem]

    const insertIndex = this.tileStore.tiles.indexOf(item)
    const items = this.tileStore.tiles.filter(itm => draggedItems.indexOf(itm) === -1)

    items.splice(insertIndex, 0, ...draggedItems)
    this.tileStore.tiles = items
    
    // update sort field
    const sort = this.tileStore.tiles.map(item => Number(item.tile_sort))
    const minsort = sort.length > 0 ? Math.min(...sort) : 1
    for (let index = 0; index < this.tileStore.tiles.length; index++) {
      this.tileStore.tiles[index].tile_sort = (minsort+index).toString()
    }
    await this.tileStore.saveTiles().then(()=> this.props.store.treeStore.updateLastDateAndUserOnTree(this.tileStore.tileDB.tree_id))
  }

  render () {
    const { t, store: { tileStore: { tiles, showDialog, loadingTiles } } } = this.props
    const { delIndex, hideDelDialog } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    const growingStyles = {
      root: {
        display: 'flex',
        height: '50%'
      }
    }
    return (
    <React.Fragment>
      {loadingTiles && <Spinner size={SpinnerSize.large} /> }
      {showDialog && <DialogTile />}
      <Stack verticall tokens={stackTokens}>
        <IconButton
            iconProps={addIcon}
            title={t('common:TILE_ADD')}
            onClick={() => this.showEditDialog()}
        />
        <DetailsList ariaLabelForGrid='Unitree'
            items={tiles} 
            setKey='set'
            columns={this.setHeaderColumns()}
            layoutMode={DetailsListLayoutMode.justified}
            selection={this.selection}
            selectionMode={SelectionMode.none}
            enterModalSelectionOnTouch={true}
            selectionPreservedOnEmptyClick={true}
            constrainMode={ConstrainMode.unconstrained}
            onRenderItemColumn={this.handleItemColumn}
            dragDropEvents={this.dragDropEvents}
        />
      </Stack>   
      <Dialog
        hidden={hideDelDialog}
        onDismiss={this.handleCloseDelDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: t('common:TILE_DELETE'),
          closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          subText: t('common:TILE_DELETE_QESTION') + (delIndex !== undefined ? this.getTileName(tiles[delIndex]) : '') + '?'
        }}
        modalProps={{
          isBlocking: true,
          dragOptions: true,
          styles: { main: { maxWidth: 450 } }
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
          <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
        </DialogFooter>
      </Dialog> 
    </React.Fragment>
    )
  }
}

export { Tiles }
export default withTranslation(['common'], { wait: true })(withMainContainer(Tiles))