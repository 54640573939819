import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Selection
  } from '@fluentui/react/lib-commonjs/DetailsList'
import { Label, ScrollablePane, ScrollbarVisibility, Stack, ConstrainMode, IconButton, PrimaryButton, DefaultButton, Checkbox,
  Dropdown, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import withMainContainer from '../withMainContainer'
import { withTranslation } from 'react-i18next'
import { Dropdown as LookupDropdown} from 'semantic-ui-react'
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';

const addIcon = { iconName: 'Add' }
const delIcon = { iconName: 'ErrorBadge' }
const data = require('../../../../config/data.json')

const theme = getTheme()
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})
@inject('store')
@observer
class Sorts extends Component {
  constructor (props) {
    super(props)
    this.esObjectStore = this.props.store.esObjectStore
    this.sortsStore = this.props.store.sortsStore
    this.sortPriorities = this.props.store.sortPriorities
    this.brandPriorityStore = this.props.store.brandPriorityStore
    this.selection = new Selection()
    this.dragDropEvents = this.getDragDropEvents()
    this.draggedItem = undefined
    this.draggedIndex = -1
    this.state = {
      hideDelDialog: true,
      showPrioDialog: false,
      selectedMandant: data.mandant.sort[data.mandant_type.sort[0]][this.brandPriorityStore.selectedESObject.shop_name][0]
    }
    this.sortsStore.populateMandants(data.mandant_type.sort[0])
  }

  setHeaderColumns () {
    const { t } = this.props
    const columnsName = []
    this.sortsStore.columns.forEach((col, index) => {
      col.name = col.key !== 'buttons' ? t(`common:${this.sortsStore.constHeader[index].text}`) : ''
      columnsName.push(col)
    })
    return columnsName
  }

  handleItemColumn = (item, index, column) => {
    const { t } = this.props
    if (column.fieldName === 'buttons') {
      return (
        <Stack horizontal verticalAlign='start' verticalFill='true'>
          <IconButton
            onClick={() => this.showDelDialog(index)}
            iconProps={delIcon}
            title={t('common:SORT_DELETE')}
          />
        </Stack>
      )
    }
    return item[column.fieldName]
  }

  showInsertDialog = (item, index) => {
    if (item === undefined) {
      item = {
        priorities: [],
        isEdit: false,
      }
    }
    this.sortsStore.handleDialog(item)
  }

  handleCloseEditDialog = () => {
    this.sortsStore.checked = []
    this.sortsStore.handleDialog()
  }

  handleEdit = () => {
    if (!this.sortsStore.item.brand_id) {
      alert(this.props.t('common:MSG_SELECT_BRAND'))
      return
    }
    if (this.sortsStore.checked.length < 1) {
      alert(this.props.t('common:MSG_FILL_PRIORITY_FIELDS'))
      return
    }
    this.sortsStore.checked.forEach(mandant => {
      const item = {
        sort: {}
      }
      let indexBrand = -1
      if (this.sortsStore.allPrioritiesItemsByMandant.has(mandant)) {
        indexBrand = this.sortsStore.allPrioritiesItemsByMandant.get(mandant).findIndex(item => item.brand_id === this.sortsStore.item.brand_id)
      } 
      const index =  this.sortsStore.item.priorities.findIndex(it => it.mandant === mandant)
      if (indexBrand < 0 && index < 0) { // add only if the brand doesn't exist for the mandant and the mandant doesn't exist for the brand
        if (!this.sortsStore.allPrioritiesItemsByMandant.has(mandant)) {
          this.sortsStore.allPrioritiesItemsByMandant.set(mandant, [])
        }
        const maxPrio = this.sortsStore.allPrioritiesItemsByMandant.get(mandant).reduce((acc, curr) => Math.max(acc, Number(curr.prio)), 0)
        
        item.prio = (maxPrio + 1).toString()
        item.sort["1"] = item.prio
        item.mandant = mandant
        item.type = Object.keys(data.mandant.sort).find(key => data.mandant.sort[key][this.brandPriorityStore.selectedESObject.shop_name].includes(mandant)) 
        item[item.type] = mandant
        this.sortsStore.item.priorities.push(item)
      
        this.sortsStore.allPrioritiesItemsByMandant.get(mandant).push({...item, brand_id: this.sortsStore.item.brand_id, brandName: this.esObjectStore.mapManufacturers.get(this.sortsStore.item.brand_id) !== undefined ? this.sortsStore.item.brand_id + ' - ' + this.esObjectStore.mapManufacturers.get(this.sortsStore.item.brand_id) : this.sortsStore.item.brand_id})  
      } 
    }) 
    this.sortsStore.checked = []
    this.sortsStore.handleDialog()
  }

  showDelDialog = index => {
    this.setState({
      hideDelDialog: false,
      delIndex: index
    })
  }

  handleCloseDelDialog = () => {
    this.setState({ hideDelDialog: true })
  }

  handleDelete = () => { 
    this.sortsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).splice(this.state.delIndex, 1)
    this.setState({
      hideDelDialog: true,
      delIndex: undefined
    })
  }

  getDragDropEvents = () => {
    return {
      canDrop: (dropContext, dragContext) => {
        return true
      },
      canDrag: (item) => {
        return true
      },
      onDragEnter: (item, event) => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass
      },
      onDragLeave: (item, event) => {
        return
      },
      onDrop: (item, event) => {
        if (this.draggedItem) {
          this.insertBeforeItem(item);
        }
      },
      onDragStart: (item, itemIndex, selectedItems, event) => {
        this.draggedItem = item;
        this.draggedIndex = itemIndex;
      },
      onDragEnd: (item, event) => {
        this.draggedItem = undefined;
        this.draggedIndex = -1;
      },
    };
  }

  insertBeforeItem = async(item) => {
    const draggedItems = this.selection.isIndexSelected(this.draggedIndex)
      ? (this.selection.getSelection()) : [this.draggedItem]

    const insertIndex = this.sortsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).indexOf(item)
    const items = this.sortsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant).filter(itm => draggedItems.indexOf(itm) === -1)
   
    items.splice(insertIndex, 0, ...draggedItems)
    this.sortsStore.allPrioritiesItemsByMandant.set(this.state.selectedMandant, items)
      
    let modifiedItems = this.sortsStore.allPrioritiesItemsByMandant.get(this.state.selectedMandant)
    const sort = modifiedItems.map(item => Number(item.prio))
    const minsort = sort.length > 0 ? Math.min(...sort) : 1
    let index = -1
    modifiedItems.map(item => { 
      index++
      return item.prio = (minsort+index).toString()
    })
    this.sortsStore.allPrioritiesItemsByMandant.set(this.state.selectedMandant, modifiedItems.slice().sort((p1,p2) => p1.prio - p2.prio))
  }

  render () {
    const { t, store: { sortsStore: { allPrioritiesItemsByMandant, showDialog, item, mandant_type, mandant },
      esObjectStore : { manufacturers, mapManufacturers } } }  = this.props
    const { hideDelDialog, delIndex } = this.state
    const stackTokens = {
      childrenGap: 5
    }
    return (
      <React.Fragment>
        <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} styles={{root: {width: '100%', paddingLeft: 10}}}>
            <Label>{t('common:SORTS')}</Label>
            <Stack horizontal tokens={stackTokens}>
              <Stack.Item styles={{root: {display: 'flex', width: '20%', height: '100%'}}}>
                <IconButton
                  iconProps={addIcon}
                  title={t('common:SORT_ADD')}
                  onClick={() => this.showInsertDialog()}
                />
              </Stack.Item>
              <Stack.Item styles={{root: {display: 'flex', width: '80%', height: '100%'}}}>
                <Dropdown id='mandant'
                    label={t('common:MANDANT')}
                    defaultSelectedKey={mandant.length > 0 ? mandant[0].key : ''}
                    options={mandant}
                    onChange={(event, { key }) => {
                      // populate priorities by mandant
                      this.setState({selectedMandant: key})
                    }}
                    styles={{dropdown: { width: 130, height: 20, float: 'right' },
                        caretDownWrapper: { height:20, lineHeight: 'normal' },
                        dropdownItem: 'dropdownItem',
                        dropdownItemSelected: 'dropdownItem',
                        root: { paddingLeft: 10}  
                    }}
                />
              </Stack.Item>
            </Stack>
            <div style={{ position: "relative", height: 450 }} id='brandsListItem'>    
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.always} >
                <DetailsList id='sortsDetailsList'
                  items={allPrioritiesItemsByMandant.get(this.state.selectedMandant) !== undefined ? allPrioritiesItemsByMandant.get(this.state.selectedMandant) : []} 
                  setKey='set'
                  columns={this.setHeaderColumns()}
                  layoutMode={DetailsListLayoutMode.justified}
                  selection={this.selection}
                  selectionMode={SelectionMode.none}
                  enterModalSelectionOnTouch={true}
                  selectionPreservedOnEmptyClick={true}
                  constrainMode={ConstrainMode.unconstrained}
                  onRenderItemColumn={this.handleItemColumn}
                  dragDropEvents={this.dragDropEvents}
                />
              </ScrollablePane>   
            </div>
          </Stack>
        { showDialog && <Dialog
          minWidth={700}
          maxWidth={900}
          hidden={!showDialog}
          onDismiss={this.handleCloseEditDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: item !== undefined && item.isEdit === true ? t('common:SORT_EDIT') : t('common:SORT_ADD'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
          }}
          modalProps={{
            isBlocking: true, dragOptions: true
          }}
        >
        <Stack verticall tokens={stackTokens}>
          <Stack.Item verticalAlign='start' verticalFill='true' tokens={stackTokens}>
            <Stack horizontal tokens={stackTokens}>
              <Label>{t('common:BRAND')}</Label>
              <LookupDropdown
                placeholder={t('common:BRAND')}
                defaultValue={item !== undefined ? item.brand_id : ''}
                fluid
                search
                selection
                closeOnChange
                clearable
                options={manufacturers}
                onChange={(e, { value }) => {
                    item.brand_id = value
                    item.brandName = value + '-' + mapManufacturers.get(value)
                }}
              />
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{root: {width: '100%', height: '100%'}}}>
            { Object.keys(data.mandant.sort).map((key, index) => (
              <Stack key={index+"_"+key} verticall tokens={stackTokens}>
                <Label> {t('common:MANDANT')} {key} </Label> 
                <Stack horizontal tokens={stackTokens} style={{columnCount: 3, display: 'block'}}>
                  {data.mandant.sort[key][this.brandPriorityStore.selectedESObject.shop_name].map((item, index) => (
                    <div key={index+"_"+item} style={index !== 0 ? {padding:5} : {marginLeft: 5, padding: 5}}>
                      <Checkbox key={item} id={item} label={item} checked={this.sortsStore.checked.includes(item)} onChange={this.sortsStore.handleCheck} />
                    </div>
                ))}
                </Stack>
              </Stack>
            ))}
            </Stack.Item>
        </Stack> 
          <DialogFooter>
            <PrimaryButton onClick={this.handleEdit} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleCloseEditDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
        }
        <Dialog
          hidden={hideDelDialog}
          onDismiss={this.handleCloseDelDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('common:SORT_DELETE'),
            closeButtonAriaLabel: t('common:BUTTON_CLOSE'),
            subText: t('common:BRAND_DELETE_QESTION') + ' (' + (allPrioritiesItemsByMandant.get(this.state.selectedMandant) !== undefined && allPrioritiesItemsByMandant.get(this.state.selectedMandant)[delIndex] !== undefined ? allPrioritiesItemsByMandant.get(this.state.selectedMandant)[delIndex].brandName : '') + ')'
          }}
          modalProps={{
            isBlocking: true, dragOptions: true,
            styles: { main: { maxWidth: 450 } }
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={this.handleDelete} text={t('common:BUTTON_DELETE')} />
            <DefaultButton onClick={this.handleCloseDelDialog} text={t('common:BUTTON_CANCEL')} />
          </DialogFooter>
        </Dialog>
      </React.Fragment> 
    )
  }
}

export { Sorts }
export default withTranslation(['common'], { wait: true })(withMainContainer(Sorts))