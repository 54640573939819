import React, { Component } from 'react'
import { Dialog, DialogType, DialogFooter, TextField, Stack, PrimaryButton, DefaultButton, Dropdown, Pivot, PivotItem, } from '@fluentui/react'
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import NodeFilters from './NodeFilters'
import Exclude from './Exclude'
import Include from './Include'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'

@inject('store')
@withTranslation(['common'])
@observer
class DialogNode extends Component {
  constructor (props) {
    super(props)
    this.nodeStore = this.props.store.nodeStore
    this.dialogNodeStore = this.props.store.dialogNodeStore
    this.esObjectStore = this.props.store.esObjectStore
  }

  handleClose = () => {
    this.nodeStore.handleHideDialog()
    const { editIndex, origEditNode } = this.nodeStore
    if (origEditNode !== '') {
      this.nodeStore.items[editIndex] = JSON.parse(origEditNode)
    }
    this.nodeStore.origEditNode = ''
    this.nodeStore.editIndex = undefined
  }

  handleChange = (key, value) => {
    const editNode = this.nodeStore.editNode
    if (editNode !== undefined) {
      editNode[key] = value
    }
  }

  handleSave = () => {
    if (this.nodeStore.loadingOnSave) {
      return
    }
    let empty = false
    this.esObjectStore.langs.forEach(lang => {
      if (this.nodeStore.editNode.node_name_trans[lang] === '' || this.nodeStore.editNode.node_name_trans[lang] === undefined) {
        empty = true
      }
    })
    if (empty) {
      return
    }
    this.nodeStore.saveNode().then(()=> this.props.store.treeStore.updateLastDateAndUserOnTree(this.nodeStore.nodeDB.tree_id))
  }

  render () {
    const { t, store: { nodeStore: { hideDialog, editNode, loadingOnSave },
      dialogNodeStore: { nodeExternalServiceTypes },
      esObjectStore : { langs } } } = this.props
    const stackTokens = { childrenGap: 10 }
    const growingStyles = {
        root: {
          display: 'flex',
          height: '50%',
          width: 900,
        }
      }
    const dialogTextFieldsStyles = {
      fieldGroup: { width: 280, height: 20, float: 'right' }
    }
    return (
      <div>
        <Dialog
          minWidth={1000}
          maxWidth={1200}
          hidden={hideDialog}
          onDismiss={this.handleClose}
          dialogContentProps={{
            type: DialogType.normal,
            title: editNode !== undefined && editNode.isEdit ? t('common:NODE_EDIT') : t('common:NODE_ADD')
          }}
          modalProps={{ isBlocking: true, dragOptions: true,
            styles: { main: { maxHeight: 680 } } }}
        >
        {loadingOnSave && <Spinner size={SpinnerSize.large} /> }
        <Stack horizontal tokens={stackTokens}>
        <Stack.Item styles={growingStyles}>   
          <Stack verticalAlign='start' verticalFill='true' tokens={stackTokens} styles={{root: {width: '100%'}}}>
            {langs.map(lang => {
              return (
              <TextField label={t('common:TREE_NAME')+ ' ' + lang} id={'node_name_'+ lang} key={'node_name_'+ lang} 
                defaultValue={editNode !== undefined && editNode.node_name_trans !== undefined? editNode.node_name_trans[lang] : ''} 
                required type='text' onGetErrorMessage={ value => { if (value === '') return lang + t('common:TREE_NAME_DIALOG_INPUT_ERR_MSG') }} 
                onChange={(event, newValue) => {
                  if (editNode !== undefined) {
                    editNode.node_name_trans[lang] = newValue
                  }
                }
                } styles={dialogTextFieldsStyles}/>
              )})}
              <Checkbox label={t('common:NODE_OPEN')} checked={editNode !== undefined && editNode.open === 1 ? true : false} onChange={(event, newValue) => this.handleChange('open', newValue ? 1 : 0)} />
              <Dropdown id='node_external_service_type'
                label={t('common:TREE_EXTERNAL_SERVICE_TYPE')}
                defaultSelectedKey={editNode !== undefined ? editNode.node_external_type : ''}
                options={nodeExternalServiceTypes}
                onChange={(event, { key }) => this.handleChange('node_external_type', key !== '' ? key : null)}
                styles={{dropdown: { width: 280, height: 20, float: 'right' },
                caretDownWrapper: { height:20, lineHeight: 'normal' },
                dropdownItem: 'dropdownItem',
                dropdownItemSelected: 'dropdownItem' }}
            />
             {langs.map(lang => {
              return (
              <TextField label={t('common:TREE_EXTERNAL_SERVICE_ATTRIBUTE')+ ' ' + lang} id={'node_external_service_attribute_'+ lang} key={'node_external_service_attribute_'+ lang} 
                defaultValue={editNode !== undefined && editNode.node_external_service_attribute_trans ? editNode.node_external_service_attribute_trans[lang] : ''} 
                type='text' 
                onChange={(event, newValue) => {
                  if (editNode !== undefined) {
                    editNode.node_external_service_attribute_trans[lang] = newValue
                  }
                }
                } styles={dialogTextFieldsStyles}/>
              )})}
              {langs.map(lang => {
              return (
              <TextField label={t('common:NODE_KEYWORDS')+ ' ' + lang} id={'node_keywords_'+ lang} key={'node_keywords_'+ lang} 
                value={editNode !== undefined && editNode.node_keywords_trans && editNode.node_keywords_trans[lang] !== undefined ? editNode.node_keywords_trans[lang] : ''}
                type='text'  multiline rows={3}
                onChange={(event, newValue) => {
                  if (editNode !== undefined) {
                    editNode.node_keywords_trans[lang] = newValue
                  }
                }} styles={dialogTextFieldsStyles}/>
              )})}
          </Stack>
        </Stack.Item>  
        <Stack.Item styles={{root: {width: '100%', height: '100%'}}}>
          <Pivot linkFormat="tabs"  onLinkClick={() => {}}>
            <PivotItem headerText='Filters' itemKey='filters' key='filters'>
              <div id='nodeFilters'><NodeFilters /></div>
            </PivotItem> 
            <PivotItem headerText='Include' itemKey='include' key='include'>
              <div id='include'><Include /></div>
            </PivotItem>
            <PivotItem headerText='Exclude' itemKey='exclude' key='exclude'>
              <div id='exclude'><Exclude /></div>
            </PivotItem>
          </Pivot>  
        </Stack.Item>
        </Stack>  
          <DialogFooter>
            <PrimaryButton onClick={this.handleSave} text={t('common:BUTTON_SAVE')} />
            <DefaultButton onClick={this.handleClose} text={t('common:BUTTON_CLOSE')} />
          </DialogFooter>
        </Dialog>
      </div>
    )
  }
}
export default DialogNode
